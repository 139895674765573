import { useMutation, useQueryClient } from 'react-query';
import { flagUserById } from '../api/users';
import { useToast, FLAG_VENDOR_SUCCESS } from '../hooks/useToast';

type FlagUserOptions = {
  userId: string;
};
export const useFlagUserMutation = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId }: FlagUserOptions) => {
      return flagUserById(userId);
    },
    onSuccess: (user) => {
      if (user.subscription === 'vendor') {
        queryClient.invalidateQueries({ queryKey: ['getVendorById', user.sub] });
        queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      }
      showToast(FLAG_VENDOR_SUCCESS, 'success');
    },
  });
};
