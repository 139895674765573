import { useMutation } from 'react-query';
import { likeEvent } from '../api/standards';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, ADD_TO_LIKES_SUCCESS } from '../hooks/useToast';

type LikeEventOptions = {
  userId: string;
  eventId: string;
  vendorId: string;
};
export const useLikeEventMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, eventId, vendorId }: LikeEventOptions) => {
      const token = await getAccessTokenSilently();
      return likeEvent(userId, eventId, vendorId, token);
    },
    onSuccess: () => {
      showToast(ADD_TO_LIKES_SUCCESS, 'success');
    },
  });
};

// TODO - Only use getAccessTokenSilently on non-public routes
