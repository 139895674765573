import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from '../inputs/DatePicker';
import { useAuth } from '../../auth/useAuth';
import { CustomEvent } from '../inputs/WeekdayHoursPicker';

const searchSchema = yup.object({
  text: yup.string(),
  startDate: yup
    .number()
    .test('is-greater-than-midnight', 'Start date must today or later', (value) => {
      if (!value) return false; // Handle null or undefined values
      const now = new Date();
      const midnight = new Date(now);
      midnight.setHours(0, 0, 0, 0); // Set time to midnight
      return value >= midnight.getTime();
    }),
  maxPrice: yup.number(),
  type: yup.string(),
  likedEventsOnly: yup.boolean(),
  calendarEventsOnly: yup.boolean(),
  followedVendorsOnly: yup.boolean(),
});

export type TSearchForm = {
  type: string;
  likedEventsOnly?: boolean;
  calendarEventsOnly?: boolean;
  followedVendorsOnly?: boolean;
  text?: string;
  startDate?: number;
  maxPrice?: number;
};
export type SearchFormProps = {
  displayTypes: string[];
  initialValues?: TSearchForm;
  onSubmit: (form: TSearchForm, setSubmitting: (submitting: boolean) => void) => Promise<void>;
};
export const SearchForm: React.FC<React.PropsWithChildren<SearchFormProps>> = ({
  displayTypes,
  initialValues,
  onSubmit,
}) => {
  const { isPublicUser, isStandardUser } = useAuth();

  const formik = useFormik({
    validateOnMount: true,
    validationSchema: searchSchema,
    initialValues: {
      text: initialValues?.text || undefined,
      startDate: initialValues?.startDate || Date.now(),
      maxPrice: initialValues?.maxPrice || undefined,
      type: initialValues?.type || displayTypes[0],
      likedEventsOnly: initialValues?.likedEventsOnly || false,
      calendarEventsOnly: initialValues?.calendarEventsOnly || false,
      followedVendorsOnly: initialValues?.followedVendorsOnly || false,
    },
    onSubmit: (values, { setSubmitting }) => onSubmit(values, setSubmitting),
  });

  const handleStartDateChange = (event: CustomEvent) => {
    const value = (event.target as any).value;
    if (typeof value !== 'number') return;

    const selectedDate = new Date(value);
    const today = new Date();
    const isToday =
      selectedDate.getMonth() === today.getMonth() &&
      selectedDate.getDate() === today.getDate() &&
      selectedDate.getFullYear() === today.getFullYear();

    formik.setFieldValue('startDate', isToday ? Date.now() : value);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ width: '100%', maxWidth: '700px' }}
      aria-label={'Search Form'}
    >
      <Paper elevation={2} style={{ padding: '1rem' }}>
        <Grid container rowSpacing={'1rem'}>
          <Grid item xs={12} sm={3}>
            <FormControl>
              <InputLabel id="type">Search By</InputLabel>
              <Select
                id="type"
                name="type"
                label="Search By"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
                style={{ width: '125px' }}
              >
                {displayTypes.map((t) => (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={'2rem'}
            >
              <TextField
                fullWidth
                id="text"
                name="text"
                placeholder="Keywords"
                value={formik.values.text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ flex: 1 }}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                style={{ width: '100px' }}
              >
                Search
              </Button>
            </Stack>
          </Grid>
          {formik.values.type === 'Events' && (
            <Grid item xs={12}>
              <Divider flexItem />
            </Grid>
          )}
          <Grid container item rowSpacing={'1rem'} xs={12}>
            <Grid item xs={12} md={7}>
              <Stack direction={'row'} alignItems={'center'} spacing={'2rem'}>
                {formik.values.type === 'Events' && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="startDate"
                      name="startDate"
                      onChange={handleStartDateChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                      helperText={formik.touched.startDate && (formik.errors.startDate as string)}
                      value={formik.values.startDate}
                      targetTime="start"
                    />
                  </LocalizationProvider>
                )}
                {formik.values.type === 'Events' && (
                  <TextField
                    fullWidth
                    label="Max Price"
                    id="maxPrice"
                    name="maxPrice"
                    value={formik.values.maxPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ width: '100px' }}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack>
                {formik.values.type === 'Events' && (isPublicUser() || isStandardUser()) && (
                  <FormControlLabel
                    label="Show liked events only"
                    control={
                      <Switch
                        id="likedEventsOnly"
                        name="likedEventsOnly"
                        checked={formik.values.likedEventsOnly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                  />
                )}
                {formik.values.type === 'Events' && isStandardUser() && (
                  <FormControlLabel
                    label="Show calendar events only"
                    control={
                      <Switch
                        id="calendarEventsOnly"
                        name="calendarEventsOnly"
                        checked={formik.values.calendarEventsOnly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                  />
                )}
                {formik.values.type === 'Vendors' && (isPublicUser() || isStandardUser()) && (
                  <FormControlLabel
                    label="Show followed vendors only"
                    control={
                      <Switch
                        id="followedVendorsOnly"
                        name="followedVendorsOnly"
                        checked={formik.values.followedVendorsOnly}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    }
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
