import { styled } from 'styled-components';
import { Colors } from '../theme/colors';
import { Typography } from '@mui/material';

type DayProps = {
  selected: boolean;
};
const Layout = styled.div<DayProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 10px;
  height: 10px;
  border: 2px;
  border-radius: 50%;
  color: ${(props) => (props.selected ? 'white' : Colors.primary)};
  background-color: ${(props) => (props.selected ? Colors.primary : '#EEE')};
  box-shadow: ${(props) => (props.selected ? '0 0 3px 1px grey' : '0')};
  cursor: pointer;
`;

type Props = {
  day: string;
  selected: boolean;
  onClick: () => void;
};
export const Day: React.FC<Props> = ({ day, selected, onClick }) => {
  return (
    <Layout selected={selected} onClick={onClick}>
      <Typography style={{ pointerEvents: 'none' }}>{day}</Typography>
    </Layout>
  );
};
