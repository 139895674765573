import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../components/layout/page';
import { DisplayVendor } from '../vendor/profile/DisplayVendor';
import { Container, List, Stack, Typography } from '@mui/material';
import { Text } from '../../components/text';
import { PALETTE } from '../../theme/palette';
import { useGetVendorByIdQuery } from '../../queries/useGetVendorByIdQuery';
import { useGetEventsQuery } from '../../queries/useGetEventsQuery';
import { EventItem } from '../../components/items/EventItem';
import { useAuth } from '../../auth/useAuth';
import { LoadingPage } from '../LoadingPage';
import { usePublicUser } from '../../context/PublicUserProvider';
import { useCreateVendorViewMutation } from '../../mutations/useCreateVendorViewMutation';

export const VendorPage = () => {
  const { user, isPublicUser } = useAuth();
  const { userId } = usePublicUser();
  const { id } = useParams();
  const navigate = useNavigate();
  const vendorQuery = useGetVendorByIdQuery(id);

  const isAdminUser = user?.subscription === 'admin';
  const isOwner = user && user.sub === id;

  const eventsQuery = useGetEventsQuery({
    vendorId: id,
    blocked: isAdminUser || isOwner,
    canceled: isOwner || isAdminUser,
  });
  const { isLoading: isLoadingVendor, data: vendor, error: vendorError } = vendorQuery;
  const { isLoading: isLoadingEvents, data: events, error: eventsError } = eventsQuery;

  const { mutateAsync: handleVendorView } = useCreateVendorViewMutation();

  React.useEffect(() => {
    const uid = isPublicUser() ? userId : user?.sub;
    if (vendor && id && uid) {
      handleVendorView({ vendorId: id, userId: uid });
    }
  }, [vendor, userId, user, isPublicUser, id, handleVendorView]);

  if (vendorError) return <h3>Something went wrong. Please refresh the page and try again.</h3>;
  if (isLoadingVendor || !vendor) return <LoadingPage />;

  return (
    <Page header={vendor.companyName}>
      <Container
        style={{ maxWidth: '500px' }}
        component="section"
        aria-label={'Vendor Information'}
      >
        <DisplayVendor vendor={vendor} />
      </Container>
      {!!vendorError && (
        <Typography color={'error'} textAlign={'center'}>
          {vendorError as string}
        </Typography>
      )}
      {!isLoadingEvents && !eventsError && events && events.length > 0 && (
        <Stack
          component="section"
          alignItems={'center'}
          aria-label={`Events posted by ${vendor.companyName}`}
        >
          <br />
          <br />
          <Text color={PALETTE.primary.main} textAlign="center" fontSize="48px" component="h3">
            EVENTS POSTED
          </Text>
          <List sx={{ width: '100%', maxWidth: '900px' }}>
            {events.map((event) => (
              <EventItem
                key={event._id}
                event={event}
                onClick={() => navigate(`/events/${event._id}`)}
                onError={console.log}
              />
            ))}
          </List>
        </Stack>
      )}
      {!!eventsError && (
        <Typography color={'error'} textAlign={'center'}>
          {eventsError as string}
        </Typography>
      )}
    </Page>
  );
};
