import { useMutation, useQueryClient } from 'react-query';
import { SubscriptionForm, subscribe } from '../api/users';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, SUBSCRIBE_SUCCESS } from '../hooks/useToast';
import { TVendorForm } from '../types/vendors';
import { TStandardForm } from '../types/standards';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

export const useSubscribeMutation = () => {
  const queryClient = useQueryClient();
  const { signIn, signOut } = useAuth();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  return useMutation(
    async (data: {
      id: string;
      subscription: SubscriptionForm;
      formData: TVendorForm | TStandardForm;
    }) => {
      const token = await getAccessTokenSilently();
      return subscribe(data.id, data.subscription, data.formData, token);
    },
    {
      onSuccess: async (userData) => {
        // if (user?.sub === userData.sub) setUser(userData);
        userData.subscription === 'vendor' &&
          queryClient.invalidateQueries({ queryKey: ['getVendors'] });
        userData.subscription === 'standard' &&
          queryClient.invalidateQueries({ queryKey: ['getStandards'] });
        showToast(SUBSCRIBE_SUCCESS, 'success');
        await signOut();
        await signIn();
        // navigate(
        //   userData.subscription === 'vendor'
        //     ? `/vendors/${userData.sub.replace('|', '%7C')}`
        //     : `/profile`,
        // );
      },
    },
  );
};
