import { TStandard } from '../types/standards';
import { createFetch } from './createFetch';

export const likeEvent = async (
  userId: string,
  eventId: string,
  vendorId: string,
  token?: string,
) => {
  const url = `/users/${userId}/likes/${eventId}`;
  const response = await createFetch(url, 'POST', JSON.stringify({ vendorId }), token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TStandard;
};

export const dislikeEvent = async (userId: string, eventId: string, token?: string) => {
  const url = `/users/${userId}/likes/${eventId}`;
  const response = await createFetch(url, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TStandard;
};

export const addEventToCalendar = async (
  userId: string,
  eventId: string,
  vendorId: string,
  token?: string,
) => {
  const url = `/users/${userId}/calendar/${eventId}`;
  const response = await createFetch(url, 'POST', JSON.stringify({ vendorId: vendorId }), token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TStandard;
};

export const removeEventFromCalendar = async (userId: string, eventId: string, token?: string) => {
  const url = `/users/${userId}/calendar/${eventId}`;
  const response = await createFetch(url, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TStandard;
};

export const followVendor = async (userId: string, vendorId: string, token?: string) => {
  const url = `/users/${userId}/follows/${vendorId}`;
  const response = await createFetch(url, 'POST', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TStandard;
};

export const unfollowVendor = async (userId: string, vendorId: string, token?: string) => {
  const url = `/users/${userId}/follows/${vendorId}`;
  const response = await createFetch(url, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TStandard;
};
