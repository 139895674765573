type NotificationInfo = {
  type: string;
  title: string;
  color: string;
};

const GREEN = '#79b39d';
const PINK = 'pink';
const BLUE = 'lightblue';
const YELLOW = '#fdd049';

const notifications = [
  { type: 'message', title: 'Message', color: YELLOW },
  { type: 'event-new', title: 'New Event', color: GREEN },
  { type: 'event-updated', title: 'Event Updated', color: BLUE },
  { type: 'event-canceled', title: 'Event Canceled', color: PINK },
  { type: 'event-uncanceled', title: 'Event Back On', color: GREEN },
  { type: 'event-blocked', title: 'Event Unblocked', color: PINK },
  { type: 'event-unblocked', title: 'Event Back On', color: GREEN },
  { type: 'vendor-blocked', title: 'Vendor Was Blocked', color: PINK },
  { type: 'vendor-unblocked', title: 'Vendor Unblocked', color: GREEN },
  { type: 'vendor-new', title: 'New Vendor', color: GREEN },
  { type: 'vendor-updated', title: 'Vendor Updated', color: BLUE },
] as const;

export const NotificationMap = notifications.reduce(
  (acc, notification) => {
    const { type } = notification;
    acc[type] = notification;
    return acc;
  },
  {} as Record<string, NotificationInfo>,
);

export type TNotificationType = keyof typeof NotificationMap;

export type TNotification = {
  _id: string;
  type: TNotificationType;
  eventId: string;
  eventName: string;
  vendorId: string;
  vendorName: string;
  timestamp: number;
  message: string;
  link: string;
  flagged?: boolean;
  canceled?: boolean;
  blocked?: boolean;
};

export type TNotificationForm = Omit<TNotification, '_id'>;
