import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { TVendor } from '../../types/vendors';
import React from 'react';
import { TagsPicker } from '../inputs/TagsPicker';
import { DateTimeRepeatPicker } from '../inputs/EventDateTimePicker';
import { REPEAT_OPTIONS_MAP, RepeatType } from '../../types/misc';
import Dropdown from '../inputs/Dropdown';
import {
  DateTimeRepeat,
  DailyRepeat,
  NoRepeat,
  WeeklyRepeat,
} from '../inputs/EventDateTimePicker/types';
import { TEventForm } from '../../types/events';
import PriceRangePicker from '../inputs/PriceRangePicker';
import { CloudinaryUploadWidget } from '../inputs/CloudinaryUploadWidget';
import { useParams } from 'react-router-dom';

const DEFAULT_NO_REPEAT: NoRepeat = {
  type: 'Does not repeat',
  start: Date.now(),
  end: Date.now() + 60 * 60 * 1000,
};
const DEFAULT_DAILY_REPEAT: DailyRepeat = {
  type: 'Repeat Daily',
  start: Date.now(),
  end: Date.now() + 60 * 60 * 1000,
  finalDay: Date.now() + 24 * 60 * 60 * 1000 + 60 * 60 * 1000,
};
const DEFAULT_WEEKLY_REPEAT: WeeklyRepeat = {
  type: 'Repeat Weekly',
  start: Date.now(),
  end: Date.now() + 60 * 60 * 1000,
  finalDay: Date.now() + 7 * 24 * 60 * 60 * 1000 + 60 * 60 * 1000,
  days: [],
};

const DEFAULT_ADDRESS = {
  venue: '',
  street: '',
  city: '',
  state: '',
  zipcode: '',
};

const eventFormSchema = yup.object({
  name: yup.string().required('Please enter an event name.'),
  dateTime: yup
    .object<DateTimeRepeat>()
    .required('Required.')
    .test(
      'weekly-days-required',
      'Please select at least one day for weekly repeating events.',
      (value) => {
        const dateTime = value as DateTimeRepeat;
        if (dateTime?.type === 'Repeat Weekly' && (!dateTime.days || dateTime.days.length === 0)) {
          return false;
        }
        return true;
      },
    )
    .test(
      'final-day-validation',
      'Final day must be after the end time for repeating events.',
      (value) => {
        const dateTime = value as DateTimeRepeat;
        if (dateTime?.type === 'Repeat Daily' || dateTime?.type === 'Repeat Weekly') {
          return dateTime.finalDay > dateTime.end;
        }
        return true;
      },
    ),
  venue: yup.string(),
  street: yup.string().required('Required.'),
  city: yup.string().required('Required.'),
  state: yup.string().required('Required.'),
  zipcode: yup.string().required('Required.'),
  needTickets: yup.boolean().required('Required.'),
  needRegister: yup.boolean().required('Required.'),
  price: yup.object().required('Required.'),
  keywords: yup.array().required('Required.'),
  featuredLinks: yup.array(),
  details: yup.string(),
  linkToRegister: yup.string().when('needRegister', (needRegister) => {
    return needRegister[0] ? yup.string().required('Required.') : yup.string();
  }),
  linkToTickets: yup.string().when('needTickets', (needTickets) => {
    return needTickets[0] ? yup.string().required('Required.') : yup.string();
  }),
  photo: yup.object(),
});

export type EventFormProps = {
  submitLabel: string;
  initialValues: TEventForm;
  vendor: TVendor;
  onSubmit: (form: TEventForm, setSubmitting: (submitting: boolean) => void) => Promise<void>;
};
export const EventForm: React.FC<React.PropsWithChildren<EventFormProps>> = ({
  vendor,
  submitLabel,
  initialValues,
  onSubmit,
}) => {
  const { id } = useParams();
  const [sameAddressChecked, setSameAddressChecked] = React.useState<boolean>(false);

  const initialValue = initialValues.dateTime?.type || 'Does not repeat';
  const [repeat, setRepeat] = React.useState<RepeatType>(initialValue);

  const formik = useFormik({
    validateOnMount: true,
    validationSchema: eventFormSchema,
    initialValues: {
      name: initialValues.name,
      dateTime: initialValues.dateTime || DEFAULT_NO_REPEAT,
      venue: initialValues.address?.venue || '',
      street: initialValues.address?.street || '',
      city: initialValues.address?.city || '',
      state: initialValues.address?.state || '',
      zipcode: initialValues.address?.zipcode || '',
      needTickets: initialValues.needTickets,
      needRegister: initialValues.needRegister,
      priceType: initialValues.price?.type || 'Single',
      price: initialValues.price,
      keywords:
        initialValues.keywords
          ?.trim()
          .split(' ')
          .filter((w) => w.length > 0) || [],
      featuredLinks: initialValues.featuredLinks,
      details: initialValues.details,
      linkToRegister: initialValues.linkToRegister,
      linkToTickets: initialValues.linkToTickets,
      photo: initialValues.photo,
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(
        {
          name: values.name,
          vendorId: vendor.sub,
          vendorName: vendor.companyName,
          dateTime: values.dateTime,
          address: {
            venue: values.venue,
            street: values.street,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          },
          needTickets: values.needTickets,
          needRegister: values.needRegister,
          price: values.price,
          keywords: values.keywords.join(' '),
          featuredLinks: values.featuredLinks,
          details: values.details,
          linkToRegister: values.linkToRegister,
          linkToTickets: values.linkToTickets,
          photo: values.photo,
        },
        setSubmitting,
      );
    },
  });

  const handleRepeatChange = (type: RepeatType) => {
    switch (type) {
      case 'Does not repeat':
        formik.setFieldValue('dateTime', DEFAULT_NO_REPEAT);
        break;
      case 'Repeat Daily':
        formik.setFieldValue('dateTime', DEFAULT_DAILY_REPEAT);
        break;
      case 'Repeat Weekly':
        formik.setFieldValue('dateTime', DEFAULT_WEEKLY_REPEAT);
        break;
      default:
        break;
    }
    setRepeat(type);
  };

  return (
    <Paper elevation={5} style={{ padding: '2rem', borderRadius: '10px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={'1rem'}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Event Title"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && (formik.errors.name as string)}
          />
          <Divider flexItem />
          <Dropdown
            value={repeat}
            label={'Event Repeat Options'}
            options={Object.keys(REPEAT_OPTIONS_MAP)}
            onChange={(s) => handleRepeatChange(s as RepeatType)}
          />
          <DateTimeRepeatPicker
            id={'dateTime'}
            name={'dateTime'}
            value={formik.values.dateTime}
            onChange={formik.handleChange}
          />
          <Divider flexItem />
          <TextField
            fullWidth
            id="venue"
            name="venue"
            label="Venue Name"
            value={formik.values.venue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.venue && Boolean(formik.errors.venue)}
            helperText={formik.touched.venue && (formik.errors.venue as string)}
          />
          <FormGroup>
            <FormControlLabel
              label="Event Address Same as Vendor Address?"
              control={
                <Checkbox
                  checked={sameAddressChecked}
                  onChange={(value) => {
                    const isChecked = value.target.checked;
                    setSameAddressChecked(isChecked);
                    if (isChecked) {
                      formik.setFieldValue('street', vendor.address.street);
                      formik.setFieldValue('city', vendor.address.city);
                      formik.setFieldValue('state', vendor.address.state);
                      formik.setFieldValue('zipcode', vendor.address.zipcode);
                    } else {
                      formik.setFieldValue('street', DEFAULT_ADDRESS.street);
                      formik.setFieldValue('city', DEFAULT_ADDRESS.city);
                      formik.setFieldValue('state', DEFAULT_ADDRESS.state);
                      formik.setFieldValue('zipcode', DEFAULT_ADDRESS.zipcode);
                    }
                  }}
                />
              }
            />
          </FormGroup>
          <TextField
            fullWidth
            id="street"
            name="street"
            label="Street Address"
            value={formik.values.street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && (formik.errors.street as string)}
          />
          <Stack direction={'row'} spacing={2}>
            <TextField
              fullWidth
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && (formik.errors.city as string)}
            />
            <TextField
              fullWidth
              id="state"
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && (formik.errors.state as string)}
            />
            <TextField
              fullWidth
              id="zipcode"
              name="zipcode"
              label="Zipcode"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && (formik.errors.zipcode as string)}
            />
          </Stack>
          <Divider flexItem />
          <FormGroup>
            <FormControlLabel
              id={'needTickets'}
              name={'needTickets'}
              label="Need to purchase tickets?"
              control={
                <Checkbox
                  checked={formik.values.needTickets}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
            />
          </FormGroup>
          {formik.values.needTickets && (
            <TextField
              fullWidth
              id="linkToTickets"
              name="linkToTickets"
              label="Link to Tickets"
              value={formik.values.linkToTickets}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.linkToTickets && Boolean(formik.errors.linkToTickets)}
              helperText={formik.touched.linkToTickets && (formik.errors.linkToTickets as string)}
            />
          )}
          <FormGroup>
            <FormControlLabel
              id={'needRegister'}
              name={'needRegister'}
              label="Need to pre-register?"
              control={
                <Checkbox
                  checked={formik.values.needRegister}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
            />
          </FormGroup>
          {formik.values.needRegister && (
            <TextField
              fullWidth
              id="linkToRegister"
              name="linkToRegister"
              label="Link to Register"
              value={formik.values.linkToRegister}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.linkToRegister && Boolean(formik.errors.linkToRegister)}
              helperText={formik.touched.linkToRegister && (formik.errors.linkToRegister as string)}
            />
          )}
          <Divider flexItem />
          <PriceRangePicker
            id={'price'}
            name={'price'}
            required
            value={formik.values.price}
            onChange={formik.handleChange}
          />
          <Divider flexItem />
          <TagsPicker
            id={'keywords'}
            name={'keywords'}
            label={'Keywords (Hit spacebar to add)'}
            value={formik.values.keywords}
            onChange={formik.handleChange}
          />
          <Divider flexItem />
          <TagsPicker
            id={'featuredLinks'}
            name={'featuredLinks'}
            label={'Featured Links (Hit spacebar to add)'}
            value={formik.values.featuredLinks || []}
            onChange={formik.handleChange}
          />
          <Divider flexItem />
          <CloudinaryUploadWidget
            src={formik.values.photo}
            eventId={id}
            onUpload={formik.setFieldValue}
          />
          <Divider flexItem />
          <TextField
            fullWidth
            multiline
            id="details"
            name="details"
            label="Extra Details"
            value={formik.values.details}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.details && Boolean(formik.errors.details)}
            helperText={formik.touched.details && (formik.errors.details as string)}
          />
          <Divider flexItem />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
          >
            {submitLabel}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
