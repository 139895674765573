import { FormControl, Select, MenuItem, makeStyles } from '@mui/material';
import { Text } from '../text';
import { Colors } from '../../theme/colors';

const inputStyle = {
  'color': 'black',
  'border': `1px solid ${Colors.primary}`,
  'borderRadius': '7px',
  'boxShadow': 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
};

type Props = {
  value: string;
  options: string[];
  onChange: (text: string) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  renderItem?: (item: string) => React.ReactNode;
};
const Dropdown = ({ label, value, options, disabled, required, onChange, renderItem }: Props) => {
  const star = required ? '*' : '';
  const labelText = `${label}` + star;

  return (
    <FormControl style={{ width: '100%' }}>
      {label && <Text color={disabled ? 'lightgrey' : undefined}>{labelText}</Text>}
      <Select
        disabled={disabled || false}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          ...inputStyle,
          color: disabled ? 'lightgrey' : undefined,
          borderColor: disabled ? 'lightgrey' : undefined,
        }}
        inputProps={{ style: { padding: '10px' } }}
      >
        {options.map((option) => {
          return renderItem ? (
            renderItem(option)
          ) : (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
