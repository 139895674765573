import { useAuth0 } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { SubscriptionForm, subscribe } from '../api/users';
import { TStandard, TStandardForm } from '../types/standards';
import { TVendor, TVendorForm } from '../types/vendors';
import { TAdmin } from '../types/admin';
import { TUnsubscribed } from '../types/unsubscribed';
import { getUserFromAuth0User } from '../utils/userUtils';

export type TUser = TUnsubscribed | TStandard | TVendor | TAdmin;

type AuthContextData = {
  user?: TUser;
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: (screen?: 'signup' | 'login') => void;
  signOut: () => void;
  setUser: (user: TUser) => void;
  subscribe: (
    id: string,
    subscription: SubscriptionForm,
    formData: TVendorForm | TStandardForm,
    token?: string,
  ) => Promise<TUser>;
  isPublicUser: () => boolean;
  isStandardUser: () => boolean;
  isVendorUser: () => boolean;
  isAdminUser: () => boolean;
  isBlocked: () => boolean;
  isEmailVerified: () => boolean;
  error?: Error;
};

const AuthContext = React.createContext<AuthContextData | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    user: auth0User,
    error,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [user, setUser] = React.useState<TUser>();

  React.useEffect(() => {
    if (!auth0User) return;
    const newUser = getUserFromAuth0User(auth0User);
    setUser(newUser as TUser);
  }, [auth0User]);

  const isPublicUser = () => !isAuthenticated;
  const isStandardUser = () => user?.subscription === 'standard';
  const isVendorUser = () => user?.subscription === 'vendor';
  const isAdminUser = () => user?.subscription === 'admin';
  const isBlocked = () =>
    (isVendorUser() || isStandardUser()) && (user as TVendor).blocked === true;

  const signIn = async (screen: 'signup' | 'login' | undefined = 'login') => {
    const params: any = {
      appState: { returnTo: '/' },
    };
    if (screen) params.authorizationParams = { screen_hint: screen };
    await loginWithRedirect(params);
  };

  const signOut = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const isEmailVerified = () => {
    return user?.email_verified === true;
  };

  const value = {
    user,
    isLoading,
    isAuthenticated,
    signIn,
    signOut,
    setUser,
    subscribe,
    isPublicUser,
    isStandardUser,
    isVendorUser,
    isAdminUser,
    isBlocked,
    isEmailVerified,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) throw new Error('Must use useAuth within an AuthProvider');
  return context;
};
