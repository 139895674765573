import React from 'react';
import { TVendor, TVendorForm } from '../../types/vendors';
import { useToast } from '../../hooks/useToast';
import { useUpdateUnclaimedMutation } from '../../mutations/useUpdateUnclaimedMutation';

export const useUpdateVendor = () => {
  const { showToast } = useToast();
  const { mutateAsync } = useUpdateUnclaimedMutation();
  const [error, setError] = React.useState<string>();

  const updateVendor = async (
    data: TVendorForm,
    vendor: TVendor,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      await mutateAsync({ data, vendor });
    } catch (err: any) {
      const message = err.message;
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return { updateVendor, error };
};
