import React from 'react';

type Props = {
  url: string;
};
export const PdfViewer: React.FC<Props> = ({ url }) => {
  return (
    <div style={{ width: '100%', height: '800px' }}>
      <embed
        src={url}
        type="application/pdf"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>
  );
};
