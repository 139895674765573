import { useNavigate, useParams } from 'react-router-dom';
import DEFAULT_IMAGE from '../../assets/Default_Image.png';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Text } from '../../components/text';
import { ensureHttps, formatAddress, formatPrice, formatWebsiteLink } from '../../utils/formatting';
import { DATE_SHORT, TIME } from '../../formatting';
import { Paper } from '../../components/Paper';
import { Page } from '../../components/layout/page';
import React from 'react';
import { getNextOcurrence, getReccurringLabel } from '../../utils/days';
import { useAuth } from '../../auth/useAuth';
import { TEvent } from '../../types/events';
import { useGetEventByIdQuery } from '../../queries/useGetEventByIdQuery';
import { ImageDisplay } from '../../components/ImageDisplay';
import { DateTimeRepeat } from '../../components/inputs/EventDateTimePicker/types';
import { ActionButtons } from '../../components/ActionButtons';
import { useActionButtons } from '../../hooks/useActionButtons';
import { usePublicUser } from '../../context/PublicUserProvider';
import { isEventOnCalendar, isLikedEvent } from '../../utils/userUtils';
import { styled } from 'styled-components';
import { LoadingPage } from '../LoadingPage';
import { useCreateEventViewMutation } from '../../mutations/useCreateEventViewMutation';

const StyledLinkText = styled(Typography)`
  text-decoration: underline;

  &:hover {
    background: #d3d3d340;
    cursor: pointer;
  }
`;

type GridDateTimeProps = {
  dateTime: DateTimeRepeat;
};
const GridDateItem: React.FC<GridDateTimeProps> = ({ dateTime }) => {
  return <Grid item xs={12}></Grid>;
};

type GridInfoItemProps = {
  event: TEvent;
};
const GridInfoItem: React.FC<GridInfoItemProps> = ({ event }) => {
  const navigate = useNavigate();

  const reoccurringLabel = getReccurringLabel(event.dateTime);
  const startTime = new Date(event.dateTime.start).toLocaleTimeString(undefined, TIME as any);
  const endTime = new Date(event.dateTime.end).toLocaleTimeString(undefined, TIME as any);
  const nextOcurrenceTimestamp = getNextOcurrence(new Date(), event.dateTime);
  const nextOcurrence = new Date(nextOcurrenceTimestamp).toLocaleDateString(
    undefined,
    DATE_SHORT as any,
  );

  const neededAddressValues: Record<string, string | undefined> = {
    street: event.address.street,
    city: event.address.city,
    state: event.address.state,
  };

  const shouldDisplayAddress =
    Object.keys(neededAddressValues).filter((key) => neededAddressValues[key]).length > 0;

  return (
    <Grid item xs={12}>
      <br />
      {event.vendorName && (
        <StyledLinkText
          fontWeight={'bold'}
          textAlign="center"
          fontSize="32px"
          tabIndex={0}
          onKeyUp={(e) => e.key === 'Enter' && navigate(`/vendors/${event.vendorId}`)}
          onClick={() => navigate(`/vendors/${event.vendorId}`)}
        >
          {event.vendorName}
        </StyledLinkText>
      )}
      <br />
      <br />
      {event.name && (
        <Text textAlign="center" fontSize="32px" fontWeight="bold">
          {event.name}
        </Text>
      )}
      <br />
      <br />
      {event.address.venue && (
        <Text textAlign="center" fontWeight="bold" fontSize="24px">
          {event.address.venue}
        </Text>
      )}
      {shouldDisplayAddress && (
        <Text textAlign="center" fontSize="18px">
          {formatAddress(neededAddressValues)}
        </Text>
      )}
      <br />
      <br />
      {event.price && (
        <Stack direction={'row'} spacing={'2rem'} justifyContent={'center'} alignItems={'center'}>
          <Text fontWeight="bold" fontSize="24px" textAlign="center" style={{ marginRight: '5px' }}>
            Price:
          </Text>
          <Text fontSize="24px" textAlign="center">
            {formatPrice(event.price)}
          </Text>
        </Stack>
      )}
      <br />
      <br />
      {event.dateTime.type === 'Does not repeat' && (
        <Text fontSize="24px" fontWeight="bolder" textAlign="center">
          {nextOcurrence}
        </Text>
      )}
      <Text textAlign="center" fontWeight="bold" fontSize="24px">
        {`${startTime} - ${endTime}`}
      </Text>
      {event.dateTime.type !== 'Does not repeat' && (
        <>
          <Text textAlign="center" fontSize="16px">
            {reoccurringLabel}
          </Text>
          <br />
        </>
      )}
      {event.needRegister && event.linkToRegister && (
        <Stack justifyContent={'center'}>
          <br />
          <Text textAlign="center" fontWeight="bold" fontSize="24px">
            Register Here:
          </Text>
          <a
            style={{ textAlign: 'center' }}
            href={ensureHttps(event.linkToRegister)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatWebsiteLink(event.linkToRegister)}
          </a>
          <br />
        </Stack>
      )}
      {event.needTickets && event.linkToTickets && (
        <Stack justifyContent={'center'}>
          <Text textAlign="center" fontWeight="bold" fontSize="24px">
            Purchase Tickets Here:
          </Text>
          <a
            style={{ textAlign: 'center' }}
            href={ensureHttps(event.linkToTickets)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatWebsiteLink(event.linkToTickets)}
          </a>
          <br />
        </Stack>
      )}
      {event.featuredLinks && event.featuredLinks.length > 0 && (
        <Stack justifyContent={'center'}>
          <br />
          <Text textAlign="center" fontWeight="bold" fontSize="24px">
            Featured Links:
          </Text>
          {event.featuredLinks.map((link) => (
            <a
              style={{ textAlign: 'center' }}
              href={ensureHttps(link)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatWebsiteLink(link)}
            </a>
          ))}
        </Stack>
      )}
      {event.details && (
        <>
          <br />
          <Text textAlign="center" fontWeight="bold" fontSize="24px">
            Details:
          </Text>
          <Text textAlign="center">
            {event.details.split('\n').map((str, index) => (
              <React.Fragment key={`${event._id}-details-${index}`}>
                {str}
                <br />
              </React.Fragment>
            ))}
          </Text>
        </>
      )}
      <br />
    </Grid>
  );
};

export const EventPage = () => {
  const { id } = useParams();
  const { user, isPublicUser, isStandardUser, isVendorUser, isAdminUser, isBlocked } = useAuth();
  const { userId, publicIsLikedEvent } = usePublicUser();
  const { isLoading: isLoadingEvent, data: event, error: eventError } = useGetEventByIdQuery(id);
  const {
    likeToggleMutation,
    calendarToggleMutation,
    blockEventToggleMutation,
    cancelEventToggleMutation,
    flagEventToggleMutation,
    toggleLikeClick,
    toggleCalendarClick,
    toggleBlockEventClick,
    toggleCancelEventClick,
    toggleFlagEventClick,
    onEditEventClick,
    // onNotificationsClick,
    onShareClick,
    onEventStatisticsClick,
  } = useActionButtons();

  const { error: likeEventError, isLoading: isLoadingLike } = likeToggleMutation;
  const { error: calendarError, isLoading: isLoadingCalendar } = calendarToggleMutation;
  const { error: blockEventError, isLoading: isLoadingBlockEvent } = blockEventToggleMutation;
  const { error: cancelEventError, isLoading: isLoadingCancelEvent } = cancelEventToggleMutation;
  const { error: flagEventError, isLoading: isLoadingFlagEvent } = flagEventToggleMutation;

  const { mutateAsync: handleEventView } = useCreateEventViewMutation();

  React.useEffect(() => {
    const uid = isPublicUser() ? userId : user?.sub;
    if (event && id && uid !== undefined) {
      handleEventView({ eventId: id, vendorId: event.vendorId, userId: uid });
    }
  }, [event, userId, user, isPublicUser, id, handleEventView]);

  if (eventError) return <h3>Something went wrong. Please refresh the page and try again.</h3>;
  if (isLoadingEvent || !event) return <LoadingPage />;

  const imageSource = event.photo || DEFAULT_IMAGE;

  const isOwner = user && user.sub === event.vendorId;

  const isOnCalendar = isStandardUser() && isEventOnCalendar(event._id, user);
  const isLiked = isPublicUser()
    ? publicIsLikedEvent(event._id)
    : isStandardUser()
      ? isLikedEvent(event._id, user)
      : false;

  const onLikeClick = () => toggleLikeClick(isLiked, event._id, event.vendorId);
  const onCalendarClick = () => toggleCalendarClick(isOnCalendar, event._id, event.vendorId);
  const onEdit = () => onEditEventClick(event._id);
  // const onNotification = () => onNotificationsClick(event._id);
  const onStatistics = () => onEventStatisticsClick(event._id);
  const onBlockClick = () => toggleBlockEventClick(event.blocked || false, event._id);
  const onCancelClick = () => toggleCancelEventClick(event.canceled || false, event._id);
  const onFlagClick = () => toggleFlagEventClick(event.flagged || false, event._id);

  const error =
    eventError ||
    likeEventError ||
    calendarError ||
    blockEventError ||
    cancelEventError ||
    flagEventError;

  const disabledStyle = isAdminUser()
    ? {}
    : { opacity: event.blocked ? 0.5 : 1, pointerEvents: event.blocked ? 'none' : 'auto' };

  const showButtons =
    (!event.blocked && !event.canceled) || (event.canceled && isOwner) || isAdminUser();

  const handleFlagging =
    isAdminUser() || (event.flagged === false && !isOwner) ? onFlagClick : undefined;

  return (
    <Page header={event.name} maxWidth="500px">
      <Paper
        elevation={2}
        style={{ padding: '1rem' }}
        sx={disabledStyle}
        component="section"
        aria-label={'Event Information'}
      >
        <Grid container justifyContent={'space-evenly'}>
          <ImageDisplay size="100%" src={imageSource} alt={event.name} />
          <GridInfoItem event={event} />
          <Grid item xs={12}>
            <Divider />
            <br />
          </Grid>
          {showButtons && (
            <Grid item xs={12}>
              <ActionButtons
                isLoadingLike={isLoadingLike}
                isLoadingCalendar={isLoadingCalendar}
                isLoadingBlock={isLoadingBlockEvent}
                isLoadingCancel={isLoadingCancelEvent}
                isLoadingFlag={isLoadingFlagEvent}
                isLiked={isLiked}
                isOnCalendar={isOnCalendar}
                isBlocked={event.blocked || false}
                isCanceled={event.canceled || false}
                isFlagged={event.flagged || false}
                onLikeClick={isPublicUser() || isStandardUser() ? onLikeClick : undefined}
                onCalendarClick={isStandardUser() ? onCalendarClick : undefined}
                onEditClick={
                  (!event.canceled && isOwner && !isBlocked() && !event.blocked) || isAdminUser()
                    ? onEdit
                    : undefined
                }
                // onNotificationClick={
                //   (isStandardUser() && isOnCalendar) || (isVendorUser() && isOwner) || isAdminUser()
                //     ? onNotification
                //     : undefined
                // }
                onShareClick={isPublicUser() && isStandardUser() ? onShareClick : undefined}
                onStatisticsClick={
                  (!event.canceled && isOwner && !isBlocked() && !event.blocked) || isAdminUser()
                    ? onStatistics
                    : undefined
                }
                onBlockClick={isAdminUser() ? onBlockClick : undefined}
                onCancelClick={
                  (isOwner && !isBlocked() && !event.blocked) || isAdminUser()
                    ? onCancelClick
                    : undefined
                }
                onFlagClick={handleFlagging}
              />
            </Grid>
          )}
          {event.blocked && (
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              padding={'1rem'}
              marginTop={'1rem'}
              style={{ background: '#0000001c' }}
            >
              <Text textAlign="center">Event is blocked</Text>
              <Text textAlign="center">
                If you think this is a mistake, please contact Paige at paige@sociallifeofmine.com
              </Text>
            </Grid>
          )}
          {event.canceled && !event.blocked && (
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              padding={'1rem'}
              marginTop={'1rem'}
              style={{ background: '#0000001c' }}
            >
              <Text textAlign="center">Event is canceled</Text>
            </Grid>
          )}
        </Grid>
      </Paper>
      {!!error && (
        <Typography color={'error'} textAlign={'center'}>
          {JSON.stringify(error)}
        </Typography>
      )}
    </Page>
  );
};
