import React from 'react';
import { Page } from '../../components/layout/page';
import { CalendarListView } from '../../components/CalendarListView';
import { CalendarView } from '../../components/CalendarView';
import { Text } from '../../components/text';
import { Box, IconButton, useTheme } from '@mui/material';
import { useAuth } from '../../auth/useAuth';
import { TEvent } from '../../types/events';
import { useGetEventsQuery } from '../../queries/useGetEventsQuery';
import { isEventOnCalendar } from '../../utils/userUtils';
import { CalendarMonth } from '@mui/icons-material';
import { ListAltOutlined } from '@mui/icons-material';
import { LoadingPage } from '../LoadingPage';
import { GetEventsParams } from '../../api/events';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const CalendarPage = () => {
  const theme = useTheme();
  const { user, isStandardUser, isVendorUser, isAdminUser } = useAuth();
  const [dateNow] = React.useState(Date.now()); // Why is this a state variable?
  const navigate = useNavigate();
  const queryParams: GetEventsParams = { canceled: true, blocked: isAdminUser() || isVendorUser() };
  if (isStandardUser()) {
    queryParams.startDate = dateNow;
  }
  if (isVendorUser()) {
    queryParams.vendorId = user?.sub;
  }
  const { isLoading, data: events } = useGetEventsQuery(queryParams);
  const [filteredEvents, setFilteredEvents] = React.useState<TEvent[]>([]);
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view') || 'calendar';

  const filterCalendarEvents = () => {
    if (!events || events.length === 0) return;
    const es = isVendorUser()
      ? events
      : events.filter((event) => isEventOnCalendar(event._id, user));
    setFilteredEvents(es);
  };

  React.useEffect(() => {
    if (!user || (!isStandardUser() && !isVendorUser())) return;
    filterCalendarEvents();
  }, [events, user]);

  if (isLoading) return <LoadingPage />;

  return (
    <Page header={'MY CALENDAR'} maxWidth="800px">
      <Text textAlign="center">* All times are local to the event location</Text>
      <br />
      <br />
      {filteredEvents.length > 0 ? (
        <>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #e5e5ea',
                borderRadius: '6px',
                overflow: 'hidden',
              }}
            >
              <IconButton
                aria-label={'Calendar View'}
                sx={{
                  borderRadius: 0,
                  padding: '6px 12px',
                  cursor: 'pointer',
                  background: view === 'calendar' ? '#798473' : 'white',
                }}
                onClick={() => navigate('/calendar?view=calendar')}
              >
                <CalendarMonth />
              </IconButton>
              <IconButton
                aria-label={'List View'}
                sx={{
                  borderRadius: 0,
                  padding: '6px 12px',
                  cursor: 'pointer',
                  background: view === 'list' ? '#798473' : 'white',
                }}
                onClick={() => navigate('/calendar?view=list')}
              >
                <ListAltOutlined />
              </IconButton>
            </Box>
          </Box>
          <CalendarListView events={filteredEvents} view={view} />
          <CalendarView events={filteredEvents} view={view} />
        </>
      ) : (
        <Text textAlign="center" color={theme.palette.secondary.main}>
          Once you add events to your calendar or like them, they will show up here.
        </Text>
      )}
    </Page>
  );
};
