import { useQuery } from 'react-query';
import { getFeedback } from '../api/feedback';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetFeedbackQuery = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['getFeedback'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getFeedback(token);
    },
  });
};
