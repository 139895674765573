import { Outlet, useLocation } from 'react-router-dom';
import Footer from './footer';
import { Stack } from '@mui/material';
import Header from './header';
import React from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';

export const Layout = () => {
  const { updateRoute } = useSessionStorage();

  const Body = () => (
    <main style={{ display: 'flex', flex: '1 0 auto', flexDirection: 'column' }}>
      <Outlet />
    </main>
  );

  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  React.useEffect(() => {
    updateRoute(location);
  }, [location.pathname, location.search]);

  return (
    <Stack style={{ minHeight: '100vh' }}>
      <Header />
      <Body />
      <Footer />
    </Stack>
  );
};
