import React from 'react';
import { useToast } from '../../../hooks/useToast';
import { useCreateEventMutation } from '../../../mutations/useCreateEventMutation';
import { TEventForm } from '../../../types/events';

export const useCreateEvent = () => {
  const { showToast } = useToast();
  const { mutateAsync } = useCreateEventMutation();
  const [error, setError] = React.useState<string>();

  const createEvent = async (data: TEventForm, setIsSubmitting: (bool: boolean) => void) => {
    try {
      setIsSubmitting(true);
      await mutateAsync(data);
      setError(undefined);
    } catch (err: any) {
      const message = err.message;
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return { createEvent, error };
};
