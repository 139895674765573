import { ListItem, Paper, useMediaQuery, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { ClickableGrid } from './ClickableGrid';

type ItemProps = {
  ariaLabel?: string;
  component?: React.ElementType;
  color: string;
  children: React.ReactNode;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: () => void;
};
export const Item: React.FC<PropsWithChildren<ItemProps>> = ({
  color,
  component,
  ariaLabel,
  disabled,
  onClick,
  tabIndex,
  children,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, func: () => void) => {
    if (e.key === 'Enter') {
      if (!(e.target instanceof HTMLElement)) {
        return;
      }
      if (e.target.tagName && e.target.tagName === 'BUTTON') {
        return;
      }
      func && func();
    }
  };
  return (
    <ListItem
      component={component || 'li'}
      aria-label={ariaLabel}
      sx={{
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <Paper elevation={3} style={{ width: '100%', background: color, borderRadius: '10px' }}>
        <ClickableGrid
          container
          padding={isSm ? '1rem 0' : '1rem'}
          marginTop={'10px'}
          borderRadius={'10px'}
          style={{ background: 'white' }}
          tabIndex={tabIndex}
          onKeyUp={(e) => onClick && handleKeyUp(e, onClick)}
          onClick={() => onClick && onClick()}
        >
          {children}
        </ClickableGrid>
      </Paper>
    </ListItem>
  );
};
