import { TEvent, TEventForm } from '../types/events';
import { createFetch, createQueryParams } from './createFetch';

export const createEvent = async (data: TEventForm, token?: string) => {
  const body = JSON.stringify(data);
  const response = await createFetch('/events', 'POST', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};
export const updateEvent = async (data: TEventForm, eventId: string, token?: string) => {
  const body = JSON.stringify(data);
  const response = await createFetch(`/events/${eventId}`, 'PATCH', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};

export const deleteEventPhoto = async (photoId: string, eventId: string, token?: string) => {
  const body =
    eventId !== ''
      ? JSON.stringify({ publicId: photoId, eventId: eventId })
      : JSON.stringify({ publicId: photoId });
  const response = await createFetch(`/photos`, 'DELETE', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
};

export const getEventById = async (id?: string) => {
  const response = await createFetch(`/events/${id}`, 'GET');
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};

export type GetEventsParams = {
  vendorId?: string;
  blocked?: boolean;
  canceled?: boolean;
  text?: string;
  startDate?: number;
  maxPrice?: number;
  flagged?: boolean;
};
export const getEvents = async (options?: GetEventsParams) => {
  const params = createQueryParams(options || {});
  const query = new URLSearchParams(params).toString();
  const response = await createFetch('/events?' + query, 'GET');
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  const events = result as TEvent[];
  return events.sort(
    (a, b) => new Date(a.dateTime.start).getTime() - new Date(b.dateTime.start).getTime(),
  );
};

export const blockEventById = async (id?: string, token?: string) => {
  const response = await createFetch(`/events/${id}/block`, 'POST', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};

export const unblockEventById = async (id?: string, token?: string) => {
  const response = await createFetch(`/events/${id}/block`, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};

export const cancelEventById = async (id?: string, token?: string) => {
  const response = await createFetch(`/events/${id}/cancel`, 'POST', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};

export const uncancelEventById = async (id?: string, token?: string) => {
  const response = await createFetch(`/events/${id}/cancel`, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TEvent;
};

export const flagEventById = async (id?: string, token?: string) => {
  const response = await createFetch(`/events/${id}/flag`, 'POST', undefined, token);
  const result = await response.json();
  return result as TEvent;
};

export const unflagEventById = async (id?: string, token?: string) => {
  const response = await createFetch(`/events/${id}/flag`, 'DELETE', undefined, token);
  const result = await response.json();
  return result as TEvent;
};