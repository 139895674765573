import { Page } from '../../../components/layout/page';
import { Stack } from '@mui/material';
import { LoadingText } from '../../../components/Loading';
import { useAuth } from '../../../auth/useAuth';
import { VendorForm } from '../../../components/forms/VendorForm';
import { useUpdateUser } from './profilePageHooks';
import { Link } from 'react-router-dom';
import { LoadingPage } from '../../LoadingPage';
import { ErrorText } from '../../../components/text/ErrorText';

export const VendorProfilePage = () => {
  const { user } = useAuth();
  const { updateUser, error } = useUpdateUser();

  if (!user) return <LoadingPage />;
  if (user.subscription !== 'vendor') return null;

  return (
    <Page header="PROFILE SETTINGS" maxWidth="450px">
      <Stack spacing={'2rem'} alignItems={'center'}>
        <Link to={`/vendors/${user.sub}`}>View Profile</Link>
        <VendorForm
          submitLabel={'Update Profile'}
          initialValues={user}
          onSubmit={(form, setSubmitting) => updateUser(user, form, setSubmitting)}
        />
        <br />
        {error && <ErrorText>{error}</ErrorText>}
      </Stack>
    </Page>
  );
};
