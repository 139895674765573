import { DateTimeRepeat } from '../components/inputs/EventDateTimePicker/types';
import { DAYS, DAYS_OF_WEEK, DAY_VALUES, Day } from '../types/misc';

export const getDayFromAbbreviation = (abr: Day) => {
  return DAYS[abr];
};

export const getReccurringLabel = (dateTime: DateTimeRepeat) => {
  switch (dateTime.type) {
    case 'Does not repeat':
      return `Does Not Repeat`;
    case 'Repeat Daily':
      return `(Every Day)`;
    case 'Repeat Weekly':
      return `(Every ${dateTime.days?.join(', ')})`;
  }
};

export const getNextOcurrence = (today: Date, dateTime: DateTimeRepeat) => {
  switch (dateTime.type) {
    case 'Does not repeat': {
      return dateTime.start;
    }
    case 'Repeat Daily': {
      const eventStartDate = new Date(dateTime.start);
      const eventHasStarted = hasNextOccurrenceHappenedToday(today, dateTime);
      const dayOffset = eventHasStarted ? 1 : 0;
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + dayOffset,
        eventStartDate.getHours(),
        eventStartDate.getMinutes(),
        eventStartDate.getSeconds(),
        eventStartDate.getMilliseconds(),
      ).getTime();
    }
    case 'Repeat Weekly': {
      const eventStartDate = new Date(dateTime.start);

      // Check todays Day with the event days
      const eventDays = dateTime.days || [];

      // If this happens, they setup their event wrong
      if (eventDays.length === 0) return dateTime.start;

      const todaysDay = today.getDay();
      const todaysAbr = DAYS_OF_WEEK[todaysDay];
      const eventHappensToday = eventDays.includes(todaysAbr);
      const eventHasStarted = hasNextOccurrenceHappenedToday(today, dateTime);

      // If todays day is within the event days, check if its occurred
      // If it has not, use today
      if (eventHappensToday && !eventHasStarted)
        return new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          eventStartDate.getHours(),
          eventStartDate.getMinutes(),
          eventStartDate.getSeconds(),
          eventStartDate.getMilliseconds(),
        ).getTime();

      // If it has occurred or is not today, find the next day
      // This needs to return days until next occurrence
      const nextDayOffset = findNextDayOfWeekOffset(todaysAbr, eventDays);
      const todayDay = today.getDate();
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        todayDay + nextDayOffset,
        eventStartDate.getHours(),
        eventStartDate.getMinutes(),
        eventStartDate.getSeconds(),
        eventStartDate.getMilliseconds(),
      ).getTime();
    }
  }
};

const hasNextOccurrenceHappenedToday = (now: Date, dateTime: DateTimeRepeat) => {
  const time = {
    h: now.getHours(),
    m: now.getMinutes(),
    s: now.getSeconds(),
    ms: now.getMilliseconds(),
  };
  const eventDate = new Date(dateTime.start);
  const eventTime = {
    h: eventDate.getHours(),
    m: eventDate.getMinutes(),
    s: eventDate.getSeconds(),
    ms: eventDate.getMilliseconds(),
  };
  const nowDateTime = new Date(0, 0, 0, time.h, time.m, time.s, time.ms);
  const eventDateTime = new Date(0, 0, 0, eventTime.h, eventTime.m, eventTime.s, eventTime.ms);
  const delta = eventDateTime.getTime() - nowDateTime.getTime();
  return delta < 0;
};

const findNextDayOfWeekOffset = (currentDay: Day, days: Day[]) => {
  const sortedDayValues = days
    .sort((a, b) => DAY_VALUES[a] - DAY_VALUES[b])
    .map((d) => DAY_VALUES[d]);

  const currentDayValue = DAY_VALUES[currentDay];
  const largerValues = sortedDayValues.filter((d) => d > currentDayValue);
  if (largerValues.length === 0) return 6 - currentDayValue + sortedDayValues[0] + 1;
  return largerValues[0] - currentDayValue;
};

export const monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']