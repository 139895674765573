import { createFetch } from './createFetch';
import { TStandardForm } from '../types/standards';
import { TVendorForm } from '../types/vendors';
import { TUser } from '../auth/useAuth';

export type Subscription = 'standard' | 'vendor' | 'admin' | 'unsubscribed';
export type SubscriptionForm = Omit<Subscription, 'admin' | 'unsubscribed'>;
type SubscriptionWithoutAdmin = Omit<Subscription, 'admin'>;

type SubscriptionData = {
  subscription: SubscriptionWithoutAdmin;
  acceptedTermsAndConditions: boolean;
};
type VendorSubscriptionData = TVendorForm & SubscriptionData;
type StandardSubscriptionData = TStandardForm & SubscriptionData;
export type TSubscriptionOptions = VendorSubscriptionData | StandardSubscriptionData;

export const subscribe = async (id: string, subscription: SubscriptionForm, formData: TVendorForm | TStandardForm, token?: string) => {
  const data = JSON.stringify({ subscription, formData });
  const response = await createFetch(`/users/${id}/subscribe`, 'POST', data, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TUser;
};

export const updateUser = async (id: string, data: TStandardForm | TVendorForm, token?: string) => {
  const body = JSON.stringify(data);
  const url = `/users/${id}`;
  const response = await createFetch(url, 'PATCH', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TUser;
};

export const blockUserById = async (userId: string, token?: string) => {
  const response = await createFetch(`/users/${userId}/block`, 'POST', '', token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TUser;
};

export const unblockUserById = async (userId: string, token?: string) => {
  const response = await createFetch(`/users/${userId}/block`, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TUser;
};

export const flagUserById = async (id?: string, token?: string) => {
  const response = await createFetch(`/users/${id}/flag`, 'POST', undefined, token);
  const result = await response.json();
  return result as TUser;
};

export const unflagUserById = async (id?: string, token?: string) => {
  const response = await createFetch(`/users/${id}/flag`, 'DELETE', undefined, token);
  const result = await response.json();
  return result as TUser;
};