import { useMutation, useQueryClient } from 'react-query';
import { TNotificationForm } from '../types/notification';
import { updateNotification } from '../api/notifications';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, UPDATE_MESSAGE_SUCCESS } from '../hooks/useToast';

type UseUpdateNotificationMutationOptions = {
  id: string;
  form: TNotificationForm;
};
export const useUpdateNotificationMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ id, form }: UseUpdateNotificationMutationOptions) => {
      const token = await getAccessTokenSilently();
      return updateNotification(id, form, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getNotifications'] });
      showToast(UPDATE_MESSAGE_SUCCESS, 'success');
    },
  });
};
