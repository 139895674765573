import React from 'react';
import { CloseIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  onClick: () => void;
};
export const Close: React.FC<Props> = ({ onClick }) => {
  const theme = useTheme();
  return (
    <Icon tooltip={'Close'} onClick={onClick}>
      <CloseIcon style={{ color: theme.palette.error.main }} fontSize="large" />
    </Icon>
  );
};
