import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { Page } from '../../components/layout/page';
import { BarChart, PieChart } from '@mui/x-charts';
import { useParams } from 'react-router-dom';
import { Text } from '../../components/text';
import { LoadingPage } from '../LoadingPage';
import { useGetVendorByIdQuery } from '../../queries/useGetVendorByIdQuery';
import { useGetVendorStatsByIdQuery } from '../../queries/useGetVendorStatsByIdQuery';
import { Statistic } from '../../types/statistic';

type BarChartData = {
  keys: string[]; // Label for xAxis
  data: number[]; // Data for each column
};

type PieChartData = {
  label: string; // Label and key
  value: number; // Data for each label
};

// COMPONENT //
export const VendorStatisticsPage = () => {
  const { id } = useParams();
  const {
    isLoading: isLoadingVendor,
    data: vendor,
    error: vendorError,
  } = useGetVendorByIdQuery(id);
  const {
    isLoading: isLoadingVendorStats,
    data: vendorStats,
    error: vendorStatsError,
  } = useGetVendorStatsByIdQuery(id);

  const [viewsBarChart, setViewsBarChart] = React.useState<BarChartData | null>(null);
  const [followsBarChart, setFollowsBarChart] = React.useState<BarChartData | null>(null);
  const [ageChart, setAgeChart] = React.useState<PieChartData[] | null>(null);
  const [pronounsChart, setPronounsChart] = React.useState<PieChartData[] | null>(null);
  const [eventsViewsChart, setEventsViewsChart] = React.useState<BarChartData | null>(null);
  const [eventsLikesChart, setEventsLikesChart] = React.useState<BarChartData | null>(null);
  const [eventsRSVPsChart, setEventsRSVPsChart] = React.useState<BarChartData | null>(null);

  type StatsByMonth = {
    [month: string]: Statistic[];
  };

  const sortStatsByMonth = (list: Statistic[]): StatsByMonth => {
    return list
      .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .reduce<StatsByMonth>((acc, listEventStat) => {
        const date = new Date(listEventStat.timestamp);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        const fullDate = `${month}, ${year}`;
        if (!acc[fullDate]) acc[fullDate] = [];
        acc[fullDate].push(listEventStat);
        return acc;
      }, {});
  };

  function getAgeRange(epochTime: number) {
    // Calculate the current time in milliseconds
    const currentTime = new Date().getTime();

    // Calculate the age in milliseconds
    const ageInMillis = currentTime - epochTime;

    // Convert age to years
    const ageInYears = ageInMillis / (365.25 * 24 * 60 * 60 * 1000);

    // Determine the age range
    if (ageInYears >= 18 && ageInYears <= 20) {
      return '18-20';
    } else if (ageInYears > 20 && ageInYears <= 30) {
      return '21-30';
    } else if (ageInYears > 30 && ageInYears <= 40) {
      return '31-40';
    } else if (ageInYears > 40 && ageInYears <= 50) {
      return '41-50';
    } else {
      return '50+';
    }
  }

  React.useEffect(() => {
    if (vendorStats) {
      if (vendorStats.views.length) {
        setViewsBarChart(() => {
          const sortedViews = sortStatsByMonth(vendorStats?.views);
          const keys = Object.keys(sortedViews);
          return {
            keys,
            data: keys.map((key) => sortedViews[key].length),
          };
        });
      }
      if (vendorStats.follows.length) {
        setFollowsBarChart(() => {
          const sortedFollows = sortStatsByMonth(vendorStats?.follows);
          const keys = Object.keys(sortedFollows);
          return {
            keys,
            data: keys.map((key) => sortedFollows[key].length),
          };
        });
        setAgeChart(() => {
          const data: { [key: string]: number } = {};
          vendorStats?.follows.forEach((stat) => {
            const ageRange = getAgeRange(stat.userBirthday);
            return data[ageRange] ? (data[ageRange] += 1) : (data[ageRange] = 1);
          });
          return Object.keys(data).map((key) => ({ id: key, label: key, value: data[key] }));
        });
        setPronounsChart(() => {
          const data: { [key: string]: number } = {};
          vendorStats?.follows.forEach((stat) =>
            data[stat.userPronouns]
              ? (data[stat.userPronouns] += 1)
              : (data[stat.userPronouns] = 1),
          );
          return Object.keys(data).map((key) => ({ id: key, label: key, value: data[key] }));
        });
      }
      if (vendorStats.eventViews.length) {
        setEventsViewsChart(() => {
          const sortedViews = sortStatsByMonth(vendorStats?.eventViews);
          const keys = Object.keys(sortedViews);
          return {
            keys,
            data: keys.map((key) => sortedViews[key].length),
          };
        });
      }
      if (vendorStats.eventLikes.length) {
        setEventsLikesChart(() => {
          const sortedLikes = sortStatsByMonth(vendorStats?.eventLikes);
          const keys = Object.keys(sortedLikes);
          return {
            keys,
            data: keys.map((key) => sortedLikes[key].length),
          };
        });
      }
      if (vendorStats.eventRSVPs.length) {
        setEventsRSVPsChart(() => {
          const sortedRSVPs = sortStatsByMonth(vendorStats?.eventRSVPs);
          const keys = Object.keys(sortedRSVPs);
          return {
            keys,
            data: keys.map((key) => sortedRSVPs[key].length),
          };
        });
      }
    }
  }, [id, vendorStats]);

  if (!id || isLoadingVendor || !vendor || isLoadingVendorStats || !vendorStats)
    return <LoadingPage />;

  return (
    <Page header={'VENDOR STATISTICS'} subheader={vendor?.companyName} maxWidth="500px">
      <Stack component="section" spacing={'1rem'} alignItems={'center'}>
        <Stack component='section' direction={'row'} spacing={'1rem'} width={'100%'}>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Event Views</Text>
            <Text fontSize="36px" textAlign="center">
              {vendorStats?.eventViews.length}
            </Text>
          </Paper>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Event Likes</Text>
            <Text fontSize="36px" textAlign="center">
              {vendorStats?.eventLikes.length}
            </Text>
          </Paper>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Event Calendars</Text>
            <Text fontSize="36px" textAlign="center">
              {vendorStats?.eventRSVPs.length}
            </Text>
          </Paper>
        </Stack>
        <Stack component='section' direction={'row'} spacing={'1rem'} width={'100%'}>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Vendor Views</Text>
            <Text fontSize="36px" textAlign="center">
              {vendorStats?.views.length}
            </Text>
          </Paper>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Followers</Text>
            <Text fontSize="36px" textAlign="center">
              {vendorStats?.follows.length}
            </Text>
          </Paper>
        </Stack>
        <Stack component='section' direction={'row'} spacing={'1rem'} width={'100%'}>
          {!isLoadingVendorStats && vendorStats?.follows && ageChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                width: '100%',
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Followers Age</Text>
              <PieChart series={[{ data: ageChart }]} height={150} />
            </Paper>
          )}

          {!isLoadingVendorStats && vendorStats?.follows && pronounsChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                width: '100%',
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Followers Pronouns</Text>
              <PieChart series={[{ data: pronounsChart }]} height={150} />
            </Paper>
          )}
        </Stack>
        <Stack component='section' direction={'column'} spacing={'1rem'} width={'100%'}>
          {!isLoadingVendorStats && vendorStats?.eventViews && eventsViewsChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Event Views</Text>
              <BarChart
                series={[
                  {
                    data: eventsViewsChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: eventsViewsChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
          {!isLoadingVendorStats && vendorStats?.eventLikes && eventsLikesChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Event Likes</Text>
              <BarChart
                series={[
                  {
                    data: eventsLikesChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: eventsLikesChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
          {!isLoadingVendorStats && vendorStats?.eventRSVPs && eventsRSVPsChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Events Calendared</Text>
              <BarChart
                series={[
                  {
                    data: eventsRSVPsChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: eventsRSVPsChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
        </Stack>
        <Stack component="section" direction={'column'} spacing={'1rem'} width={'100%'}>
          {!isLoadingVendorStats && vendorStats?.views && viewsBarChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Vendor Views</Text>
              <BarChart
                series={[
                  {
                    data: viewsBarChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: viewsBarChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
          {!isLoadingVendorStats && vendorStats?.follows && followsBarChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Vendor Follows</Text>
              <BarChart
                series={[
                  {
                    data: followsBarChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: followsBarChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
        </Stack>
        {!!vendorError && (
          <Typography color={'error'} textAlign={'center'}>
            {vendorError as string}
          </Typography>
        )}
        {!!vendorStatsError && (
          <Typography color={'error'} textAlign={'center'}>
            {vendorStatsError as string}
          </Typography>
        )}
      </Stack>
    </Page>
  );
};
