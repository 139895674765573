import React from 'react';
import { CheckCircleOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  fontSize?: 'small' | 'large';
  onClick?: () => void;
  disabled?: boolean;
};
export const CheckmarkCircle: React.FC<Props> = ({ onClick, fontSize, disabled }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const displayColor = disabled ? 'lightgrey' : color;
  return (
    <Icon tooltip={onClick && 'Confirm'} onClick={onClick} disabled={disabled}>
      <CheckCircleOutlinedIcon style={{ color: displayColor }} fontSize={fontSize || "large"} />
    </Icon>
  );
};
