import { useMutation } from 'react-query';
import { addEventToCalendar } from '../api/standards';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, ADD_TO_CALENDAR_SUCCESS } from '../hooks/useToast';

// TODO - these should not be their own types - they should be the same as the API function
type AddEventToCalendarOptions = {
  userId: string;
  eventId: string;
  vendorId: string;
};
export const useAddEventToCalendarMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, eventId, vendorId }: AddEventToCalendarOptions) => {
      const token = await getAccessTokenSilently();
      return addEventToCalendar(userId, eventId, vendorId, token);
    },
    onSuccess: () => {
      showToast(ADD_TO_CALENDAR_SUCCESS,'success');
    },
  });
};
