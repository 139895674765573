import { useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getPhotoSignature } from '../api/photos';

export const useGetPhotoSignatureMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (paramsToSign: string) => {
      const token = await getAccessTokenSilently();
      return getPhotoSignature(paramsToSign, token);
    },
  });
};
