import { Page } from '../../components/layout/page';
import { PdfViewer } from '../../components/pdfs/PdfViewer';
import { Document, documentUrls } from '../../urls/documents';

type Props = {
  document: Document;
};
export const PdfViewerPage: React.FC<Props> = ({ document }) => {
  return (
    <Page header={document}>
      <PdfViewer url={documentUrls[document]} />
    </Page>
  );
};
