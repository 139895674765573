import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { deleteEventPhoto } from '../api/events';
import { deleteVendorPhoto } from '../api/vendors';

type deletePhotoMutation = {
  publicId: string;
  eventId?: string;
  vendorId?: string;
};
export const useDeletePhotoMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async ({ publicId, eventId, vendorId }: deletePhotoMutation) => {
      const token = await getAccessTokenSilently();
      if (eventId) return deleteEventPhoto(publicId, eventId, token);
      if (vendorId) return deleteVendorPhoto(publicId, vendorId, token);
      if (!eventId && !vendorId) return deleteEventPhoto(publicId, '', token);
    },
    onSuccess: (data, { eventId, vendorId }) => {
      if (eventId) {
        queryClient.invalidateQueries({ queryKey: ['getEventById', eventId] });
        queryClient.invalidateQueries({ queryKey: ['getEvents'] });
      }
      if (vendorId) {
        queryClient.invalidateQueries({ queryKey: ['getVendorById', vendorId] });
        queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      }
    },
  });
};
