import { useToast } from '../../../hooks/useToast';
import { TVendor, TVendorForm } from '../../../types/vendors';
import { useUpdateUserMutation } from '../../../mutations/useUpdateUserMutation';
import { TStandard, TStandardForm } from '../../../types/standards';
import React from 'react';

export const useUpdateUser = () => {
  const { showToast } = useToast();
  const { mutateAsync, isLoading } = useUpdateUserMutation();
  const [error, setError] = React.useState<string>();
  
  const updateUser = async (
    user: TVendor | TStandard,
    data: TVendorForm | TStandardForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      await mutateAsync({ id: user.sub, data });
      setError(undefined);
    } catch (err: any) {
      const message = err.message;
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return { updateUser, error, isLoading };
};
