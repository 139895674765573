import { useMutation, useQueryClient } from 'react-query';
import { createVendor } from '../api/vendors';
import { TVendorForm } from '../types/vendors';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, CREATE_VENDOR_SUCCESS } from '../hooks/useToast';
import { useNavigate } from 'react-router-dom';

export const useCreateVendorMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (data: TVendorForm) => {
      const token = await getAccessTokenSilently();
      return createVendor(data, token);
    },
    onSuccess: (vendor) => {
      queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      showToast(CREATE_VENDOR_SUCCESS, 'success');
      navigate(`/vendors/${vendor.sub}`);
    },
  });
};
