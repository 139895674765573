import React from 'react';
import { Stack } from '@mui/material';
import { DateTimePicker } from '../DateTimePicker';
import { NoRepeat } from './types';
import { DateTime } from '../../../types/misc';

export type StartEnd = {
  start: Date;
  end: Date;
};

type Props = {
  value?: NoRepeat;
  onChange: (data: DateTime) => void;
};
export const StartEndPicker = ({ value, onChange }: Props) => {
  const [start, setStart] = React.useState<number>(value?.start || Date.now());
  const [end, setEnd] = React.useState<number>(value?.end || Date.now() + 60 * 60 * 1000);

  React.useEffect(() => {
    if (!start || !end) {
      return;
    }
    onChange({ type: 'Does not repeat', start, end, finalDay: end });
  }, [start, end]);

  const handleStartChange = (timestamp: number | null) => {
    if (timestamp) {
      setStart(timestamp);
      if (end <= timestamp) setEnd(timestamp + 60 * 60 * 1000);
    }
  };
  const handleEndChange = (timestamp: number | null) => {
    if (timestamp) setEnd(timestamp);
  };

  return (
    <Stack spacing={'1rem'}>
      <DateTimePicker label="Event Start" value={start} onChange={handleStartChange} />
      <DateTimePicker label="Event End" value={end} onChange={handleEndChange} />
    </Stack>
  );
};
