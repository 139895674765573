import React from 'react';
import { NotificationsNoneOutlinedIcon } from '.';
import Icon from './Icon';
import { Badge, useTheme } from '@mui/material';
// import { Text } from '../text';

export type Props = {
  value: number;
  onClick: () => void;
  disabled?: boolean;
  tabIndex?: number;
};
export const Notification: React.FC<Props> = ({ value, disabled, onClick, tabIndex }) => {
  const theme = useTheme();
  const iconColor = theme.palette.primary.main;
  // const textColor = 'white';
  const text = 'Notifications';
  const badgeBackgroundColor = disabled ? 'lightgrey' : iconColor;
  return (
    <Icon tooltip={text} onClick={onClick} tabIndex={tabIndex}>
      <Badge /* badgeContent={<Text color={textColor}>{value}</Text>} color={'primary'} */>
        <NotificationsNoneOutlinedIcon style={{ color: badgeBackgroundColor }} fontSize="large" />
      </Badge>
    </Icon>
  );
};
