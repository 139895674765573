import { Page } from '../../components/layout/page';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAuth } from '../../auth/useAuth';
import { useGetVendorByIdQuery } from '../../queries/useGetVendorByIdQuery';
import { VendorForm } from '../../components/forms/VendorForm';
import { useUpdateVendor } from './editVendorHook';
import { LoadingPage } from '../LoadingPage';
import { ErrorText } from '../../components/text/ErrorText';

export const VendorEditPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { data: vendor, isLoading: isLoadingVendor } = useGetVendorByIdQuery(id);
  const { updateVendor, error } = useUpdateVendor();

  if (!vendor || isLoadingVendor) return <LoadingPage />;
  const isOwner = user?.sub === vendor.sub;
  const label = isOwner ? 'Update Profile' : 'Update Vendor';

  return (
    <Page header={label} maxWidth="450px">
      <Stack spacing={'1rem'} alignItems={'center'}>
        <VendorForm
          submitLabel={label}
          initialValues={vendor}
          onSubmit={(form, setSubmitting) => updateVendor(form, vendor, setSubmitting)}
        />
        <br />
        {error && <ErrorText>{error}</ErrorText>}
      </Stack>
    </Page>
  );
};
