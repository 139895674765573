import { ReactNode } from 'react';
import { styled } from 'styled-components';

type Style = { max: string };
const Layout = styled.section<Style>`
  width: 100%;
  max-width: ${(props) => props.max};
  margin: auto;
  padding: 0;
`;

type Props = {
  children: ReactNode | ReactNode[];
  maxWidth?: string;
  style?: any;
};
export const Container = ({ maxWidth, children, style }: Props) => {
  return (
    <Layout max={maxWidth || '100%'} style={{ ...style }}>
      {children}
    </Layout>
  );
};

export default Container;
