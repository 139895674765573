export const DATE_SHORT = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as const;

export const DATE_LONG = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as const;

export const TIME = {
  hour: 'numeric',
  minute: 'numeric',
} as const;
