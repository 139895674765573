import { Cloudinary } from '@cloudinary/url-gen';
import { crop } from '@cloudinary/url-gen/actions/resize';
import { Photo } from '../types/misc';
import { AdvancedImage } from '@cloudinary/react';

type ImageItemProps = {
  src?: Photo;
  imageStyles?: { [key: string]: string };
  altText: string;
};
// TODO - This should read from a cloudinaryConfig object
export const CloudinaryImage: React.FC<ImageItemProps> = ({ src, imageStyles, altText }) => {
  const cld = new Cloudinary({ cloud: { cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME } });
  const myImage = src ? cld.image(src?.url) : null;
  const imageCrop = src ? src.crop : null;

  return myImage ? (
    imageCrop && imageCrop.length ? (
      <AdvancedImage
        cldImg={myImage.resize(
          crop().x(imageCrop[0]).y(imageCrop[1]).width(imageCrop[2]).height(imageCrop[3]),
        )}
        alt={altText}
        style={imageStyles}
      />
    ) : (
      <AdvancedImage
        cldImg={myImage}
        alt={altText}
        style={imageStyles}
      />
    )
  ) : (
    <></>
  );
};
