import { useMutation } from 'react-query';
import { unfollowVendor } from '../api/standards';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, REMOVE_FOLLOW_VENDOR_SUCCESS } from '../hooks/useToast';

type UnfollowVendorOptions = {
  userId: string;
  vendorId: string;
};
export const useUnfollowVendorMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, vendorId }: UnfollowVendorOptions) => {
      const token = await getAccessTokenSilently();
      return unfollowVendor(userId, vendorId, token);
    },
    onSuccess: () => {
      showToast(REMOVE_FOLLOW_VENDOR_SUCCESS,'success');
    },
  });
};
