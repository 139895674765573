import { Stack, Typography } from '@mui/material';
import { Page } from '../../components/layout/page';
import { LoadingText } from '../../components/Loading';
import { useAuth } from '../../auth/useAuth';
import { DEFAULT_EVENT_FORM } from '../../constants/event-form';
import { useCreateEvent } from '../admin/CreateEventPage/createEventPageHooks';
import { EventForm } from '../../components/forms/EventForm';
import { LoadingPage } from '../LoadingPage';
import { ErrorText } from '../../components/text/ErrorText';

export const CreateEventPage = () => {
  const { user } = useAuth();
  const { createEvent, error } = useCreateEvent();

  if (!user || user.subscription !== 'vendor') return <LoadingPage />;

  return (
    <Page header={'CREATE EVENT'} maxWidth="450px">
      <Stack spacing={'1rem'}>
        <EventForm
          submitLabel="Create Event"
          initialValues={DEFAULT_EVENT_FORM}
          onSubmit={createEvent}
          vendor={user}
        />
        <br />
        {error && <ErrorText>{error}</ErrorText>}
      </Stack>
    </Page>
  );
};
