import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import { useDialog } from '../context/DialogProvider';
import { useAddEventToCalendarMutation } from '../mutations/useAddEventToCalendarMutation';
import { useBlockEventMutation } from '../mutations/useBlockEventMutation';
import { useCancelEventMutation } from '../mutations/useCancelEventMutation';
import { useDislikeEventMutation } from '../mutations/useDislikeEventMutation';
import { useFollowVendorMutation } from '../mutations/useFollowVendorMutation';
import { useLikeEventMutation } from '../mutations/useLikeEventMutation';
import { useRemoveEventFromCalendarMutation } from '../mutations/useRemoveEventFromCalendarMutation';
import { useUnblockEventMutation } from '../mutations/useUnblockEventMutation';
import { useUncancelEventMutation } from '../mutations/useUncancelEventMutation';
import { useUnfollowVendorMutation } from '../mutations/useUnfollowVendorMutation';
import { CopyDialogContents } from '../components/dialogs/CopyDialogContents';
import { useBlockUserMutation } from '../mutations/useBlockUserMutation';
import { useUnblockUserMutation } from '../mutations/useUnblockUserMutation';
import { usePublicUser } from '../context/PublicUserProvider';
import { useFlagEventMutation } from '../mutations/useFlagEventMutation';
import { useUnflagEventMutation } from '../mutations/useUnflagEventMutation';
import { useUnflagUserMutation } from '../mutations/useUnflagUserMutation';
import { useFlagUserMutation } from '../mutations/useFlagUserMutation';
import { useUnflagNotificationMutation } from '../mutations/useUnflagNotificationMutation';
import { useFlagNotificationMutation } from '../mutations/useFlagNotificationMutation';
import { useBlockNotificationMutation } from '../mutations/useBlockNotificationMutation';
import { useUnblockNotificationMutation } from '../mutations/useUnblockNotificationMutation';

export const useActionButtons = () => {
  const { user, setUser } = useAuth();
  const { publicToggleLikeEvent, publicToggleFollowVendor } = usePublicUser();
  const navigate = useNavigate();
  const { setOpen } = useDialog();
  const {
    mutateAsync: likeEvent,
    error: likeEventError,
    isLoading: isLoadingLike,
  } = useLikeEventMutation();
  const {
    mutateAsync: dislikeEvent,
    error: dislikeEventError,
    isLoading: isLoadingDislike,
  } = useDislikeEventMutation();
  const {
    mutateAsync: addToCalendar,
    error: addToCalendarError,
    isLoading: isLoadingAddEventToCalendar,
  } = useAddEventToCalendarMutation();
  const {
    mutateAsync: removeFromCalendar,
    error: removeEventFromCalendarError,
    isLoading: isLoadingRemoveEventFromCalendar,
  } = useRemoveEventFromCalendarMutation();
  const {
    mutateAsync: blockEvent,
    error: blockEventError,
    isLoading: isLoadingBlockEvent,
  } = useBlockEventMutation();
  const {
    mutateAsync: unblockEvent,
    error: unblockEventError,
    isLoading: isLoadingUnblockEvent,
  } = useUnblockEventMutation();
  const {
    mutateAsync: cancelEvent,
    error: cancelEventError,
    isLoading: isLoadingCancelEvent,
  } = useCancelEventMutation();
  const {
    mutateAsync: uncancelEvent,
    error: uncancelEventError,
    isLoading: isLoadingUncancelEvent,
  } = useUncancelEventMutation();
  const {
    mutateAsync: followVendor,
    error: followVendorError,
    isLoading: isLoadingFollowVendor,
  } = useFollowVendorMutation();
  const {
    mutateAsync: unfollowVendor,
    error: unfollowVendorError,
    isLoading: isLoadingUnfollowVendor,
  } = useUnfollowVendorMutation();
  const {
    mutateAsync: blockUser,
    error: blockUserError,
    isLoading: isLoadingBlockUser,
  } = useBlockUserMutation();
  const {
    mutateAsync: unblockUser,
    error: unblockUserError,
    isLoading: isLoadingUnblockUser,
  } = useUnblockUserMutation();
  const {
    mutateAsync: flagEvent,
    error: flagEventError,
    isLoading: isLoadingFlagEvent,
  } = useFlagEventMutation();
  const {
    mutateAsync: unflagEvent,
    error: unflagEventError,
    isLoading: isLoadingUnflagEvent,
  } = useUnflagEventMutation();
  const {
    mutateAsync: flagUser,
    error: flagUserError,
    isLoading: isLoadingFlagUser,
  } = useFlagUserMutation();
  const {
    mutateAsync: unflagUser,
    error: unflagUserError,
    isLoading: isLoadingUnflagUser,
  } = useUnflagUserMutation();
  const {
    mutateAsync: flagNotification,
    error: flagNotificationError,
    isLoading: isLoadingFlagNotification,
  } = useFlagNotificationMutation();
  const {
    mutateAsync: unflagNotification,
    error: unflagNotificationError,
    isLoading: isLoadingUnflagNotification,
  } = useUnflagNotificationMutation();
  const {
    mutateAsync: blockNotification,
    error: blockNotificationError,
    isLoading: isLoadingBlockNotification,
  } = useBlockNotificationMutation();
  const {
    mutateAsync: unblockNotification,
    error: unblockNotificationError,
    isLoading: isLoadingUnblockNotification,
  } = useUnblockNotificationMutation();

  const toggleLikeClick = async (isLiked: boolean, eventId: string, vendorId: string) => {
    if (user) {
      if (isLiked) {
        const newUser = await dislikeEvent({ userId: user.sub, eventId });
        setUser(newUser);
      } else {
        const newUser = await likeEvent({ userId: user.sub, eventId, vendorId });
        setUser(newUser);
      }
    } else {
      publicToggleLikeEvent(eventId);
    }
  };

  const toggleCalendarClick = async (isOnCalendar: boolean, eventId: string, vendorId: string) => {
    if (user) {
      if (isOnCalendar) {
        const newUser = await removeFromCalendar({ userId: user.sub, eventId });
        setUser(newUser);
      } else {
        const newUser = await addToCalendar({ userId: user.sub, eventId, vendorId });
        setUser(newUser);
      }
    } else {
      // Public does not add to calendar
    }
  };

  const toggleFollowVendorClick = async (isFollowingVendor: boolean, vendorId: string) => {
    if (user) {
      if (isFollowingVendor) {
        const newUser = await unfollowVendor({ userId: user.sub, vendorId });
        setUser(newUser);
      } else {
        const newUser = await followVendor({ userId: user.sub, vendorId });
        setUser(newUser);
      }
    } else {
      publicToggleFollowVendor(vendorId);
    }
  };

  const toggleBlockEventClick = async (blocked: boolean, eventId: string) => {
    if (!blocked) {
      await blockEvent({ eventId });
    } else {
      await unblockEvent({ eventId });
    }
  };

  const toggleCancelEventClick = async (canceled: boolean, eventId: string) => {
    if (!canceled) {
      await cancelEvent({ eventId });
    } else {
      await uncancelEvent({ eventId });
    }
  };

  const toggleFlagEventClick = async (flagged: boolean, eventId: string) => {
    if (!flagged) {
      await flagEvent({ eventId });
    } else {
      await unflagEvent({ eventId });
    }
  };

  const toggleFlagUserClick = async (flagged: boolean, userId: string) => {
    if (!flagged) {
      await flagUser({ userId });
    } else {
      await unflagUser({ userId });
    }
  };

  const toggleFlagNotificationClick = async (flagged: boolean, notificationId: string) => {
    if (!flagged) {
      await flagNotification({ notificationId });
    } else {
      await unflagNotification({ notificationId });
    }
  };

  const toggleBlockUserClick = async (blocked: boolean, userId: string) => {
    if (!blocked) {
      await blockUser({ userId });
    } else {
      await unblockUser({ userId });
    }
  };

  const toggleBlockNotificationClick = async (blocked: boolean, notificationId: string) => {
    if (!blocked) {
      await blockNotification({ notificationId });
    } else {
      await unblockNotification({ notificationId });
    }
  };

  const onEditEventClick = (eventId: string) => navigate(`/events/${eventId}/edit`);
  const onEditVendorClick = (eventId: string) => navigate(`/vendors/${eventId}/edit`);
  // handle query string navigation link
  const onNotificationsClick = (eventId?: string, vendorId?: string, userId?: string) => {
    const params: { [key: string]: string } = {};
    if (eventId) {
      params.eventId = eventId;
    }
    if (vendorId) {
      params.vendorId = vendorId;
    }
    if (userId) {
      params.userId = userId;
    }
    return navigate(
      `/notifications${Object.keys(params).length ? `?${Object.keys(params).map((key, index) => `${key}=${params[key]}${index === Object.keys(params).length - 1 ? '' : '&'}`)}` : ''}`,
    );
  };
  const onShareClick = () => setOpen(true, <CopyDialogContents />);
  const onEventStatisticsClick = (eventId: string) => navigate(`/events/${eventId}/statistics`);
  const onVendorStatisticsClick = (eventId: string) => navigate(`/vendors/${eventId}/statistics`);

  const likeToggleMutation = {
    isLoading: isLoadingLike || isLoadingDislike,
    error: likeEventError || dislikeEventError,
  };
  const calendarToggleMutation = {
    isLoading: isLoadingAddEventToCalendar || isLoadingRemoveEventFromCalendar,
    error: addToCalendarError || removeEventFromCalendarError,
  };
  const blockEventToggleMutation = {
    isLoading: isLoadingBlockEvent || isLoadingUnblockEvent,
    error: blockEventError || unblockEventError,
  };
  const cancelEventToggleMutation = {
    isLoading: isLoadingCancelEvent || isLoadingUncancelEvent,
    error: cancelEventError || uncancelEventError,
  };
  const flagEventToggleMutation = {
    isLoading: isLoadingFlagEvent || isLoadingUnflagEvent,
    error: flagEventError || unflagEventError,
  };
  const flagUserToggleMutation = {
    isLoading: isLoadingFlagUser || isLoadingUnflagUser,
    error: flagUserError || unflagUserError,
  };
  const flagNotificationToggleMutation = {
    isLoading: isLoadingFlagNotification || isLoadingUnflagNotification,
    error: flagNotificationError || unflagNotificationError,
  };
  const followVendorToggleMutation = {
    isLoading: isLoadingFollowVendor || isLoadingUnfollowVendor,
    error: followVendorError || unfollowVendorError,
  };
  const blockUserToggleMutation = {
    isLoading: isLoadingBlockUser || isLoadingUnblockUser,
    error: blockUserError || unblockUserError,
  };
  const blockNotificationToggleMutation = {
    isLoading: isLoadingBlockNotification || isLoadingUnblockNotification,
    error: blockNotificationError || unblockNotificationError,
  };

  return {
    likeToggleMutation,
    calendarToggleMutation,
    blockEventToggleMutation,
    cancelEventToggleMutation,
    flagEventToggleMutation,
    followVendorToggleMutation,
    blockUserToggleMutation,
    blockNotificationToggleMutation,
    flagUserToggleMutation,
    flagNotificationToggleMutation,
    toggleLikeClick,
    toggleCalendarClick,
    toggleBlockEventClick,
    toggleBlockNotificationClick,
    toggleCancelEventClick,
    toggleFlagEventClick,
    toggleFlagUserClick,
    toggleFlagNotificationClick,
    toggleFollowVendorClick,
    toggleBlockUserClick,
    onEditEventClick,
    onEditVendorClick,
    onNotificationsClick,
    onShareClick,
    onEventStatisticsClick,
    onVendorStatisticsClick,
  };
};
