import { styled } from 'styled-components';
import SLOM from '../../assets/SLOM_White_Trimmed.png';
import { Colors } from '../../theme/colors';
import { Link as LinkDOM, useNavigate } from 'react-router-dom';
import { PALETTE } from '../../theme/palette';
import { Notification } from '../icons/Notification';
import { useAuth } from '../../auth/useAuth';

const HEADER_SMALL = 840;

const Layout = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px 3px ${PALETTE.primary.main};
  background-color: white;

  @media (max-width: ${HEADER_SMALL}px) {
    flex-direction: column-reverse;
  }
`;
const Top = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  transform: translate(0, -20%);
`;
const LinksLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  padding: 3rem 1rem 1rem 1rem;
  border-bottom: 1px solid ${Colors.primary};

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
  }
`;
const LinksRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  padding: 3rem 3rem 1rem 1rem;
  border-bottom: 1px solid ${Colors.primary};

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
  }
`;
const LinkBotomLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  padding: 1rem 3rem 1rem 1rem;

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
  }
`;
const LinkBotomRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  padding: 1rem 3rem 1rem 1rem;

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
`;
export const Logo = styled.img`
  width: 150px;
  height: 150px;
  transform: rotate(-90deg);
  cursor: pointer;
`;
export const Link = styled(LinkDOM)`
  color: ${Colors.primary};
  font-size: 24px;
  text-decoration: none;
`;

const Header = () => {
  const navigate = useNavigate();
  const {
    user,
    signIn,
    signOut,
    isAuthenticated,
    isStandardUser,
    isVendorUser,
    isAdminUser,
    isBlocked,
    isEmailVerified,
  } = useAuth();

  const showMyCalendar = (isStandardUser() || isVendorUser()) && !isBlocked() && isEmailVerified();
  const showProfileLink = isAuthenticated && !isAdminUser();
  const showCreateEvent = isAdminUser() || (isVendorUser() && !isBlocked() && isEmailVerified());

  return (
    <Layout>
      <Top>
        <LinksLeft>
          <Link to="/browse">BROWSE</Link>
          {isAdminUser() && <Link to="/audits">AUDITS</Link>}
        </LinksLeft>
        <LinkBotomLeft>
          {showMyCalendar && <Link to="/calendar">MY CALENDAR</Link>}
          {isAdminUser() && <Link to="/vendors/create">CREATE VENDOR</Link>}
          {showCreateEvent && (
            <Link style={{ textAlign: 'right' }} to="/events/create">
              CREATE EVENT
            </Link>
          )}
        </LinkBotomLeft>
      </Top>
      <Logo
        src={SLOM}
        alt="SLOM Logo"
        onClick={() => navigate('/')}
        tabIndex={0}
        onKeyUp={(e) => e.key === 'Enter' && navigate('/')}
      />
      <Top>
        <LinksRight>
          {!isAuthenticated && (
            <Link to="/" onClick={() => signIn()}>
              SIGN IN / REGISTER
            </Link>
          )}
          {isAuthenticated && (
            <Link to="/" onClick={() => signOut()}>
              SIGN OUT
            </Link>
          )}
        </LinksRight>
        <LinkBotomRight>
          {isAdminUser() && <Link to="/flags">FLAGS</Link>}
          {isAdminUser() && <Link to="/feedback">FEEDBACK</Link>}
          {/* {isStandardUser() && !isBlocked() && (
            <Link to={`/notifications?userId=${user?.sub.replace('|', '%7C')}`}>
              <Notification value={1} tabIndex={-1} onClick={() => {}} disabled={false} />
            </Link>
          )} */}
          {!isAuthenticated && <Link to="join">BECOME A MEMBER</Link>}
          {showProfileLink && (
            <Link
              to={isAdminUser() ? `/vendors/${user?.sub.replace('|', '%7C')}/edit` : '/profile'}
            >
              PROFILE
            </Link>
          )}
        </LinkBotomRight>
      </Top>
    </Layout>
  );
};

export default Header;
