import React from 'react';
import { Divider, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { Page } from '../../../components/layout/page';
import Dropdown from '../../../components/inputs/Dropdown';
import { useGetVendorsQuery } from '../../../queries/useGetVendorsQuery';
import { LoadingText } from '../../../components/Loading';
import { EventForm } from '../../../components/forms/EventForm';
import { ErrorText } from '../../../components/text/ErrorText';
import { useCreateEvent } from './createEventPageHooks';
import { DEFAULT_EVENT_FORM } from '../../../constants/event-form';
import { TVendor } from '../../../types/vendors';
import { Text } from '../../../components/text';
import { CheckOutlinedIcon } from '../../../components/icons';
import { LoadingPage } from '../../LoadingPage';

export const CreateEventPage = () => {
  const [selectedVendor, setSelectedVendor] = React.useState<TVendor>();
  const { createEvent, error } = useCreateEvent();
  const {
    isLoading: loadingVendors,
    error: vendorsError,
    data: vendors,
  } = useGetVendorsQuery({ blocked: false });

  if (loadingVendors || !vendors || !vendors.length) return <LoadingPage />;
  if (vendorsError)
    return (
      <Typography color={'error'} textAlign={'center'}>
        Could not load vendors. Please reload the page.
      </Typography>
    );

  return (
    <Page header="CREATE EVENT" maxWidth="450px">
      <Stack spacing={'1rem'}>
        <Paper elevation={5} style={{ padding: '1rem' }}>
          <Dropdown
            label="SELECT A VENDOR"
            value={selectedVendor?.companyName || ''}
            options={vendors
              .map((v) => v.companyName)
              .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
            renderItem={(option) => {
              const vendor = vendors.filter((v) => v.companyName === option)[0];
              if (!vendor) return null;
              if (!vendor.claimed)
                return (
                  <MenuItem key={option} value={option}>
                    <Text>{option}</Text>
                  </MenuItem>
                );
              return (
                <MenuItem key={option} value={option}>
                  <Stack width={'100%'} direction={'row'} alignItems={'space-between'}>
                    <Text style={{ flex: 1 }}>{option}</Text>
                    <CheckOutlinedIcon style={{ flex: 0.25, color: 'green' }} />
                  </Stack>
                </MenuItem>
              );
            }}
            onChange={(name) => {
              const vendor = vendors.filter((v) => v.companyName === name)[0];
              setSelectedVendor(vendor);
            }}
          />
        </Paper>
        <Divider />
        {selectedVendor && (
          <EventForm
            submitLabel="Create Event"
            initialValues={DEFAULT_EVENT_FORM}
            onSubmit={createEvent}
            vendor={selectedVendor}
          />
        )}
        {!selectedVendor && <ErrorText>{'Please select a vendor'}</ErrorText>}
        <br />
        {error && <ErrorText>{error}</ErrorText>}
      </Stack>
    </Page>
  );
};
