export const COMPANY_MISSION =
  'To help people meet in real life, share meaningful experiences, and build stronger communities.';

export const COMPANY_MISSION_PARAGRAPHS = [
  'At Social Life of Mine, we believe that life is better when shared. Our mission may sound simple—to help people meet in real life, share common experiences, and build genuine community connections—but it stems from a deeply personal and universal need.',
  'We’ve witnessed and felt the growing epidemic of loneliness, depression, and anxiety in our culture. Studies show that meaningful social connections can reduce stress, combat mental health challenges, and even improve physical health. In fact, loneliness has been compared to smoking 15 cigarettes a day in terms of its impact on health. Conversely, close relationships and shared experiences increase happiness, strengthen resilience, and help us live longer, healthier lives.',
  'Humans are wired to connect. We thrive on face-to-face interaction and the intangible magic of shared moments. Yet in an age dominated by social media and virtual connections, many of us find it harder than ever to make real-world connections. Scrolling through endless online posts or fragmented community calendars doesn’t always translate to meaningful action or memorable experiences. That’s where we come in.',
  'We created Social Life of Mine to bridge this gap—to make it easier for people to step out of the digital world and into the real one. Through this website, you can discover local events, connect with others who share your interests, and cultivate relationships that enrich your life. Whether you’re looking to make new friends, explore your community, or simply get out of the house, we’re here to help.',
  'This website is the result of countless hours of conversation, brainstorming, and refinement. We’ve carefully considered user safety, accessibility, and what makes Social Life of Mine stand out from other tools. We know this is a work in progress, and we’re committed to evolving and improving to better serve our mission.',
  'At its core, Social Life of Mine is about bringing people together in ways that are meaningful, safe, and impactful. Because life is richer when we connect with others—in real time, in real life, in real ways.',
];
