import { useMutation, useQueryClient } from 'react-query';
import { TEvent, TEventForm } from '../types/events';
import { updateEvent } from '../api/events';
import { useAuth0 } from '@auth0/auth0-react';
import { UPDATE_EVENT_SUCCESS, useToast } from '../hooks/useToast';
import { useNavigate } from 'react-router-dom';

type EventUpdateOptions = {
  data: TEventForm;
  eventId: string;
};
export const useUpdateEventMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({ data, eventId }: EventUpdateOptions) => {
      const token = await getAccessTokenSilently();
      return updateEvent(data, eventId, token);
    },
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', event._id] });
      queryClient.invalidateQueries({ queryKey: ['getEvents'] });
      showToast(UPDATE_EVENT_SUCCESS, 'success');
      navigate(`/events/${event._id}`);
    },
  });
};
