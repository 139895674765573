import { Stack } from '@mui/material';
import DatePicker from '../DatePicker';
import WeekdayPicker from '../WeekdayPicker';
import React from 'react';
import { DateTimePicker } from '../DateTimePicker';
import { WeeklyRepeat } from './types';
import { DateTime, Day } from '../../../types/misc';
import dayjs from 'dayjs';

type Props = {
  value?: WeeklyRepeat;
  onChange: (data: DateTime) => void;
};
export const WeeklyRepeatPicker = ({ value, onChange }: Props) => {
  const [start, setStart] = React.useState<number>(value?.start || Date.now());
  const [end, setEnd] = React.useState<number>(value?.end || Date.now() + 60 * 60 * 1000);
  const [finalDay, setFinalDay] = React.useState<number>(
    value?.finalDay || Date.now() + 7 * 24 * 60 * 60 * 1000 + 60 * 60 * 1000,
  );
  const [days, setDays] = React.useState<Day[]>(value?.days || []);

  React.useEffect(() => {
    if (!start || !end || !finalDay || !days) {
      return;
    }
    onChange({ type: 'Repeat Weekly', start, end, finalDay, days });
  }, [start, end, finalDay, days]);

  const handleStartChange = (timestamp: number | null) => {
    if (timestamp) {
      setStart(timestamp);
      if (end <= timestamp) handleEndChange(timestamp + 60 * 60 * 1000);
    }
  };
  const handleEndChange = (timestamp: number | null) => {
    if (timestamp) {
      setEnd(timestamp);
      const date = new Date(timestamp);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      //keeps time of finalDay synced with time of end
      if (finalDay <= timestamp) {
        handleFinalDayChange(
          dayjs(timestamp + 7 * 24 * 60 * 60 * 1000 + 60 * 60 * 1000)
            .toDate()
            .setHours(hours, minutes, 0, 0),
        );
      } else {
        handleFinalDayChange(dayjs(finalDay).toDate().setHours(hours, minutes, 0, 0));
      }
    }
  };
  const handleFinalDayChange = (timestamp: number | null) => {
    if (timestamp) setFinalDay(timestamp);
  };

  return (
    <Stack spacing={'1rem'}>
      <DateTimePicker label="Event Start" value={start} onChange={handleStartChange} />
      <DateTimePicker label="Event End" value={end} onChange={handleEndChange} />
      <DatePicker
        targetTime={end}
        label="Final Repeat Date"
        value={finalDay}
        onChange={(e) => handleFinalDayChange(e.target.value)}
      />
      <WeekdayPicker value={days} label={'Days of the Week'} onChange={setDays} />
    </Stack>
  );
};
