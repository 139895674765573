export type Address = {
  venue?: string;
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
};
export type DayHours = {
  day: DayType;
  hours: Hours;
};

export type Hours = {
  open: number;
  close: number;
};

export type HoursSpan = {
  from: number;
  to: number;
};

export type DayType = keyof typeof DAY_VALUES;

export const REPEAT_OPTIONS_MAP = {
  'Does not repeat': 'Does Not Repeat',
  'Repeat Daily': 'Repeat Daily',
  'Repeat Weekly': 'Repeat Weekly',
};

export type RepeatType = keyof typeof REPEAT_OPTIONS_MAP;

export type DateTime = {
  type: RepeatType;
  start: number;
  end: number;
  finalDay: number;
  days?: Day[];
};

export type Day = keyof typeof DAYS;

export const DAYS = {
  Su: 'Sunday',
  M: 'Monday',
  Tu: 'Tuesday',
  W: 'Wednesday',
  Th: 'Thursday',
  F: 'Friday',
  Sa: 'Saturday',
};

export const DAYS_OF_WEEK = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'] as const;

type DayValues = {
  [day: string]: number;
};
export const DAY_VALUES = DAYS_OF_WEEK.reduce<DayValues>((acc, day, i) => {
  acc[day] = i;
  return acc;
}, {});

type Free = {
  type: 'Free';
};
type Single = {
  type: 'Single';
  min: number;
};
type Range = {
  type: 'Range';
  min: number;
  max: number;
};

export type Price = Free | Single | Range;

export type Photo = {
  url: string;
  crop: number[];
};

export type MinMax = {
  min: number;
  max: number;
};

export type DateTimeSettings = {
  showAllDateTime: boolean;
  availableDayTimes: [];
  dateSearch: DateRange;
};

export type DateRange = {
  from: string;
  to: string;
};
