import { Stack } from '@mui/material';
import { LoadingText } from '../components/Loading';
import { Page } from '../components/layout/page';

export const LoadingPage = () => {
  return (
    <Page>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        style={{ height: '100%', background: 'red' }}
      ></Stack>
    </Page>
  );
};
