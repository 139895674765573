import { useMutation, useQueryClient } from 'react-query';
import { TFeedbackForm, createFeedback } from '../api/feedback';
import { useToast, CREATE_FEEDBACK_SUCCESS } from '../hooks/useToast';

export const useCreateFeedbackMutation = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async (data: TFeedbackForm) => {
      return createFeedback(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getFeedback'] });
      showToast(CREATE_FEEDBACK_SUCCESS, 'success');
    },
  });
};
