import React, { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

if (!domain) throw new Error('Could not load REACT_APP_AUTH0_DOMAIN');
if (!clientId) throw new Error('Could not load REACT_APP_AUTH0_CLIENT_ID');
if (!audience) throw new Error('Could not load REACT_APP_AUTH0_AUDIENCE');

export const AUTH0_CONFIG = {
  domain,
  clientId,
  audience,
};

export const Auth0ProviderWithHistory: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const authorizationParams = {
    audience: AUTH0_CONFIG.audience,
    redirect_uri: window.location.origin,
  };

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_CONFIG.domain}
      clientId={AUTH0_CONFIG.clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
