import { TVendor, TVendorForm } from '../types/vendors';
import { createFetch, createQueryParams } from './createFetch';

export type GetVendorsOptions = {
  text?: string;
  claimed?: boolean;
  blocked?: boolean;
  flagged?: boolean; // TODO
};
export const getVendors = async (options: GetVendorsOptions) => {
  const params = createQueryParams(options);
  const query = new URLSearchParams(params).toString();
  const response = await createFetch('/vendors?' + query, 'GET');
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TVendor[];
};

export const getVendorById = async (vendorId: string | undefined) => {
  const response = await createFetch(`/vendors/${vendorId}`, 'GET');
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TVendor;
};

export const deleteVendorPhoto = async (photoId: string, vendorId: string, token?: string) => {
  const body =
    vendorId !== ''
      ? JSON.stringify({ publicId: photoId, vendorId: vendorId })
      : JSON.stringify({ publicId: photoId });
  const response = await createFetch(`/photos`, 'DELETE', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
};

export const createVendor = async (data: TVendorForm, token?: string) => {
  const body = JSON.stringify(data);
  const response = await createFetch('/vendors', 'POST', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TVendor;
};

export const updateVendor = async (data: TVendorForm, vendor: TVendor, token?: string) => {
  const { sub: _id, claimed } = vendor;
  const body = JSON.stringify(data);
  const url = claimed ? `/users/${_id}` : `/vendors/${_id}`;
  const response = await createFetch(url, 'PATCH', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TVendor;
};
