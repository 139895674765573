import { Stack } from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Text } from '../text';
import React from 'react';
import { CustomEvent } from './WeekdayHoursPicker';

type Props = {
  id?: string;
  name?: string;
  onChange: (event: CustomEvent) => void;
  onBlur?: (event: CustomEvent) => void;
  value?: number;
  label?: string;
  error?: boolean;
  helperText?: string | false;
  required?: boolean;
  disabled?: boolean;
  targetTime: number | 'start' | 'end'; //start or end of selected day
};
const DatePicker = ({
  id,
  name,
  value,
  label,
  error,
  helperText,
  onChange,
  onBlur,
  required,
  disabled,
  targetTime,
}: Props) => {
  const star = required ? '*' : '';
  const labelText = `${label}` + star;

  const updateTimeOfFinalDay = (value: dayjs.Dayjs | null, timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    if (value) return value.toDate().setHours(hours, minutes, 0, 0);
  };

  return (
    <Stack>
      {label && <Text color={disabled ? 'lightgrey' : undefined}>{labelText}</Text>}
      <MUIDatePicker
        value={dayjs(value) || dayjs(Date.now())}
        disabled={disabled}
        onChange={(d) => {
          onChange({
            type: 'change',
            target: {
              id: id || 'id',
              name: name || 'name',
              value:
                typeof targetTime === 'number'
                  ? updateTimeOfFinalDay(d, targetTime)
                  : targetTime === 'start'
                    ? d?.toDate().setHours(0, 0, 0, 0)
                    : d?.toDate().setHours(23, 59, 59, 999) || dayjs(Date.now()),
            },
          });
        }}
        slotProps={{
          textField: {
            inputProps: {
              'aria-label': label || 'Date Input',
            },
            onBlur: (e) => {
              const syntheticEvent = new FocusEvent('blur', {
                bubbles: true,
                cancelable: true,
                relatedTarget: e.target,
              }) as unknown as React.FocusEvent<HTMLInputElement>;

              Object.defineProperty(syntheticEvent, 'target', {
                writable: true,
                value: { name: name || 'name' },
              });
              onBlur && onBlur(syntheticEvent);
            },
            helperText,
            error,
          },
        }}
      />
    </Stack>
  );
};

export default DatePicker;
