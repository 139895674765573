import { styled } from 'styled-components';
import { Text } from '../../components/text';
import { Divider, Grid, Stack, useTheme } from '@mui/material';
import { Page } from '../../components/layout/page';
import { COMPANY_BIOS } from '../../constants/employees';

export const Paragraph = styled.p`
  width: 60%;
  margin: auto;
  margin-bottom: 2rem;
  color: white;
  text-align: justify;
  font-size: 24px;
`;

export const ContactUsPage = () => {
  return (
    <Page header={'CONTACT US'} maxWidth="900px">
      <Stack spacing={'1rem'} alignItems={'center'}>
        <br />
        <Grid container columnGap={'3rem'} justifyContent={'center'}>
          {COMPANY_BIOS.map((person) => (
            <Grid key={person.name} item>
              <Stack alignItems={'center'} spacing={'1rem'}>
                <img
                  width={'150px'}
                  height={'150px'}
                  src={person.image}
                  alt={person.name}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: '0 0 3px 1px grey',
                  }}
                />
                <Stack spacing={'1rem'}>
                  <Text fontSize="18px" fontWeight="bold" textAlign="center">
                    {person.name}
                  </Text>
                  <Text fontSize="18px" textAlign="center">
                    {person.title}
                  </Text>
                  <Text fontSize="18px" textAlign="center">
                    {person.specialty}
                  </Text>
                  <Text fontSize="18px" textAlign="center">
                    <a href={`mailto:${person.email}`}>{person.email}</a>
                  </Text>
                  <Divider flexItem />
                  <br />
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Page>
  );
};
