import { Divider, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Text } from '../text';
import { DATE_SHORT, TIME } from '../../formatting';
import { formatAddress, formatPrice } from '../../utils/formatting';
import React from 'react';
import { getNextOcurrence, getReccurringLabel } from '../../utils/days';
import { useAuth } from '../../auth/useAuth';
import { TEvent } from '../../types/events';
import { DateTimeRepeat } from '../inputs/EventDateTimePicker/types';
import { ImageDisplay } from '../ImageDisplay';
import { ActionButtons } from '../ActionButtons';
import { useActionButtons } from '../../hooks/useActionButtons';
import { usePublicUser } from '../../context/PublicUserProvider';
import { isEventOnCalendar, isLikedEvent } from '../../utils/userUtils';
import { Item } from './Item';

type DateDisplayProps = {
  dateTime: DateTimeRepeat;
};
const DateDisplay: React.FC<DateDisplayProps> = ({ dateTime }) => {
  const reoccurringLabel = getReccurringLabel(dateTime);
  const nextOcurrenceTimestamp = getNextOcurrence(new Date(), dateTime);
  const nextOcurrence = new Date(nextOcurrenceTimestamp).toLocaleDateString(undefined, DATE_SHORT);
  return (
    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
      {dateTime.type === 'Does not repeat' && (
        <Text fontSize="24px" fontWeight="bolder" textAlign="center">
          {nextOcurrence}
        </Text>
      )}
      <Text fontSize="24px" fontWeight="bolder" textAlign="center">
        {new Date(dateTime.start).toLocaleTimeString(undefined, TIME)}
      </Text>
      {dateTime.type !== 'Does not repeat' && (
        <Text textAlign="center" fontSize="16px">
          {reoccurringLabel}
        </Text>
      )}
    </Stack>
  );
};

type InfoItemProps = {
  event: TEvent;
};
const InfoDisplay: React.FC<InfoItemProps> = ({ event }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const textAlign = isSm ? 'center' : 'left';

  const neededAddressValues: Record<string, string | undefined> = {
    street: event.address.street,
    city: event.address.city,
    state: event.address.state,
  };

  const shouldDisplayAddress =
    Object.keys(neededAddressValues).filter((key) => neededAddressValues[key]).length > 0;

  return (
    <Stack
      sx={{
        width: '100%',
        padding: { sm: '1rem 0', md: '1rem' },
      }}
      justifyContent={'center'}
      alignItems={isSm ? 'center' : 'flex-start'}
      spacing={'1rem'}
    >
      {event.name && (
        <Text fontWeight="bold" fontSize="24px" textAlign={textAlign}>
          {event.name}
        </Text>
      )}
      <br />
      {event.vendorName && (
        <Text fontWeight="bold" fontSize="18px" textAlign={textAlign}>
          {event.vendorName}
        </Text>
      )}
      {event.address.venue && (
        <Text fontSize="18px" textAlign={textAlign}>
          {event.address.venue}
        </Text>
      )}
      {shouldDisplayAddress && (
        <Text fontSize="18px" textAlign={textAlign}>
          {formatAddress(neededAddressValues)}
        </Text>
      )}
      {event.price && (
        <Text fontSize="18px" textAlign={textAlign}>
          <Text component="span" fontWeight="bold">
            Price:{' '}
          </Text>
          {formatPrice(event.price)}
        </Text>
      )}
      <br />
    </Stack>
  );
};

type Props = {
  event: TEvent;
  onError: (error: unknown) => void;
  onClick: (event: TEvent) => void;
};
export const EventItem: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const { event, onClick, onError } = props;
  const { user, isPublicUser, isStandardUser, isVendorUser, isAdminUser } = useAuth();
  const { publicIsLikedEvent } = usePublicUser();

  const {
    likeToggleMutation,
    calendarToggleMutation,
    toggleLikeClick,
    toggleCalendarClick,
    // onNotificationsClick,
  } = useActionButtons();

  const { error: likeEventError, isLoading: isLoadingLike } = likeToggleMutation;
  const { error: calendarError, isLoading: isLoadingCalendar } = calendarToggleMutation;

  const isOnCalendar = isStandardUser() ? isEventOnCalendar(event._id, user) : false;

  const isLiked = isPublicUser()
    ? publicIsLikedEvent(event._id)
    : isStandardUser()
      ? isLikedEvent(event._id, user)
      : false;

  const onLikeClick = () => toggleLikeClick(isLiked, event._id, event.vendorId);
  const onCalendarClick = () => toggleCalendarClick(isOnCalendar, event._id, event.vendorId);
  // const onNotification = () => onNotificationsClick(event._id);

  const error = likeEventError || calendarError;

  React.useEffect(() => {
    if (error) onError(error);
  }, [error, onError]);

  return (
    <Item
      ariaLabel={`${event.name} Event Card`}
      color={theme.palette.primary.main}
      disabled={(event.blocked || event.canceled) && !isAdminUser()}
      tabIndex={0}
      onClick={() => onClick(event)}
    >
      <Grid container item xs={12} md={10}>
        <Grid item xs={12} sm={12} md={3}>
          <DateDisplay dateTime={event.dateTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} justifyContent={'center'} alignItems={'center'}>
          <ImageDisplay src={event.photo} alt={event.name} />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <InfoDisplay event={event} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={1}>
        <Divider orientation={isMd ? 'horizontal' : 'vertical'} />
      </Grid>
      <Grid item xs={12} md={1}>
        <ActionButtons
          isLoadingLike={isLoadingLike}
          isLoadingCalendar={isLoadingCalendar}
          isLiked={isLiked}
          isOnCalendar={isOnCalendar}
          onLikeClick={isPublicUser() || isStandardUser() ? onLikeClick : undefined}
          onCalendarClick={isStandardUser() ? onCalendarClick : undefined}
          // onNotificationClick={
          //   (isStandardUser() && isOnCalendar) || (isVendorUser() && isOwner) || isAdminUser()
          //     ? onNotification
          //     : undefined
          // }
        />
      </Grid>
      <Grid item xs={12}>
        {event.blocked && (
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            padding={'1rem'}
            marginTop={'1rem'}
            style={{ background: '#0000001c' }}
          >
            <Text textAlign="center">Event is blocked</Text>
            <Text textAlign="center">
              If you think this is a mistake, please contact Paige at paige@sociallifeofmine.com
            </Text>
          </Stack>
        )}
        {event.canceled && !event.blocked && (
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            padding={'1rem'}
            marginTop={'1rem'}
            style={{ background: '#0000001c' }}
          >
            <Text textAlign="center">Event is canceled</Text>
          </Stack>
        )}
      </Grid>
    </Item>
  );
};
