import Paige_Profile from '../assets/Paige_Profile.jpeg';
import Brandon_Profile from '../assets/Brandon_Profile.jpg';

export const brandon = {
  name: 'BRANDON MENYES',
  title: 'Founder, COO',
  email: 'brandon@sociallifeofmine.com',
  specialty: 'Developer',
  image: Brandon_Profile,
};

export const paige = {
  name: 'PAIGE DENIER',
  title: 'Founder, CEO',
  email: 'paige@sociallifeofmine.com',
  specialty: 'Vendor & User Support',
  image: Paige_Profile,
};

export const COMPANY_BIOS = [paige, brandon];
