import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from '@mui/material';
import { TStandardForm } from '../../types/standards';
import { PRONOUNS } from '../../constants/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from '../inputs/DatePicker';
import { PrivacyLabel } from './PrivacyLabel';
import dayjs from 'dayjs';

const standardFormSchema = yup.object({
  pronouns: yup.string().required('Required.'),
  birthday: yup
    .number()
    .required('Required.')
    .test('is-over-18', 'Must be 18 or older', (value) => {
      const ageInYears = dayjs().diff(value, 'year');
      return ageInYears >= 18;
    }),
});

export type StandardFormProps = {
  submitLabel: string;
  initialValues: TStandardForm;
  onSubmit: (form: TStandardForm, setSubmitting: (submitting: boolean) => void) => Promise<void>;
  showNotices?: boolean;
};
export const StandardForm: React.FC<React.PropsWithChildren<StandardFormProps>> = ({
  submitLabel,
  initialValues,
  showNotices,
  onSubmit,
}) => {
  const formik = useFormik({
    validateOnMount: true,
    validationSchema: standardFormSchema,
    initialValues: {
      pronouns: initialValues.pronouns || 'He/Him',
      birthday: initialValues.birthday || Date.now(),
    },
    onSubmit: (values, { setSubmitting }) =>
      onSubmit(
        {
          pronouns: values.pronouns,
          birthday: values.birthday,
        },
        setSubmitting,
      ),
  });

  return (
    <Paper elevation={5} style={{ padding: '2rem', borderRadius: '10px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={'1rem'}>
          <FormControl fullWidth>
            <InputLabel id="pronouns-label">Pronouns</InputLabel>
            <Select
              labelId="pronouns-label"
              id="pronouns"
              name="pronouns"
              label="Pronouns"
              value={formik.values.pronouns}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pronouns && Boolean(formik.errors.pronouns)}
            >
              {PRONOUNS.map((pronoun) => (
                <MenuItem key={pronoun} value={pronoun}>
                  {pronoun}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.pronouns && (
              <FormHelperText>{formik.errors.pronouns as string}</FormHelperText>
            )}
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="birthday"
              name="birthday"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.birthday && Boolean(formik.errors.birthday)}
              helperText={formik.touched.birthday && (formik.errors.birthday as string)}
              value={formik.values.birthday}
              label="Date of Birth"
              targetTime='start'
            />
          </LocalizationProvider>
          <Divider flexItem />
          <br />
          {showNotices && <PrivacyLabel />}
          <Stack direction={'row'} justifyContent={'center'}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
            >
              {submitLabel}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Paper>
  );
};
