import { TNotification, TNotificationForm } from '../types/notification';
import { createFetch, createQueryParams } from './createFetch';

export type GetNotificationsParams = {
  eventId?: string;
  vendorId?: string;
  userId?: string;
  flagged?: boolean;
  blocked?: boolean;
};

// TODO - Do we use this?
export const getNotification = async (id?: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}`, 'GET', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification;
};

export const getNotifications = async (options?: GetNotificationsParams, token?: string) => {
  const params = createQueryParams(options || {});
  const query = new URLSearchParams(params).toString();
  const response = await createFetch(`/notifications?` + query, 'GET', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification[];
};

export const getAudits = async (token?: string) => {
  const response = await createFetch(`/audits`, 'GET', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification[];
};

export const createNotification = async (form: TNotificationForm, token?: string) => {
  const body = JSON.stringify(form);
  const response = await createFetch(`/notifications`, 'POST', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification;
};

// TODO - Edit message?
export const updateNotification = async (id: string, form: TNotificationForm, token?: string) => {
  const body = JSON.stringify(form);
  const response = await createFetch(`/notifications/${id}`, 'PATCH', body, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification;
};

export const deleteNotification = async (id: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}`, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
};

export const blockNotificationById = async (id?: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}/block`, 'POST', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification;
};

export const unblockNotificationById = async (id?: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}/block`, 'DELETE', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification;
};

export const flagNotificationById = async (id?: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}/flag`, 'POST', undefined, token);
  const result = await response.json();
  return result as TNotification;
};

export const unflagNotificationById = async (id?: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}/flag`, 'DELETE', undefined, token);
  const result = await response.json();
  return result as TNotification;
};

export const cancelNotificationById = async (id?: string, token?: string) => {
  const response = await createFetch(`/notifications/${id}/cancel`, 'POST', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TNotification;
};
