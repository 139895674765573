import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Paper, Stack } from '@mui/material';
import { TextField } from '@mui/material';
import { WeekdayHoursPicker } from '../inputs/WeekdayHoursPicker';
import { TVendorForm } from '../../types/vendors';
import { CloudinaryUploadWidget } from '../inputs/CloudinaryUploadWidget';
import { PrivacyLabel } from './PrivacyLabel';
import { useParams } from 'react-router-dom';

const vendorFormSchema = yup.object({
  companyName: yup.string().required('Please enter your company name.'),
  street: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipcode: yup.string(),
  missionStatement: yup.string().required('Please enter your company mission statement.'),
  businessDescription: yup.string().required('Please enter your company business description.'),
  phoneNumber: yup.string(),
  websiteLink: yup.string(),
  daysOfOperation: yup.array().required('Please enter your company hours.'),
  photo: yup.mixed(),
});

export type VendorFormProps = {
  submitLabel: string;
  initialValues: TVendorForm;
  onSubmit: (form: TVendorForm, setSubmitting: (submitting: boolean) => void) => Promise<void>;
  showNotices?: boolean;
  onCancel?: () => void;
};
export const VendorForm: React.FC<React.PropsWithChildren<VendorFormProps>> = ({
  submitLabel,
  initialValues,
  showNotices,
  onSubmit,
  onCancel,
}) => {
  const { id } = useParams();
  const formik = useFormik({
    validateOnMount: true,
    validationSchema: vendorFormSchema,
    initialValues: {
      companyName: initialValues.companyName || '',
      street: initialValues.address?.street || '',
      city: initialValues.address?.city || '',
      state: initialValues.address?.state || '',
      zipcode: initialValues.address?.zipcode || '',
      missionStatement: initialValues.missionStatement || '',
      businessDescription: initialValues.businessDescription || '',
      phoneNumber: initialValues.phoneNumber || '',
      websiteLink: initialValues.websiteLink || '',
      daysOfOperation: initialValues.daysOfOperation || [],
      photo: initialValues.photo || undefined,
    },
    onSubmit: (values, { setSubmitting }) =>
      onSubmit(
        {
          companyName: values.companyName,
          address: {
            street: values.street,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          },
          missionStatement: values.missionStatement,
          businessDescription: values.businessDescription,
          phoneNumber: values.phoneNumber,
          websiteLink: values.websiteLink,
          daysOfOperation: values.daysOfOperation,
          photo: values.photo,
        },
        setSubmitting,
      ),
  });

  return (
    <Paper elevation={5} style={{ padding: '1rem', borderRadius: '10px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={'1rem'}>
          <TextField
            fullWidth
            id="companyName"
            name="companyName"
            label="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={formik.touched.companyName && (formik.errors.companyName as string)}
          />
          <TextField
            fullWidth
            id="businessDescription"
            name="businessDescription"
            label="Business Description"
            value={formik.values.businessDescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.businessDescription && Boolean(formik.errors.businessDescription)}
            helperText={
              formik.touched.businessDescription && (formik.errors.businessDescription as string)
            }
          />
          <TextField
            fullWidth
            multiline
            inputProps={{
              style: {
                height: '110px',
              },
              autoComplete: 'new-password',
              form: { autoComplete: 'off' },
            }}
            id="missionStatement"
            name="missionStatement"
            label="Mission Statement"
            value={formik.values.missionStatement}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.missionStatement && Boolean(formik.errors.missionStatement)}
            helperText={
              formik.touched.missionStatement && (formik.errors.missionStatement as string)
            }
          />
          <Divider flexItem />
          <TextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && (formik.errors.phoneNumber as string)}
          />
          <TextField
            fullWidth
            id="websiteLink"
            name="websiteLink"
            label="Website Link"
            value={formik.values.websiteLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.websiteLink && Boolean(formik.errors.websiteLink)}
            helperText={formik.touched.websiteLink && (formik.errors.websiteLink as string)}
          />
          <Divider flexItem />
          <TextField
            fullWidth
            id="street"
            name="street"
            label="Street Address"
            value={formik.values.street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && (formik.errors.street as string)}
          />
          <Stack direction={'row'} spacing={2}>
            <TextField
              fullWidth
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && (formik.errors.city as string)}
            />
            <TextField
              fullWidth
              id="state"
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && (formik.errors.state as string)}
            />
            <TextField
              fullWidth
              id="zipcode"
              name="zipcode"
              label="Zipcode"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && (formik.errors.zipcode as string)}
            />
          </Stack>
          <Divider flexItem />
          <CloudinaryUploadWidget
            src={formik.values.photo}
            vendorId={id}
            onUpload={formik.setFieldValue}
          />
          <Divider flexItem />
          <WeekdayHoursPicker
            id="daysOfOperation"
            name="daysOfOperation"
            value={formik.values.daysOfOperation}
            onChange={formik.handleChange}
          />
          <Divider flexItem />
          <br />
          {showNotices && <PrivacyLabel />}
          <Stack direction={'row'} justifyContent={'space-between'}>
            {onCancel && (
              <Button color="primary" variant="contained" fullWidth onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
            >
              {submitLabel}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Paper>
  );
};
