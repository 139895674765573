import { useMutation, useQueryClient } from 'react-query';
import { createVendorViewById } from '../api/statistics';
import { useAuth0 } from '@auth0/auth0-react';

type createVendorViewOptions = {
  userId: string;
  vendorId: string;
};
export const useCreateVendorViewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ vendorId, userId }: createVendorViewOptions) => {
      return createVendorViewById(vendorId, userId);
    },
    onSuccess: (stat, { vendorId }) => {
      queryClient.invalidateQueries({ queryKey: ['getVendorStatsById', vendorId] });
    },
  });
};
