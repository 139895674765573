import React from 'react';
import { FlagOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  isToggled?: boolean;
  onClick: () => void;
  disabled?: boolean;
};
export const Flag: React.FC<Props> = ({ isToggled, onClick, disabled }) => {
  const theme = useTheme();
  const color = isToggled ? theme.palette.warning.main : theme.palette.error.main;
  const text = isToggled ? 'Unreport' : 'Report';
  const displayColor = disabled ? 'lightgrey' : color;
  return (
    <Icon tooltip={text} onClick={onClick}>
      <FlagOutlinedIcon style={{ color: displayColor }} fontSize="large" />
    </Icon>
  );
};
