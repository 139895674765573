import { useMutation, useQueryClient } from 'react-query';
import { blockUserById } from '../api/users';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, BLOCK_VENDOR_SUCCESS } from '../hooks/useToast';

type BlockUserOptions = {
  userId: string;
};
export const useBlockUserMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId }: BlockUserOptions) => {
      const token = await getAccessTokenSilently();
      return blockUserById(userId, token);
    },
    onSuccess: (user) => {
      if (user.subscription === 'vendor'){
        queryClient.invalidateQueries({ queryKey: ['getVendorById', user.sub] });
        queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      }
      showToast(BLOCK_VENDOR_SUCCESS, 'success');
    },
  });
};
