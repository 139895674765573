import { useQuery } from 'react-query';
import { getEventStatsById } from '../api/statistics';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetEventStatsByIdQuery = (eventId: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['getEventStatsById', eventId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getEventStatsById(eventId, token);
    },
    enabled: !!eventId,
  });
};
