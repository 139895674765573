import React from 'react';
import { Box, Paper, Stack } from '@mui/material';
import { useDialog } from '../../context/DialogProvider';
import { Text } from '../text';
import { TEvent } from '../../types/events';
import { TIME } from '../../formatting';
import { Close } from '../icons/Close';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

type CalendarModalProps = {
  date: string;
  events: TEvent[];
};

export const CalendarEventsDialogContents: React.FC<CalendarModalProps> = ({ date, events }) => {
  const { setOpen } = useDialog();
  const time = (eventTime: number) =>
    new Date(eventTime).toLocaleTimeString(undefined, TIME as any);
  const theme = useTheme();

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        setOpen(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setOpen]);

  return (
    <Paper elevation={5} style={{ padding: '1rem' }}>
      <Stack direction={'column'} spacing={'8px'} alignItems={'center'}>
        <Text fontWeight="700" fontSize="2rem">
          Events for {date}
        </Text>
        {events.map((event) => (
          <Box key={`dialog-${event._id}`}>
            <Link
              to={`/events/${event._id}`}
              onClick={() => setOpen(false)}
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <Text style={{ cursor: 'pointer' }}>
                <b>{time(event.dateTime.start)}</b> - {event.name}
                {event.canceled ? (
                  <span style={{ color: theme.palette.error.main }}> (Canceled)</span>
                ) : (
                  <></>
                )}
              </Text>
            </Link>
          </Box>
        ))}
        <Close onClick={() => setOpen(false)} />
      </Stack>
    </Paper>
  );
};
