export const getBaseUrl = (): string => {
  // During deployment, need to use /api
  // During development, need to use REACT_APP_API_URL (http://localhost:3001/api)
  return process.env['REACT_APP_API_URL'] || '/api';
};

type QueryParams = {
  [key: string]: string;
};
type CreateQueryParams = {
  [key: string]: unknown;
};
export const createQueryParams = (params: CreateQueryParams): QueryParams => {
  return Object.keys(params).reduce<QueryParams>((acc, key) => {
    const value = params[key];
    acc[key] = `${value}`;
    return acc;
  }, {});
};

export const createFetch = (url: string, method: string, body?: string, token?: string) => {
  const baseUrl = getBaseUrl();
  const headers = {
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };
  const options = {
    method,
    headers,
    body,
  };
  return fetch(`${baseUrl}${url}`, options);
};
