import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { unflagNotificationById } from '../api/notifications';
import { useToast, UNFLAG_NOTIFICATION_SUCCESS } from '../hooks/useToast';

type FlagNotificationOptions = {
  notificationId: string;
};
export const useUnflagNotificationMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ notificationId }: FlagNotificationOptions) => {
      const token = await getAccessTokenSilently();
      return unflagNotificationById(notificationId, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getNotifications'] });
      showToast(UNFLAG_NOTIFICATION_SUCCESS, 'success');
    },
  });
};
