import { useMutation, useQueryClient } from 'react-query';
import { createEvent } from '../api/events';
import { TEventForm } from '../types/events';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, CREATE_EVENT_SUCCESS } from '../hooks/useToast';
import { useNavigate } from 'react-router-dom';

export const useCreateEventMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['createEvent'],
    mutationFn: async (data: TEventForm) => {
      const token = await getAccessTokenSilently();
      return createEvent(data, token);
    },
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEvents'] });
      showToast(CREATE_EVENT_SUCCESS, 'success');
      navigate(`events/${event._id}`);
    },
  });
};
