import { useQuery } from 'react-query';
import { getAudits } from '../api/notifications';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetAuditsQuery = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['getAudits'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getAudits(token);
    },
  });
};
