import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  tooltip?: string;
  onClick?: () => void;
  children: any;
  disabled?: boolean;
  style?: any;
  tabIndex?: number;
};
const Icon: React.FC<Props> = ({ tooltip, disabled, style, children, onClick, tabIndex }) => {
  const isDisabled = disabled !== undefined ? disabled : onClick ? false : true;
  return (
    <Tooltip
      title={tooltip}
      style={style}
      tabIndex={tabIndex}
      onClick={(e) => {
        if (onClick !== undefined) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <IconButton disabled={isDisabled}>{children}</IconButton>
    </Tooltip>
  );
};

export default Icon;
