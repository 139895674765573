import { Statistic, EventStatistics, VendorStatistics } from '../types/statistic';
import { createFetch } from './createFetch';

export const createEventViewById = async (
  eventId?: string,
  vendorId?: string,
  userId?: string,
  token?: string,
) => {
  const response = await createFetch(
    `/statistics/event/${eventId}/views`,
    'POST',
    JSON.stringify({ vendorId: vendorId, userId: userId }),
    token,
  );
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as Statistic;
};
export const createVendorViewById = async (vendorId?: string, userId?: string, token?: string) => {
  const response = await createFetch(
    `/statistics/vendor/${vendorId}/views`,
    'POST',
    JSON.stringify({ userId: userId }),
    token,
  );
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as Statistic;
};
export const getEventStatsById = async (id?: string, token?: string) => {
  const response = await createFetch(`/statistics/event/${id}`, 'GET', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as EventStatistics;
};
export const getVendorStatsById = async (id?: string, token?: string) => {
  const response = await createFetch(`/statistics/vendor/${id}`, 'GET', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as VendorStatistics;
};
