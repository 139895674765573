import { Stack } from '@mui/material';
import DatePicker from '../DatePicker';
import { useEffect, useState } from 'react';
import { DateTimePicker } from '../DateTimePicker';
import { DailyRepeat } from './types';
import { DateTime } from '../../../types/misc';
import dayjs from 'dayjs';

type Props = {
  value?: DailyRepeat;
  onChange: (data: DateTime) => void;
};
export const DailyRepeatPicker = ({ value, onChange }: Props) => {
  const [start, setStart] = useState<number>(value?.start || Date.now());
  const [end, setEnd] = useState<number>(value?.end || Date.now() + 60 * 60 * 1000);
  const [finalDay, setFinalDay] = useState<number>(
    value?.finalDay || Date.now() + 25 * 60 * 60 * 1000,
  );

  useEffect(() => {
    if (!start || !end || !finalDay) {
      return;
    }
    onChange({ type: 'Repeat Daily', start, end, finalDay });
  }, [start, end, finalDay]);

  const handleStartChange = (timestamp: number | null) => {
    if (timestamp) {
      setStart(timestamp);
      if (end <= timestamp) handleEndChange(timestamp + 60 * 60 * 1000);
    }
  };
  const handleEndChange = (timestamp: number | null) => {
    if (timestamp) {
      setEnd(timestamp);
      const date = new Date(timestamp);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      //keeps time of finalDay synced with time of end
      if (finalDay <= timestamp) {
        handleFinalDayChange(
          dayjs(timestamp + 25 * 60 * 60 * 1000)
            .toDate()
            .setHours(hours, minutes, 0, 0),
        );
      } else {
        handleFinalDayChange(dayjs(finalDay).toDate().setHours(hours, minutes, 0, 0));
      }
    }
  };
  const handleFinalDayChange = (timestamp: number | null) => {
    if (timestamp) setFinalDay(timestamp);
  };

  return (
    <Stack spacing={'1rem'}>
      <DateTimePicker label="Event Start" value={start} onChange={handleStartChange} />
      <DateTimePicker label="Event End" value={end} onChange={handleEndChange} />
      <DatePicker
        targetTime={end}
        label="Final Repeat Date"
        value={finalDay}
        onChange={(e) => handleFinalDayChange(e.target.value)}
      />
    </Stack>
  );
};
