import { useMutation } from 'react-query';
import { dislikeEvent } from '../api/standards';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, REMOVE_FROM_LIKES_SUCCESS } from '../hooks/useToast';

type DislikeEventOptions = {
  userId: string;
  eventId: string;
};
export const useDislikeEventMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, eventId }: DislikeEventOptions) => {
      const token = await getAccessTokenSilently();
      return dislikeEvent(userId, eventId, token);
    },
    onSuccess: () => {
      showToast(REMOVE_FROM_LIKES_SUCCESS, 'success');
    },
  });
};
