import { Stack } from '@mui/material';
import { Page } from '../../../components/layout/page';
import { VendorForm } from '../../../components/forms/VendorForm';
import { ErrorText } from '../../../components/text/ErrorText';
import { useCreateVendor } from './createVendorHooks';
import { DEFAULT_VENDOR_FORM } from '../../../constants/vendor-form';

export const CreateVendorPage = () => {
  const { createVendor, error } = useCreateVendor();

  return (
    <Page header="CREATE VENDOR" maxWidth="450px">
      <Stack spacing={'1rem'}>
        <VendorForm
          submitLabel={'Create Vendor'}
          initialValues={DEFAULT_VENDOR_FORM}
          onSubmit={createVendor}
        />
        <br />
        {error && <ErrorText>{error}</ErrorText>}
      </Stack>
    </Page>
  );
};
