import { useMutation, useQueryClient } from 'react-query';
import { createEventViewById } from '../api/statistics';
import { useAuth0 } from '@auth0/auth0-react';

type createEventViewOptions = {
  userId: string;
  vendorId: string;
  eventId: string;
};
export const useCreateEventViewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ eventId, vendorId, userId }: createEventViewOptions) => {
      return createEventViewById(eventId, vendorId, userId);
    },
    onSuccess: (stat, { eventId, vendorId }) => {
      queryClient.invalidateQueries({ queryKey: ['getEventStatsById', eventId] });
      queryClient.invalidateQueries({ queryKey: ['getVendorStatsById', vendorId] });
    },
  });
};
