import { Stack } from '@mui/material';
import { Like } from './icons/Like';
import { Stats } from './icons/Stats';
import { Calendar } from './icons/Calendar';
import { Edit } from './icons/Edit';
import { Share } from './icons/Share';
import { Block } from './icons/Block';
import { Cancel } from './icons/Cancel';
import { Flag } from './icons/Flag';
import { Notification } from './icons/Notification';

// TODO - API for blocked, needs to return the new event.
// Set the event in state (or re-fetch using useQuery)

type ActionButtonsProps = {
  isLoadingLike?: boolean;
  isLoadingCalendar?: boolean;
  isLoadingBlock?: boolean;
  isLoadingCancel?: boolean;
  isLoadingFlag?: boolean;
  isLoadingFollowVendor?: boolean;
  isBlocked?: boolean;
  isCanceled?: boolean;
  isFlagged?: boolean;
  isOnCalendar?: boolean;
  isLiked?: boolean;
  isFollowing?: boolean;
  onLikeClick?: () => void;
  onFollowClick?: () => void;
  onCalendarClick?: () => void;
  onNotificationClick?: () => void;
  onEditClick?: () => void;
  onStatisticsClick?: () => void;
  onCancelClick?: () => void;
  onFlagClick?: () => void;
  onShareClick?: () => void;
  onBlockClick?: () => void;
};
export const ActionButtons: React.FC<ActionButtonsProps> = (props) => {
  const {
    isLoadingLike,
    isLoadingBlock,
    isLoadingCalendar,
    isLoadingCancel,
    isLoadingFlag,
    isLoadingFollowVendor,
    isBlocked,
    isCanceled,
    isFlagged,
    isOnCalendar,
    isLiked,
    isFollowing,
    onLikeClick,
    onFollowClick,
    onCalendarClick,
    onEditClick,
    onStatisticsClick,
    onNotificationClick,
    onCancelClick,
    onFlagClick,
    onShareClick,
    onBlockClick,
  } = props;

  const newNotificationCount = 0;

  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      height={'100%'}
    >
      {onCalendarClick && (
        <Calendar disabled={isLoadingCalendar} isToggled={isOnCalendar} onClick={onCalendarClick} />
      )}
      {onLikeClick && (
        <Like vendor={false} disabled={isLoadingLike} isToggled={isLiked} onClick={onLikeClick} />
      )}
      {onFollowClick && (
        <Like
          vendor={true}
          disabled={isLoadingFollowVendor}
          isToggled={isFollowing}
          onClick={onFollowClick}
        />
      )}
      {onEditClick && <Edit onClick={onEditClick} />}
      {onStatisticsClick && <Stats onClick={onStatisticsClick} />}
      {onNotificationClick && (
        <Notification onClick={onNotificationClick} value={newNotificationCount} />
      )}
      {onShareClick && <Share onClick={onShareClick} />}
      {onCancelClick && (
        <Cancel disabled={isLoadingCancel} isToggled={isCanceled} onClick={onCancelClick} />
      )}
      {onFlagClick && <Flag disabled={isLoadingFlag} isToggled={isFlagged} onClick={onFlagClick} />}
      {onBlockClick && (
        <Block disabled={isLoadingBlock} isToggled={isBlocked} onClick={onBlockClick} />
      )}
    </Stack>
  );
};
