import { TEventForm } from '../types/events';

export const DEFAULT_EVENT_FORM: TEventForm = {
  name: '',
  vendorId: '',
  vendorName: '',
  dateTime: {
    type: 'Does not repeat',
    start: Date.now(),
    end: Date.now() + 60 * 60 * 1000,
  },
  address: {
    venue: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
  },
  needTickets: false,
  needRegister: false,
  price: {
    type: 'Single',
    min: 0,
  },
  keywords: '',
  featuredLinks: [],
  details: '',
  linkToRegister: '',
  linkToTickets: '',
  photo: undefined,
};
