import { useMutation, useQueryClient } from 'react-query';
import { cancelEventById } from '../api/events';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, CANCEL_EVENT_SUCCESS } from '../hooks/useToast';

type CancelEventOptions = {
  eventId: string;
};
export const useCancelEventMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ eventId }: CancelEventOptions) => {
      const token = await getAccessTokenSilently();
      return cancelEventById(eventId, token);
    },
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', event._id] });
      queryClient.invalidateQueries({ queryKey: ['getEvents'] });
      showToast(CANCEL_EVENT_SUCCESS, 'success');
    },
  });
};
