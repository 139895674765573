import { useAuth } from '../auth/useAuth';
import { BlockedPage } from './BlockedPage';
import { VerifyEmailPage } from './VerifyEmailPage';
import { SubscriptionPage } from './auth/SubscriptionPage';
import { StandardProfilePage } from './standard/StandardProfilePage';
import { VendorProfilePage } from './vendor/ProfilePage/VendorProfilePage';

export const ProfilePage = () => {
  const { user, isBlocked, isEmailVerified } = useAuth();

  if (isBlocked()) return <BlockedPage />;
  if (!isEmailVerified()) return <VerifyEmailPage />;

  if (!user?.subscription) return <SubscriptionPage />;
  if (user.subscription === 'unsubscribed') return <SubscriptionPage />;

  if (user.subscription === 'standard') return <StandardProfilePage />;
  if (user.subscription === 'vendor') return <VendorProfilePage />;

  throw new Error('Unable to determine profile page');
};
