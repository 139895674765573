import { CustomEvent } from '../WeekdayHoursPicker';
import { DailyRepeatPicker } from './DailyRepeatPicker';
import { StartEndPicker } from './StartEndPicker';
import { WeeklyRepeatPicker } from './WeeklyRepeatPicker';
import { DateTimeRepeat } from './types';

type DateTimeRepeatPickerProps = {
  id: string;
  name: string;
  value: DateTimeRepeat;
  onChange: (e: CustomEvent) => void;
};
export const DateTimeRepeatPicker = (props: DateTimeRepeatPickerProps) => {
  const { id, name, value, onChange } = props;

  switch (value.type) {
    case 'Does not repeat':
      return (
        <StartEndPicker
          value={value}
          onChange={(value) => {
            onChange({
              type: 'change',
              target: {
                id,
                name,
                value,
              },
            });
          }}
        />
      );
    case 'Repeat Daily':
      return (
        <DailyRepeatPicker
          value={value}
          onChange={(value) => {
            onChange({
              type: 'change',
              target: {
                id,
                name,
                value,
              },
            });
          }}
        />
      );
    case 'Repeat Weekly':
      return (
        <WeeklyRepeatPicker
          value={value}
          onChange={(value) => {
            onChange({
              type: 'change',
              target: {
                id,
                name,
                value,
              },
            });
          }}
        />
      );
    default:
      return null;
  }
};
