import { Address, Price } from '../types/misc';

export const formatPhoneNumber = (phoneNumber: string): string => {
  switch (phoneNumber.length) {
    case 7: // 8283080
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    case 10: // 7608283080
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    case 11: // 17608283080
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
        1,
        4,
      )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
    default:
      return phoneNumber;
  }
};

export const formatPrice = (price: Price): string => {
  const { type } = price;
  switch (type) {
    case 'Free':
      return 'Free';
    case 'Single': {
      const { min } = price;
      return min === 0 ? 'Free' : `$${min}`;
    }
    case 'Range': {
      const { min, max } = price;
      return `$${min} to $${max}`;
    }
    default:
      return 'Free';
  }
};

export const formatAddress = (address: Address) => {
  const parts: string[] = [];

  if (address.street) {
    parts.push(address.street);
  }

  if (address.city) {
    parts.push(address.city);
  }

  if (address.state) {
    parts.push(address.state);
  }

  return parts.join(', ');
};

export const formatWebsiteLink = (website: string) => {
  const regex = /^(?:https?:\/\/)?([^\/]+)(\/.*)?$/;
  const match = website.match(regex);
  return match ? match[1] : website;
};

export const ensureHttps = (url: string) => {
  return url.startsWith('https://') ? url : `https://${url}`;
};
