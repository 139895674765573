import React from 'react';
import { v4 as uuidv4 } from 'uuid';

type LocalData = {
  userId: string;
  savedEvents: string[];
  followedVendors: string[];
};

const DEFAULT_LOCAL_DATA: LocalData = {
  userId: uuidv4(),
  savedEvents: [],
  followedVendors: [],
};

type PublicUserContextData = {
  userId: string;
  savedEvents: string[];
  followedVendors: string[];
  publicIsLikedEvent: (eventId: string) => boolean;
  publicLikeEvent: (eventId: string) => void;
  publicUnlikeEvent: (eventId: string) => void;
  publicToggleLikeEvent: (eventId: string) => void;
  publicIsFollowingVendor: (vendorId: string) => boolean;
  publicFollowVendor: (vendorId: string) => void;
  publicUnfollowVendor: (vendorId: string) => void;
  publicToggleFollowVendor: (vendorId: string) => void;
};

const PublicUserContext = React.createContext<PublicUserContextData | null>(null);

export const PublicUserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // const { fetchEventLiked } = useEventsAPI();
  const [userId, setUserId] = React.useState<string>('');
  const [savedEvents, setSavedEvents] = React.useState<string[]>([]);
  const [followedVendors, setFollowedVendors] = React.useState<string[]>([]);

  React.useEffect(() => {
    const savedEvents = getSavedEvents();
    const localUserId = getLocalUserId();
    const followedVendors = getFollowedVendors();
    setSavedEvents(savedEvents || []);
    setUserId(localUserId || uuidv4());
    setFollowedVendors(followedVendors || []);
  }, []);

  const getSavedEvents = () => {
    return loadLocalData().savedEvents;
  };

  const getLocalUserId = () => {
    return loadLocalData().userId;
  };

  const getFollowedVendors = () => {
    return loadLocalData().followedVendors;
  };

  const loadLocalData = () => {
    const localString = localStorage.getItem('SLOM_DATA');
    if (localString) {
      return JSON.parse(localString) as LocalData;
    }
    const defaultData = DEFAULT_LOCAL_DATA;
    saveLocalData(defaultData);
    return defaultData;
  };

  const saveLocalData = (localData: LocalData) => {
    localStorage.setItem('SLOM_DATA', JSON.stringify(localData));
  };

  const publicLikeEvent = (eventId: string) => {
    if (savedEvents.includes(eventId)) return;
    const events = [...savedEvents, eventId];
    const newLocalData = { userId: userId, savedEvents: events, followedVendors };
    setSavedEvents(events);
    saveLocalData(newLocalData);
    // fetchEventLiked(eventId);
  };

  const publicUnlikeEvent = (eventId: string) => {
    const events = savedEvents.filter((item) => {
      return item !== eventId;
    });
    const newLocalData = { userId: userId, savedEvents: events, followedVendors };
    setSavedEvents(events);
    saveLocalData(newLocalData);
  };

  const publicIsLikedEvent = (eventId: string) => {
    if (!savedEvents) return false;
    return savedEvents.includes(eventId);
  };

  const publicToggleLikeEvent = (eventId: string) => {
    if (publicIsLikedEvent(eventId)) {
      publicUnlikeEvent(eventId);
    } else {
      publicLikeEvent(eventId);
    }
  };

  const publicFollowVendor = (vendorId: string) => {
    if (followedVendors.length > 0 && followedVendors.includes(vendorId)) return;
    const vendors = [...followedVendors, vendorId];
    const newLocalData = { ...loadLocalData(), followedVendors: vendors };
    setFollowedVendors(vendors);
    saveLocalData(newLocalData);
  };

  const publicUnfollowVendor = (vendorId: string) => {
    const vendors = followedVendors.filter((item) => item !== vendorId);
    const newLocalData = { ...loadLocalData(), followedVendors: vendors };
    setFollowedVendors(vendors);
    saveLocalData(newLocalData);
  };

  const publicIsFollowingVendor = (vendorId: string) => {
    if (!followedVendors) return false;
    return followedVendors.includes(vendorId);
  };

  const publicToggleFollowVendor = (vendorId: string) => {
    if (publicIsFollowingVendor(vendorId)) {
      publicUnfollowVendor(vendorId);
    } else {
      publicFollowVendor(vendorId);
    }
  };

  const value = {
    userId,
    savedEvents,
    followedVendors,
    publicIsLikedEvent,
    publicLikeEvent,
    publicUnlikeEvent,
    publicToggleLikeEvent,
    publicIsFollowingVendor,
    publicFollowVendor,
    publicUnfollowVendor,
    publicToggleFollowVendor,
  };

  return <PublicUserContext.Provider value={value}>{children}</PublicUserContext.Provider>;
};

export const usePublicUser = () => {
  const context = React.useContext(PublicUserContext);
  if (!context) throw new Error('Must have usePublicUser within a PublicUserProvider');
  return context;
};
