import { createFetch } from './createFetch';

export type PhotoSignature = {
  signature: string;
};

export const getPhotoSignature = async (paramsToSign: string, token?: string) => {
  const response = await createFetch('/photos/sign', 'POST', paramsToSign, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as PhotoSignature;
};
