import { useState } from 'react';
import { Container, Divider, Grid, Paper, Stack } from '@mui/material';
import { Page } from '../../components/layout/page';
import { Subscription } from '../../api/users';
import { VendorForm } from '../../components/forms/VendorForm';
import { DEFAULT_VENDOR_FORM } from '../../constants/vendor-form';
import { useSubscribe } from './subscriptionPageHooks';
import { StandardForm } from '../../components/forms/StandardForm';
import { TVendorForm } from '../../types/vendors';
import { TStandardForm } from '../../types/standards';
import { DEFAULT_STANDARD_FORM } from '../../constants/standard-form';
import { Text } from '../../components/text';
import { paige } from '../../constants/employees';
import { ErrorText } from '../../components/text/ErrorText';
import { styled } from 'styled-components';

type PaperButtonProps = {
  selected: boolean;
};
const PaperButton = styled(Paper)<PaperButtonProps>`
  padding: 1rem;
  box-shadow: 0 0 5px 2px grey;
  opacity: ${({ selected }) => (selected ? '1' : '0.5')} !important;
  cursor: ${({ selected }) => (selected ? 'not-allowed' : 'pointer')} !important;
  pointer-events: ${({ selected }) => (selected ? 'none' : 'all')} !important;
  background-color: ${({ selected }) => (selected ? 'white' : 'rgba(0, 0, 0, 0.12)')} !important;

  & * {
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 0 5px 2px black;
  }
`;

export const SubscriptionPage = () => {
  const { subscribe, error } = useSubscribe();
  const [subscription, setSubscription] = useState<Subscription>('vendor');

  const handleRegister = async (
    form: TVendorForm | TStandardForm,
    setSubmitting: (submitting: boolean) => void,
  ) => {
    await subscribe(subscription, form, setSubmitting);
  };

  return (
    <Page header="SUBSCRIPTION" maxWidth="900px">
      <Stack spacing={'1rem'} alignItems={'center'}>
        <Grid container gap={'1rem'} justifyContent={'center'}>
          {/* <Grid item xs={12} sm={5} onClick={() => setSubscription('standard')}>
            <PaperButton selected={subscription === 'standard'} elevation={3}>
              <Stack>
                <Text textAlign="center" fontWeight="bold" fontSize="28px">
                  Standard Account
                </Text>
                <Text fancy textAlign="center" fontSize="30px">
                  {subscription === 'standard' ? '(Selected)' : <br />}
                </Text>
              </Stack>
            </PaperButton>
          </Grid> */}
          <Grid item xs={12} sm={5} onClick={() => setSubscription('vendor')}>
            <PaperButton selected={subscription === 'vendor'} elevation={3}>
              <Stack>
                <Text textAlign="center" fontWeight="bold" fontSize="28px">
                  Vendor Account
                </Text>
                <Text fancy textAlign="center" fontSize="30px">
                  {subscription === 'vendor' ? '(Selected)' : <br />}
                </Text>
              </Stack>
            </PaperButton>
          </Grid>
        </Grid>
        <Container style={{ maxWidth: '450px', padding: '0' }}>
          {subscription === 'vendor' && (
            <>
              <Divider />
              <br />
              <Text textAlign="center">
                * If you currently have an account which we are administering for you, we can hand
                this account over by contacting Paige at {paige.email} after registering.
              </Text>
              <br />
            </>
          )}
          <Divider />
          <br />
          {subscription === 'vendor' && (
            <VendorForm
              submitLabel={'Complete'}
              initialValues={DEFAULT_VENDOR_FORM}
              onSubmit={handleRegister}
              showNotices={true}
            />
          )}
          {subscription === 'standard' && (
            <StandardForm
              submitLabel={'Complete'}
              initialValues={DEFAULT_STANDARD_FORM}
              onSubmit={handleRegister}
              showNotices={true}
            />
          )}
        </Container>
        <br />
        <Text textAlign="center">* After registering, you will be signed out.</Text>
      </Stack>
      <br />
      {error && <ErrorText>{error}</ErrorText>}
    </Page>
  );
};
