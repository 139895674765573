import React from 'react';
import { CloudinaryImage } from '../CloudinaryImage';
import { Photo } from '../../types/misc';
import { Button, Stack } from '@mui/material';
import { useDeletePhotoMutation } from '../../mutations/useDeletePhotoMutation';
import { ErrorText } from '../text/ErrorText';
import { useGetPhotoSignatureMutation } from '../../mutations/useGetPhotoSignatureMutation';
import { useToast } from '../../hooks/useToast';

declare global {
  interface Window {
    cloudinary?: any;
  }
}

type Props = {
  src?: Photo;
  eventId?: string;
  vendorId?: string;
  onUpload: (key: string, data: Photo) => void;
};

export const CloudinaryUploadWidget = ({ src, eventId, vendorId, onUpload }: Props) => {
  const [publicId, setPublicId] = React.useState<string>(src?.url || '');
  const [prevPublicId, setPrevPublicId] = React.useState<string>('');
  const [cropPos, setCropPos] = React.useState<number[]>(src?.crop || []);
  const { mutateAsync } = useDeletePhotoMutation();
  const [error, setError] = React.useState<string>();
  const { mutateAsync: photoSignature } = useGetPhotoSignatureMutation();
  const { showToast } = useToast();

  const deletePrevUpload = async (prevId: string) => {
    try {
      if (eventId) {
        await mutateAsync({ publicId: prevId, eventId });
      } else if (vendorId) {
        await mutateAsync({ publicId: prevId, vendorId });
      } else {
        await mutateAsync({ publicId: prevId });
      }
    } catch (error: any) {
      const message = error.message;
      message && showToast(message, 'error');
    }
  };

  const generateSignature: any = async (callback: any, paramsToSign: any) => {
    try {
      const data = await photoSignature(JSON.stringify(paramsToSign));
      callback(data.signature);
    } catch (error: any) {
      const message = error.message;
      message && showToast(message, 'error');
    }
  };

  const uwConfig = {
    cloudName: `${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}`,
    api_key: `${process.env.REACT_APP_CLOUDINARY_API_KEY}`,
    uploadSignature: generateSignature,
    uploadPreset: 'slom-photo',
    cropping: true,
    croppingAspectRatio: 1.0,
    resourceType: 'image',
    maxImageWidth: 800,
    maxImageHeight: 800,
    croppingValidateDimensions: true,
    croppingShowDimensions: true,
    multiple: false,
    maxImageFileSize: 2000000,
  };

  const initializeWidget = (e: Event) => {
    if (!window.cloudinary) {
      const message = 'Could not initialize cloudinary';
      setError(message);
    }
  };

  React.useEffect(() => {
    if (!window.cloudinary) {
      const loadScript = document.createElement('script');
      loadScript.setAttribute('async', 'true');
      loadScript.setAttribute('id', 'uw');
      loadScript.setAttribute('src', 'https://upload-widget.cloudinary.com/global/all.js');
      loadScript.addEventListener('load', initializeWidget);
      document.body.appendChild(loadScript);
    }
  }, []);

  React.useEffect(() => {
    if (prevPublicId !== '' && publicId !== prevPublicId) deletePrevUpload(prevPublicId);
    return () => {
      if (publicId !== '') setPrevPublicId(publicId);
    };
  }, [publicId, prevPublicId]);

  const openUploadWidget = () => {
    if (!window.cloudinary) {
      const message = 'Could not open upload widget';
      setError(message);
      return;
    }

    window.cloudinary.openUploadWidget(uwConfig, (error: any, result: any) => {
      if (result && result.event === 'success') {
        const uploadInfo = result.info;

        // Using a crop
        if (
          uploadInfo.coordinates &&
          uploadInfo.coordinates.custom &&
          uploadInfo.coordinates.custom.length
        ) {
          setPublicId(uploadInfo.public_id);
          setCropPos(uploadInfo.coordinates.custom[0]);
          onUpload('photo', {
            url: uploadInfo.public_id,
            crop: uploadInfo.coordinates.custom[0],
          });
        }

        // Not using a crop
        else {
          setPublicId(uploadInfo.public_id);
          setCropPos([]);
          onUpload('photo', { url: uploadInfo.public_id, crop: [] });
        }

        error && setError(undefined);
      }
    });
  };

  return (
    <Stack spacing={'1rem'}>
      <Button
        type="button"
        color="primary"
        variant="contained"
        aria-haspopup="dialog"
        aria-label={src ? 'Replace Image' : 'Upload Image'}
        fullWidth
        id="upload_widget"
        className="cloudinary-button"
        onClick={openUploadWidget}
      >
        {src ? 'Replace Image' : 'Upload Image'}
      </Button>
      {error && <ErrorText>{error}</ErrorText>}
      {publicId && <CloudinaryImage src={{ url: publicId, crop: cropPos }} altText='Newest Upload' />}
    </Stack>
  );
};
