import { Grid, GridProps } from '@mui/material';
import DEFAULT_IMAGE from '../assets/Default_Image.png';
import { Photo } from '../types/misc';
import { CloudinaryImage } from './CloudinaryImage';

type DisplayProps = {
  alt: string;
  src?: string | Photo;
  size?: string;
};

type ImageDisplayProps = DisplayProps & GridProps;

export const ImageDisplay: React.FC<ImageDisplayProps> = (props) => {
  const size = props.size
    ? { width: props.size, height: props.size }
    : { width: '200px', height: '200px' };

  const imageStyles = {
    objectFit: 'cover',
    borderRadius: '15px',
    height: '100%',
    width: 'auto',
    maxWidth: '100%',
  } as const;

  const containerStyles = {
    height: size.height,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '1rem',
    boxSizing: 'border-box' as const,
  };

  const imageContainerStyles = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '15px',
  };

  return (
    <Grid component="figure" container item style={containerStyles}>
      <div style={imageContainerStyles}>
        {typeof props.src === 'object' && props.src.url ? (
          <CloudinaryImage src={props.src} altText={props.alt} imageStyles={imageStyles} />
        ) : (
          <img src={DEFAULT_IMAGE} alt={props.alt} style={imageStyles} />
        )}
      </div>
    </Grid>
  );
};
