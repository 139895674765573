import { Page } from '../../components/layout/page';
import { useAuth } from '../../auth/useAuth';
import { TStandard } from '../../types/standards';
import { StandardForm } from '../../components/forms/StandardForm';
import { useUpdateUser } from '../vendor/ProfilePage/profilePageHooks';
import { LoadingPage } from '../LoadingPage';
import { ErrorText } from '../../components/text/ErrorText';

export const StandardProfilePage = () => {
  const { user } = useAuth();
  const { updateUser, error } = useUpdateUser();
  const standard = user as TStandard;

  if (!user) return <LoadingPage />;

  return (
    <Page header="PROFILE SETTINGS" maxWidth="450px">
      <StandardForm
        submitLabel={'Update Profile'}
        initialValues={standard}
        onSubmit={(form, setSubmitting) => updateUser(standard, form, setSubmitting)}
      />
      <br />
      {error && <ErrorText>{error}</ErrorText>}
    </Page>
  );
};
