import { useMutation, useQueryClient } from 'react-query';
import { unblockEventById } from '../api/events';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, UNBLOCK_EVENT_SUCCESS } from '../hooks/useToast';

type UnblockEventOptions = {
  eventId: string;
};
export const useUnblockEventMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ eventId }: UnblockEventOptions) => {
      const token = await getAccessTokenSilently();
      return unblockEventById(eventId, token);
    },
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', event._id] });
      queryClient.invalidateQueries({ queryKey: ['getEvents'] });
      showToast(UNBLOCK_EVENT_SUCCESS, 'success');
    },
  });
};
