import React from 'react';
import { useSubscribeMutation } from '../../mutations/useSubscribeMutation';
import { useToast } from '../../hooks/useToast';
import { TVendorForm } from '../../types/vendors';
import { useAuth } from '../../auth/useAuth';
import { Subscription } from '../../api/users';
import { TStandardForm } from '../../types/standards';

// TODO - This is an example of how the network calls should be

export const useSubscribe = () => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const { mutateAsync: subscribeMutation } = useSubscribeMutation();
  const [error, setError] = React.useState<string>();

  const subscribe = async (
    subscription: Subscription,
    data: TVendorForm | TStandardForm,
    setIsSubmitting: (bool: boolean) => void,
  ) => {
    if (!user) return;
    setIsSubmitting(true);

    try {
      // Could revoke token here
      // Could return new user here
      // Force a refresh
      await subscribeMutation({ id: user.sub, subscription, formData: data });
    } catch (err: any) {
      const { message } = err;
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return { subscribe, error };
};
