import { Button, Divider, Paper, Rating, Stack, TextField } from '@mui/material';
import { useToast } from '../../hooks/useToast';
import { Text } from '../text';
import React from 'react';
import { useDialog } from '../../context/DialogProvider';
import { useCreateFeedbackMutation } from '../../mutations/useCreateFeedbackMutation';
import { useAuth } from '../../auth/useAuth';

export const FeedbackDialog = () => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const { setOpen } = useDialog();
  const { mutateAsync } = useCreateFeedbackMutation();
  const [rating, setRating] = React.useState<number>(3);
  const [comments, setComments] = React.useState<string>('');

  const submit = async () => {
    try {
      await mutateAsync({
        rating,
        comments,
        subscription: user?.subscription,
        userId: user?.sub,
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <Paper elevation={5} style={{ width: '300px', padding: '1rem' }}>
      <Stack spacing={'1rem'}>
        <Text fontWeight="bold" fontSize="24px">
          Feedback to <br /> Social Life of Mine
        </Text>
        <Rating
          size="large"
          value={rating}
          onChange={(event, newValue) => {
            newValue && setRating(newValue);
          }}
        />
        <Divider flexItem />
        <TextField
          rows={5}
          fullWidth
          multiline
          placeholder="Comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <Button fullWidth variant="contained" onClick={submit}>
          Submit
        </Button>
      </Stack>
    </Paper>
  );
};
