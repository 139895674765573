import React from 'react';

type ScrollState = {
  pageX: number;
  pageY: number;
};

type PageState = {
  route: string;
  scroll: ScrollState;
  expanded: string[];
};

export const useSessionStorage = () => {
  const loadSessionData = () => {
    const sessionString = sessionStorage.getItem('SLOM_PAGESTATE');
    if (sessionString) {
      return JSON.parse(sessionString) as PageState;
    }
    const defaultData = DEFAULT_SESSION_DATA;
    saveSessionData(defaultData);
    return defaultData;
  };

  const saveSessionData = (sessionData: PageState) => {
    sessionStorage.setItem('SLOM_PAGESTATE', JSON.stringify(sessionData));
  };

  const DEFAULT_SESSION_DATA: PageState = {
    route: '',
    scroll: { pageX: 0, pageY: 0 },
    expanded: [],
  };

  const useScroll = () => {
    React.useEffect(() => {
      window.addEventListener('scroll', handleScroll());
      return () => {
        window.removeEventListener('scroll', handleScroll());
      };
    }, []);
    return getSavedScroll();
  };

  const useExpanded = () => {
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const expandAccordion = (key: string) => {
      const { route, scroll, expanded: savedExpanded } = loadSessionData();
      if (savedExpanded.includes(key)) return;
      const newExpanded = [...savedExpanded, key];
      const newState = {
        route,
        scroll,
        expanded: newExpanded,
      };
      saveSessionData(newState);
      setExpanded(newExpanded);
    };

    const closeAccordion = (key: string) => {
      const { route, scroll, expanded: savedExpanded } = loadSessionData();
      if (!savedExpanded.includes(key)) return;
      const newExpanded = savedExpanded.filter((str: string) => str !== key);
      const newState = {
        route,
        scroll,
        expanded: newExpanded,
      };
      saveSessionData(newState);
      setExpanded(newExpanded);
    };

    const handleExpanded = (key: string) => {
      expanded.includes(key) ? closeAccordion(key) : expandAccordion(key);
    };

    const isExpanded = (key: string) => getSavedExpanded().includes(key);

    return { isExpanded, handleExpanded };
  };

  const getSavedScroll = () => {
    return loadSessionData().scroll;
  };

  const getSavedExpanded = () => {
    return loadSessionData().expanded;
  };

  const updateRoute = (location: any) => {
    const newState = { ...DEFAULT_SESSION_DATA, route: `${location.pathname}${location.search}` };
    saveSessionData(newState);
  };

  const handleScroll = () => {
    let timerId: NodeJS.Timeout | undefined;
    return () => {
      timerId && clearTimeout(timerId);
      timerId = setTimeout(() => {
        const { route, expanded } = loadSessionData();
        const newState = {
          route,
          expanded,
          scroll: { pageX: window.scrollX, pageY: window.scrollY },
        };
        saveSessionData(newState);
      }, 100);
    };
  };

  return {
    useExpanded,
    updateRoute,
    useScroll,
  };
};
