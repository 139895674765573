import { Divider, Grid, Paper, Stack } from '@mui/material';
import { Text } from '../../components/text';
import { TIME } from '../../formatting';
import { Page } from '../../components/layout/page';
import { useGetEventsQuery } from '../../queries/useGetEventsQuery';
import { LoadingPage } from '../LoadingPage';
import DEFAULT_IMAGE from '../../assets/Default_Image.png';
import { CloudinaryImage } from '../../components/CloudinaryImage';
import { useNavigate } from 'react-router-dom';
import { getNextOcurrence } from '../../utils/days';
import { DAYS_OF_WEEK, DAYS } from '../../types/misc';
import { styled } from 'styled-components';
import { formatAddress } from '../../utils/formatting';

const IMAGE_SIZE = '250px';

const PaperButton = styled(Paper)`
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 3px 1px black;
  }
`;

export const HomePage = () => {
  const navigate = useNavigate();
  const { isLoading, data: events, error } = useGetEventsQuery();
  if (isLoading || !events) return <LoadingPage />;

  const today = new Date();
  const oneWeekFromToday = new Date(today).setDate(today.getDate() + 7);

  return (
    <Page header="Upcoming Events" maxWidth="1200px">
      <Text textAlign="center">* All times are local to the event location</Text>
      <br />
      <br />
      <Grid container spacing={'2rem'} justifyContent={'center'}>
        {events
          .filter((event) => {
            return getNextOcurrence(new Date(), event.dateTime) < oneWeekFromToday;
          })
          .sort(
            (a, b) =>
              getNextOcurrence(new Date(), a.dateTime) - getNextOcurrence(new Date(), b.dateTime),
          )
          .map((event) => {
            const neededAddressValues: Record<string, string | undefined> = {
              street: event.address.street,
              city: event.address.city,
              state: event.address.state,
            };

            const shouldDisplayAddress =
              Object.keys(neededAddressValues).filter((key) => neededAddressValues[key]).length > 0;

            return (
              <Grid key={event._id} item xs={12} sm={6} md={4}>
                <PaperButton
                  elevation={2}
                  style={{ padding: '1rem' }}
                  tabIndex={0}
                  onKeyUp={(e) => e.key === 'Enter' && navigate(`/events/${event._id}`)}
                  onClick={() => navigate(`/events/${event._id}`)}
                >
                  <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={'1rem'}
                    marginTop={'1rem'}
                  >
                    {typeof event.photo === 'object' && event.photo.url ? (
                      <CloudinaryImage
                        src={event.photo}
                        altText={event.name}
                        imageStyles={{
                          objectFit: 'cover',
                          borderRadius: '5px',
                          width: IMAGE_SIZE,
                          height: IMAGE_SIZE,
                        }}
                      />
                    ) : (
                      <img
                        src={DEFAULT_IMAGE}
                        alt={event.name}
                        width={IMAGE_SIZE}
                        height={IMAGE_SIZE}
                        style={{
                          objectFit: 'cover',
                          borderRadius: '5px',
                        }}
                      />
                    )}
                    <Stack direction={'row'} spacing={'1rem'} justifyContent={'center'}>
                      <Text fontSize="24px" fontWeight="bolder">
                        {
                          DAYS[
                            DAYS_OF_WEEK[
                              new Date(getNextOcurrence(new Date(), event.dateTime)).getDay()
                            ]
                          ]
                        }
                      </Text>
                      <Divider flexItem style={{ marginRight: '1rem' }} orientation="vertical" />
                      <Text fontSize="24px" fontWeight="bolder">
                        {new Date(event.dateTime.start).toLocaleTimeString(undefined, TIME as any)}
                      </Text>
                    </Stack>
                    <Stack>
                      {event.name && (
                        <Text fontWeight="bold" textAlign="center">
                          {event.name.toUpperCase()}
                        </Text>
                      )}
                    </Stack>
                    <Stack direction={'row'} spacing={'1rem'} justifyContent={'center'}>
                      {event.vendorName && (
                        <Text textAlign="center" fontSize="12px">
                          {event.vendorName.toUpperCase()}
                        </Text>
                      )}
                      <Divider flexItem style={{ marginRight: '1rem' }} orientation="vertical" />
                      {shouldDisplayAddress && (
                        <Text textAlign="center" fontSize="12px">
                          {formatAddress(neededAddressValues)}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                </PaperButton>
              </Grid>
            );
          })}
      </Grid>
    </Page>
  );
};
