import { Text } from '../../components/text';
import { Box, Button, Divider, Grid, Stack, useTheme } from '@mui/material';
import { Page } from '../../components/layout/page';
import { Paper } from '../../components/Paper';
import { Checkmark } from '../../components/icons/Checkmark';
import { useAuth } from '../../auth/useAuth';

const standardPoints = [
  'Follow vendors (businesses or individuals hosting events) to stay in the loop',
  'Customize your interests to discover events that matter to you',
  'Save events directly to your calendar for easy planning',
  'Like events that spark your interest',
  'Receive notifications from vendors whose events you’re following',
  'Completely free for all users',
];

const vendorPoints = [
  'Completely free for vendors, with unlimited event postings.',
  'Effortless advertising to reach your target audience',
  'Access to demographic insights to better understand your attendees',
  'Simple event tracking to manage details with ease',
  'Direct user access to your website for increased visibility',
  'Streamlined communication with your attendees for better engagement',
];

const SloganItem = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Box>
      <Text textAlign="center" fontSize="32px" fancy>
        Why sign up with Social Life of Mine?
      </Text>
      <Text textAlign="center" color={color}>
        (To bring everyone together on one unified community calendar and platform.)
      </Text>
    </Box>
  );
};

const StandardItem = () => {
  const { signIn } = useAuth();
  return (
    <Paper elevation={1} style={{ maxWidth: '468px', height: '100%', padding: '2rem 1rem' }}>
      <Stack height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack>
          <Text textAlign="center" fontWeight="bold" fontSize="28px">
            Standard Account
          </Text>
          <Text fancy textAlign="center" fontSize="30px">
            (Coming Soon)
          </Text>
          <Divider flexItem style={{ paddingTop: '1rem' }} />
          <br />
          <Text textAlign="center" fontSize="18px" fontWeight="bold">
            A standard account is for an individual who is looking to find events and vendors in
            their area.
          </Text>
          <br />
          <Stack spacing={'10px'}>
            {standardPoints.map((point) => (
              <Stack key={point} direction="row" alignItems="center" paddingRight="35px">
                <Checkmark fontSize="small" />
                <Text key={point} fontSize="18px" style={{ 'line-height': '25px' }}>
                  {point}
                </Text>
              </Stack>
            ))}
          </Stack>
        </Stack>
        {/* <br />
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Button variant="contained" onClick={() => signIn()} style={{ width: '300px' }}>
            Create Standard Account
          </Button>
        </Stack> */}
      </Stack>
    </Paper>
  );
};

const VendorItem = () => {
  const { signIn } = useAuth();
  return (
    <Paper elevation={1} style={{ maxWidth: '468px', height: '100%', padding: '2rem 1rem' }}>
      <Stack height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack>
          <Text fontWeight="bold" fontSize="28px" style={{ textAlign: 'center' }}>
            Vendor Account
          </Text>
          <Text fancy textAlign="center" fontSize="30px">
            (Free to sign up!)
          </Text>
          <Divider style={{ paddingTop: '1rem' }} />
          <br />
          <Text textAlign="center" fontSize="18px" fontWeight="bold">
            A vendor account is for a business or person who plans on hosting and promoting events.
          </Text>
          <br />
          <Stack spacing={'10px'}>
            {vendorPoints.map((point) => (
              <Stack key={point} direction="row" alignItems="center" paddingRight="35px">
                <Checkmark fontSize="small" />
                <Text key={point} fontSize="18px" style={{ 'line-height': '25px' }}>
                  {point}
                </Text>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <br />
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Button variant="contained" onClick={() => signIn('signup')} style={{ width: '300px' }}>
            Create Vendor Account
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export const BecomeAMemberPage = () => {
  return (
    <Page header="BECOME A MEMBER" maxWidth="1200px">
      <Grid container spacing={'3rem'} justifyContent={'center'}>
        <Grid item xs={12}>
          <SloganItem />
        </Grid>
        <Grid item md={12} lg={6} justifyContent={'center'}>
          <Stack height={'100%'} alignItems={'center'}>
            <StandardItem />
          </Stack>
        </Grid>
        <Grid item md={12} lg={6} justifyContent={'center'}>
          <Stack height={'100%'} alignItems={'center'}>
            <VendorItem />
          </Stack>
        </Grid>
        <Grid item xs={12} justifyContent={'center'}>
          <Text textAlign="center">Have any questions? We are here to help.</Text>
          <Text textAlign="center">
            Contact Paige at:{' '}
            <a href="mailto:paige@sociallifeofmine.com" target="_blank" rel="noopener noreferrer">
              paige@sociallifeofmine.com
            </a>
          </Text>
        </Grid>
      </Grid>
    </Page>
  );
};
