import React from 'react';
import { useToast } from '../../../hooks/useToast';
import { TEventForm } from '../../../types/events';
import { useUpdateEventMutation } from '../../../mutations/useUpdateEventMutation';

export const useUpdateEvent = () => {
  const { showToast } = useToast();
  const { mutateAsync } = useUpdateEventMutation();
  const [error, setError] = React.useState<string>();

  const updateEvent = async (
    data: TEventForm,
    eventId: string,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      await mutateAsync({ data, eventId });
      setError(undefined);
    } catch (err: any) {
      const message = err.message;
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return { updateEvent, error };
};
