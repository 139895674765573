import { useMutation } from 'react-query';
import { removeEventFromCalendar } from '../api/standards';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, REMOVE_FROM_CALENDAR_SUCCESS } from '../hooks/useToast';

type RemoveEventFromCalendarOptions = {
  userId: string;
  eventId: string;
};
export const useRemoveEventFromCalendarMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, eventId }: RemoveEventFromCalendarOptions) => {
      const token = await getAccessTokenSilently();
      return removeEventFromCalendar(userId, eventId, token);
    },
    onSuccess: () => {
      showToast(REMOVE_FROM_CALENDAR_SUCCESS, 'success');
    },
  });
};
