import { useTheme } from '@mui/material';
import { Text } from '../components/text';
import { Page } from '../components/layout/page';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const VerifyEmailPage = () => {
  const theme = useTheme();

  const handleFocus = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <Page header="Verify Email">
      <Text textAlign="center" color={theme.palette.secondary.main}>
        Please verify your email before continuing.
      </Text>
      <br />
      <Text textAlign="center" color={theme.palette.secondary.main}>
        If after verifying this page persists, please logout and log back in.
      </Text>
      <br />
      <Text textAlign="center" color={theme.palette.secondary.main}>
        If you do not receive an email verification, contact Paige at paige@sociallifeofmine.com
      </Text>
    </Page>
  );
};
