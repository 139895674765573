import { useMutation, useQueryClient } from 'react-query';
import { TVendor, TVendorForm } from '../types/vendors';
import { updateVendor } from '../api/vendors';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, UPDATE_PROFILE_SUCCESS } from '../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

type UnclaimedUpdateOptions = {
  vendor: TVendor;
  data: TVendorForm;
};
export const useUpdateUnclaimedMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { user, setUser } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({ vendor, data }: UnclaimedUpdateOptions) => {
      const token = await getAccessTokenSilently();
      return updateVendor(data, vendor, token);
    },
    onSuccess: (vendor) => {
      if (user?.sub === vendor.sub) setUser(vendor);
      queryClient.invalidateQueries({ queryKey: ['getVendorById', vendor.sub] });
      queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      showToast(UPDATE_PROFILE_SUCCESS, 'success');
      navigate(`/vendors/${vendor.sub}`);
    },
  });
};
