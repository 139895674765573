import { createFetch } from './createFetch';
import { Subscription } from './users';

export type TFeedbackForm = Omit<TFeedback, '_id' | 'timestamp'>;
export type TFeedback = {
  _id: string;
  rating: number;
  comments: string;
  timestamp: number;
  userId?: string;
  subscription?: Subscription;
};
export const createFeedback = async (data: TFeedbackForm) => {
  const body = JSON.stringify(data);
  const response = await createFetch('/feedback', 'POST', body);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
};

export const getFeedback = async (token?: string) => {
  const response = await createFetch('/feedback', 'GET', undefined, token);
  const result = await response.json();
  if (response.status !== 200) {
    const { message } = result;
    throw new Error(message);
  }
  return result as TFeedback[];
};
