import React from 'react';
import { BookmarkAddOutlinedIcon, BookmarkRemoveOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  vendor: boolean;
  isToggled?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};
export const Like: React.FC<Props> = ({ isToggled, disabled, vendor, onClick }) => {
  const theme = useTheme();
  const color = isToggled ? theme.palette.warning.main : theme.palette.success.main;
  const displayColor = disabled ? 'lightgrey' : color;
  let text = isToggled ? 'Dislike Event' : 'Like Event';
  if (vendor) text = isToggled ? 'Remove Follow' : 'Follow Vendor';

  return (
    <Icon tooltip={text} onClick={onClick}>
      {!isToggled ? (
        <BookmarkAddOutlinedIcon style={{ color: displayColor }} fontSize="large" />
      ) : (
        <BookmarkRemoveOutlinedIcon style={{ color: displayColor }} fontSize="large" />
      )}
    </Icon>
  );
};
