import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from '../components/layout/Layout';
import { HomePage } from '../pages/home/HomePage';
import { AboutUsPage } from '../pages/home/AboutUsPage';
import { BecomeAMemberPage } from '../pages/home/BecomeAMemberPage';
import { ContactUsPage } from '../pages/home/ContactUsPage';
import { EventPage } from '../pages/shared/EventPage';
import { VendorPage } from '../pages/shared/VendorPage';
import { useAuth } from '../auth/useAuth';
import { CreateVendorPage } from '../pages/admin/CreateVendorPage/CreateVendorPage';
import { BrowsePage } from '../pages/BrowsePage';
import { protectByAuthenticated, protectBySubscription } from '../auth/PrivateRoute';
import { EventStatisticsPage } from '../pages/shared/EventStatisticsPage';
import { VendorStatisticsPage } from '../pages/shared/VendorStatisticsPage';
import { CalendarPage } from '../pages/shared/CalendarPage';
import { CreateEventPage as VendorCreateEventPage } from '../pages/vendor/CreateEventPage';
import { CreateEventPage as AdminCreateEventPage } from '../pages/admin/CreateEventPage/CreateEventPage';
import { UpdateEventPage } from '../pages/admin/EditEventPage/EditEventPage';
import { VendorEditPage } from '../pages/vendor/VendorEditPage';
import { NotificationsPage } from '../pages/NotificationsPage';
import { LoadingPage } from '../pages/LoadingPage';
import { ReviewFlagsPage } from '../pages/admin/ReviewFlagsPage/ReviewFlagsPage';
import { FeedbackPage } from '../pages/admin/FeedbackPage/FeedbackPage';
import { ProfilePage } from '../pages/ProfilePage';
import { AuditsPage } from '../pages/admin/AuditsPage/AuditsPage';
import React from 'react';
import { PdfViewerPage } from '../pages/shared/PdfViewerPage';

export const Router = () => {
  const { user, isLoading, isAuthenticated, error } = useAuth();

  if (isLoading) return <LoadingPage />;
  if (!user && isAuthenticated) return <LoadingPage />;
  if (error) throw new Error(error.message); // TODO - SSO - Throw? Need error boundary?

  const ProtectedFeedbackPage = protectBySubscription({
    user,
    element: FeedbackPage,
    subscriptions: ['admin'],
  });
  const ProtectedReviewFlagsPage = protectBySubscription({
    user,
    element: ReviewFlagsPage,
    subscriptions: ['admin'],
  });
  const ProtectedAuditsPage = protectBySubscription({
    user,
    element: AuditsPage,
    subscriptions: ['admin'],
  });
  const ProtectedEventStatisticsPage = protectBySubscription({
    user,
    element: EventStatisticsPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedVendorStatisticsPage = protectBySubscription({
    user,
    element: VendorStatisticsPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedCalendarPage = protectBySubscription({
    user,
    element: CalendarPage,
    subscriptions: ['standard', 'vendor', 'admin'],
  });
  const ProtectedProfilePage = protectByAuthenticated(isAuthenticated, ProfilePage);
  const ProtectedVendorEditPage = protectBySubscription({
    user,
    element: VendorEditPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedCreateEventPage = protectBySubscription({
    user,
    element: user?.subscription === 'vendor' ? VendorCreateEventPage : AdminCreateEventPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedUpdateEventPage = protectBySubscription({
    user,
    element: UpdateEventPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedCreateVendorPage = protectBySubscription({
    user,
    element: CreateVendorPage,
    subscriptions: ['admin'],
  });
  const ProtectedNotificationsPage = protectBySubscription({
    user,
    element: NotificationsPage,
    subscriptions: ['standard', 'vendor', 'admin'],
  });

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />

        {/* Public */}
        <Route path="/join" element={<BecomeAMemberPage />} />
        <Route path="/browse" element={<BrowsePage />} />

        {/* Public / Standard / Vendor / Admin */}
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/events/:id" element={<EventPage />} />
        <Route path="/vendors/:id" element={<VendorPage />} />

        {/* Standard / Vendor */}
        <Route path="/calendar" element={<ProtectedCalendarPage />} />
        <Route path="/profile" element={<ProtectedProfilePage />} />

        {/* Vendor / Admin */}
        <Route path="/events/create" element={<ProtectedCreateEventPage />} />
        <Route path="/events/:id/edit" element={<ProtectedUpdateEventPage />} />
        <Route path="/events/:id/statistics" element={<ProtectedEventStatisticsPage />} />
        <Route path="/vendors/:id/statistics" element={<ProtectedVendorStatisticsPage />} />
        <Route path="/vendors/:id/edit" element={<ProtectedVendorEditPage />} />

        {/* Standard */}
        <Route path="/notifications" element={<ProtectedNotificationsPage />} />
        <Route path="/notifications/:eventId" element={<ProtectedNotificationsPage />} />

        {/* Admin */}
        <Route path="/vendors/create" element={<ProtectedCreateVendorPage />} />
        <Route path="/feedback" element={<ProtectedFeedbackPage />} />
        <Route path="/flags" element={<ProtectedReviewFlagsPage />} />
        <Route path="/audits" element={<ProtectedAuditsPage />} />

        {/* External Links */}
        <Route path="/privacy-policy" element={<PdfViewerPage document={'Privacy Policy'} />} />
        <Route path="/terms-of-service" element={<PdfViewerPage document="Terms of Service" />} />
        <Route
          path="/advertise-agreement"
          element={<PdfViewerPage document="Advertise Agreement" />}
        />

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Route>
    </Routes>
  );
};
