import { Grid, Stack } from '@mui/material';
import React from 'react';
import { DATE_SHORT } from '../formatting';
import { TEvent } from '../types/events';
import { Text } from './text';
import { EventItem } from './items/EventItem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getNextOcurrence, monthsArr } from '../utils/days';
import { useSessionStorage } from '../hooks/useSessionStorage';

const DAYS_TO_MILLISECONDS = 24 * 60 * 60 * 1000;
const DATE_CHECK_OFFSET = 27 * DAYS_TO_MILLISECONDS;

const MAX_FUTURE_DATE = Date.now() + 365 * DAYS_TO_MILLISECONDS;

export type EventDateMap = {
  [key: string]: TEvent[];
};
type EventsByMonth = {
  [month: string]: TEvent[];
};
type EventsByMonthByDate = {
  [month: string]: EventDateMap;
};

type CalendarViewProps = {
  events: TEvent[];
  view?: string;
};
export const CalendarListView: React.FC<CalendarViewProps> = ({ events, view }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const startDate = Number(searchParams.get('startDate')) || undefined;
  // Mapping of <Month, Day>
  // Day is <Day, Event[]>
  const [eventsFormatted, setEventsFormatted] = React.useState<EventsByMonthByDate>({});

  const { useScroll } = useSessionStorage();
  const scroll = useScroll();

  React.useEffect(() => {
    const sortedEvents = sortEventsByMonth(events);
    const eventsByMonthAndDate = createMonthMap(sortedEvents);

    // Filter out months before startDate if it exists
    if (startDate) {
      const startMonth = new Date(startDate).toLocaleString('default', { month: 'long' });
      const startYear = new Date(startDate).toLocaleString('default', { year: 'numeric' });

      const filteredEvents = Object.entries(eventsByMonthAndDate).reduce<EventsByMonthByDate>(
        (acc, [month, events]) => {
          const [monthName, year] = month.split(', ');
          if (year > startYear || (year === startYear && monthsArr.indexOf(monthName) >= monthsArr.indexOf(startMonth))) {
            acc[month] = events;
          }
          return acc;
        },
        {},
      );

      setEventsFormatted(filteredEvents);
    } else {
      setEventsFormatted(eventsByMonthAndDate);
    }
  }, [events, startDate]);

  React.useEffect(() => {
    if (
      document.documentElement.scrollHeight > scroll.pageY &&
      Object.keys(eventsFormatted).length
    ) {
      window.scrollTo({ top: scroll.pageY, left: scroll.pageX, behavior: 'smooth' });
    }
  }, [document.documentElement.scrollHeight, eventsFormatted]);

  const getEventCount = (dateMap: EventDateMap): number => {
    return Object.keys(dateMap).reduce<number>((acc, date) => {
      return acc + dateMap[date].length;
    }, 0);
  };

  const createMonthMap = (eventsByMonth: EventsByMonth): EventsByMonthByDate => {
    return Object.keys(eventsByMonth).reduce<EventsByMonthByDate>((acc, month) => {
      const eventsInMonth = eventsByMonth[month];
      const eventsMap = createDateMap(eventsInMonth);
      acc[month] = eventsMap;
      return acc;
    }, {});
  };

  const sortEventsByMonth = (list: TEvent[]): EventsByMonth => {
    return list.reduce<EventsByMonth>((acc, event) => {
      switch (event.dateTime.type) {
        case 'Does not repeat': {
          const date = new Date(getNextOcurrence(new Date(), event.dateTime));
          const month = date.toLocaleString('default', { month: 'long' });
          const year = date.toLocaleString('default', { year: 'numeric' });
          const fullDate = `${month}, ${year}`;
          if (!acc[fullDate]) acc[fullDate] = [];
          acc[fullDate].push(event);
          return acc;
        }
        default: {
          let timestamp = getNextOcurrence(new Date(), event.dateTime);

          while (timestamp <= event.dateTime.finalDay && timestamp < MAX_FUTURE_DATE) {
            const date = new Date(timestamp);
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            const fullDate = `${month}, ${year}`;

            if (!acc[fullDate]) acc[fullDate] = [];

            const eventAlreadyAdded = acc[fullDate].some((e) => e._id === event._id);

            if (!eventAlreadyAdded) {
              acc[fullDate].push(event);
            }

            timestamp += DATE_CHECK_OFFSET;
          }

          return acc;
        }
      }
    }, {});
  };

  const createDateMap = (list: TEvent[]): EventDateMap => {
    return list.reduce<EventDateMap>((acc, value) => {
      const date = getNextOcurrence(new Date(), value.dateTime);
      const formattedDate = new Date(date).toLocaleDateString(undefined, DATE_SHORT as any);
      if (!acc[formattedDate]) acc[formattedDate] = [];
      acc[formattedDate].push(value);
      return acc;
    }, {});
  };

  return (
    <Stack spacing={'2rem'} sx={{ display: { xs: 'flex', sm: view === 'list' ? 'flex' : 'none' } }}>
      <br />
      {Object.entries(eventsFormatted).map(([month, dateMap], i) => {
        return (
          <Stack key={month}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
              <Grid item sm={8}>
                <Text fontWeight="bold" fontSize="28px">
                  {month.toUpperCase()}
                </Text>
              </Grid>
              <Grid item sm={4}>
                <Text
                  fontWeight="bold"
                  textAlign="right"
                >{`Local Events: ${getEventCount(dateMap)}`}</Text>
              </Grid>
            </Grid>
            <Stack>
              {Object.keys(dateMap).map((date) => (
                <Stack key={date} spacing={'0.5rem'}>
                  {dateMap[date].map((event) => (
                    <EventItem
                      key={event._id}
                      event={event}
                      onClick={() => navigate(`/events/${event._id}`)}
                      onError={console.log}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
