import { useMutation } from 'react-query';
import { followVendor } from '../api/standards';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, FOLLOW_VENDOR_SUCCESS } from '../hooks/useToast';

type FollowVendorOptions = {
  userId: string;
  vendorId: string;
};
export const useFollowVendorMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId, vendorId }: FollowVendorOptions) => {
      const token = await getAccessTokenSilently();
      return followVendor(userId, vendorId, token);
    },
    onSuccess: () => {
      showToast(FOLLOW_VENDOR_SUCCESS, 'success');
    },
  });
};
