import { Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { Page } from '../../components/layout/page';
import { BarChart, PieChart } from '@mui/x-charts';
import { useParams } from 'react-router-dom';
import { Statistic } from '../../types/statistic';
import { useGetEventByIdQuery } from '../../queries/useGetEventByIdQuery';
import { LoadingPage } from '../LoadingPage';
import { useGetEventStatsByIdQuery } from '../../queries/useGetEventStatsByIdQuery';
import { Text } from '../../components/text';

type BarChartData = {
  keys: string[]; // Label for xAxis
  data: number[]; // Data for each column
};

type PieChartData = {
  label: string; // Label and key
  value: number; // Data for each label
};

// COMPONENT //
export const EventStatisticsPage = () => {
  const { id } = useParams();
  const { isLoading: isLoadingEvent, data: event, error: eventError } = useGetEventByIdQuery(id);
  const {
    isLoading: isLoadingEventStats,
    data: eventStats,
    error: eventStatsError,
  } = useGetEventStatsByIdQuery(id);
  const [likesBarChart, setLikesBarChart] = React.useState<BarChartData | null>(null);
  const [viewsBarChart, setViewsBarChart] = React.useState<BarChartData | null>(null);
  const [rsvpsBarChart, setRSVPsBarChart] = React.useState<BarChartData | null>(null);
  const [ageChart, setAgeChart] = React.useState<PieChartData[] | null>(null);
  const [pronounsChart, setPronounsChart] = React.useState<PieChartData[] | null>(null);

  type StatsByMonth = {
    [month: string]: Statistic[];
  };

  const sortStatsByMonth = (list: Statistic[]): StatsByMonth => {
    return list
      .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .reduce<StatsByMonth>((acc, listEventStat) => {
        const date = new Date(listEventStat.timestamp);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        const fullDate = `${month}, ${year}`;
        if (!acc[fullDate]) acc[fullDate] = [];
        acc[fullDate].push(listEventStat);
        return acc;
      }, {});
  };

  function getAgeRange(epochTime: number) {
    // Calculate the current time in milliseconds
    const currentTime = new Date().getTime();

    // Calculate the age in milliseconds
    const ageInMillis = currentTime - epochTime;

    // Convert age to years
    const ageInYears = ageInMillis / (365.25 * 24 * 60 * 60 * 1000);

    // Determine the age range
    if (ageInYears >= 18 && ageInYears <= 20) {
      return '18-20';
    } else if (ageInYears > 20 && ageInYears <= 30) {
      return '21-30';
    } else if (ageInYears > 30 && ageInYears <= 40) {
      return '31-40';
    } else if (ageInYears > 40 && ageInYears <= 50) {
      return '41-50';
    } else {
      return '50+';
    }
  }

  React.useEffect(() => {
    if (eventStats) {
      if (eventStats.views.length) {
        setViewsBarChart(() => {
          const sortedViews = sortStatsByMonth(eventStats?.views);
          const keys = Object.keys(sortedViews);
          return {
            keys,
            data: keys.map((key) => sortedViews[key].length),
          };
        });
      }
      if (eventStats.likes.length) {
        setLikesBarChart(() => {
          const sortedLikes = sortStatsByMonth(eventStats?.likes);
          const keys = Object.keys(sortedLikes);
          return {
            keys,
            data: keys.map((key) => sortedLikes[key].length),
          };
        });
      }
      if (eventStats.rsvps.length) {
        setRSVPsBarChart(() => {
          const sortedRSVPs = sortStatsByMonth(eventStats?.rsvps);
          const keys = Object.keys(sortedRSVPs);
          return {
            keys,
            data: keys.map((key) => sortedRSVPs[key].length),
          };
        });
        setAgeChart(() => {
          const data: { [key: string]: number } = {};
          eventStats?.rsvps.forEach((stat) => {
            const ageRange = getAgeRange(stat.userBirthday);
            return data[ageRange] ? (data[ageRange] += 1) : (data[ageRange] = 1);
          });
          return Object.keys(data).map((key) => ({ id: key, label: key, value: data[key] }));
        });
        setPronounsChart(() => {
          const data: { [key: string]: number } = {};
          eventStats?.rsvps.forEach((stat) =>
            data[stat.userPronouns]
              ? (data[stat.userPronouns] += 1)
              : (data[stat.userPronouns] = 1),
          );
          return Object.keys(data).map((key) => ({ id: key, label: key, value: data[key] }));
        });
      }
    }
  }, [id, eventStats]);

  if (!id || isLoadingEvent || !event || isLoadingEventStats || !eventStats) return <LoadingPage />;

  return (
    <Page header={'EVENT STATISTICS'} subheader={event?.name} maxWidth="500px">
      <Stack component="section" spacing={'1rem'} alignItems={'center'}>
        <Stack component="section" direction={'row'} spacing={'1rem'} width={'100%'}>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Views</Text>
            <Text fontSize="36px" textAlign="center">
              {eventStats?.views.length}
            </Text>
          </Paper>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Likes</Text>
            <Text fontSize="36px" textAlign="center">
              {eventStats?.likes.length}
            </Text>
          </Paper>
          <Paper
            component="article"
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              background: 'white',
              borderRadius: '6px',
              paddingX: '12px',
              paddingY: '8px',
            }}
          >
            <Text>Total Calendar</Text>
            <Text fontSize="36px" textAlign="center">
              {eventStats?.rsvps.length}
            </Text>
          </Paper>
        </Stack>
        <Stack component="section" direction={'row'} spacing={'1rem'} width={'100%'}>
          {!isLoadingEventStats && eventStats?.rsvps && ageChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                width: '100%',
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Ages (Calendar)</Text>
              <PieChart series={[{ data: ageChart }]} height={150} />
            </Paper>
          )}

          {!isLoadingEventStats && eventStats?.rsvps && pronounsChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                width: '100%',
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Pronouns (Calendar)</Text>
              <PieChart series={[{ data: pronounsChart }]} height={150} />
            </Paper>
          )}
        </Stack>
        <Stack component="section" direction={'column'} spacing={'1rem'} width={'100%'}>
          {!isLoadingEventStats && eventStats?.likes && likesBarChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Likes</Text>
              <BarChart
                series={[
                  {
                    data: likesBarChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: likesBarChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
          {!isLoadingEventStats && eventStats?.views && viewsBarChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Monthly Views</Text>
              <BarChart
                series={[
                  {
                    data: viewsBarChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: viewsBarChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
          {!isLoadingEventStats && eventStats?.rsvps && rsvpsBarChart && (
            <Paper
              component="article"
              elevation={3}
              sx={{
                background: 'white',
                borderRadius: '6px',
                paddingX: '12px',
                paddingY: '8px',
              }}
            >
              <Text>Monthly Calendars</Text>
              <BarChart
                series={[
                  {
                    data: rsvpsBarChart?.data,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: rsvpsBarChart?.keys,
                  },
                ]}
                height={350}
              />
            </Paper>
          )}
        </Stack>
        {!!eventError && (
          <Typography color={'error'} textAlign={'center'}>
            {eventError as string}
          </Typography>
        )}
        {!!eventStatsError && (
          <Typography color={'error'} textAlign={'center'}>
            {eventStatsError as string}
          </Typography>
        )}
      </Stack>
    </Page>
  );
};
