import { useNavigate } from 'react-router-dom';
import { Page } from '../../../components/layout/page';
import { ErrorText } from '../../../components/text/ErrorText';
import { LoadingPage } from '../../LoadingPage';
import { TNotification } from '../../../types/notification';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { Paper } from '../../../components/Paper';
import { Text } from '../../../components/text';
import { ExpandMoreIcon } from '../../../components/icons';
import { useGetAuditsQuery } from '../../../queries/useGetAuditsQuery';

type AuditItemParams = {
  item: TNotification;
  onClick: () => void;
};

export const AuditItem: React.FC<AuditItemParams> = ({ item, onClick }) => {
  function getItemLabel(item: TNotification): string {
    if (item.type === 'vendor-new') {
      return `New Vendor: ${item.vendorName}`;
    } else if (item.type === 'vendor-updated') {
      return `Vendor Updated: ${item.vendorName}`;
    } else if (item.type === 'event-new') {
      return `New Event: ${item.eventName} (Vendor: ${item.vendorName})`;
    } else if (item.type === 'event-updated') {
      return `Event Updated: ${item.eventName} (Vendor: ${item.vendorName})`;
    } else {
      // Handle the case where the item type doesn't have a suitable field
      console.warn('Unknown item type:', item);
      return 'Unknown Item'; // Or provide a default fallback label
    }
  }

  const date = new Date(item.timestamp);
  const time = date.toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return (
    <Paper elevation={1} width={'100%'} onClick={() => onClick()}>
      <Stack padding={'8px'}>
        <Typography fontWeight={'bold'}>{getItemLabel(item)}</Typography>
        <Typography>{time}</Typography>
      </Stack>
    </Paper>
  );
};

export const AuditsPage = () => {
  const navigate = useNavigate();

  const { isLoading: isLoadingAudits, data: audits, error: auditsError } = useGetAuditsQuery();

  const events = audits?.filter((item) => item.type.includes('event')) || [];
  const vendors = audits?.filter((item) => item.type.includes('vendor')) || [];

  const onClick = (item: TNotification) => {
    if (item.type.includes('event')) return navigate(`/events/${item.eventId}`);
    if (item.type.includes('vendor')) return navigate(`/vendors/${item.vendorId}`);
  };

  if (isLoadingAudits) return <LoadingPage />;
  if (!audits) return <LoadingPage />;
  if (auditsError !== null) return <ErrorText>Something went wrong. Please try again.</ErrorText>;

  return (
    <Page header={'AUDITS'} maxWidth="450px">
      <Stack spacing={'2rem'}>
        <Accordion elevation={5} style={{ background: 'transparent' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Text fontSize="32px" style={{ paddingRight: '1rem' }}>
                EVENTS
              </Text>
              <Text
                style={{ paddingRight: '1rem' }}
              >{`Items: ${events.length.toString().toUpperCase()}`}</Text>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={'0.5rem'}>
              {events
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((item) => (
                  <AuditItem item={item} onClick={() => onClick(item)} key={item._id} />
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={5} style={{ background: 'transparent' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Text fontSize="32px" style={{ paddingRight: '1rem' }}>
                VENDORS
              </Text>
              <Text
                style={{ paddingRight: '1rem' }}
              >{`Items: ${vendors.length.toString().toUpperCase()}`}</Text>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={'0.5rem'}>
              {vendors
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((item) => (
                  <AuditItem item={item} onClick={() => onClick(item)} key={item._id} />
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      {/* {error && <ErrorText>{error}</ErrorText>} */}
    </Page>
  );
};
