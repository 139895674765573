import { useQuery } from 'react-query';
import { getVendorStatsById } from '../api/statistics';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetVendorStatsByIdQuery = (vendorId: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['getVendorStatsById', vendorId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getVendorStatsById(vendorId, token);
    },
    enabled: !!vendorId,
  });
};
