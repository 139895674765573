import { useMutation, useQueryClient } from 'react-query';
import { flagEventById } from '../api/events';
import { useToast, FLAG_EVENT_SUCCESS } from '../hooks/useToast';

type FlagEventOptions = {
  eventId: string;
};
export const useFlagEventMutation = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ eventId }: FlagEventOptions) => {
      return flagEventById(eventId);
    },
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', event._id] });
      queryClient.invalidateQueries({ queryKey: ['getEvents'] });
      showToast(FLAG_EVENT_SUCCESS, 'success');
    },
  });
};
