import { useMutation, useQueryClient } from 'react-query';
import { unblockUserById } from '../api/users';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, UNBLOCK_VENDOR_SUCCESS } from '../hooks/useToast';

type UnblockUserOptions = {
  userId: string;
};
export const useUnblockUserMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async ({ userId }: UnblockUserOptions) => {
      const token = await getAccessTokenSilently();
      return unblockUserById(userId, token);
    },
    onSuccess: (user) => {
      if (user.subscription === 'vendor') {
        queryClient.invalidateQueries({ queryKey: ['getVendorById', user.sub] });
        queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      }
      showToast(UNBLOCK_VENDOR_SUCCESS, 'success');
    },
  });
};
