import { useMutation, useQueryClient } from 'react-query';
import { TStandardForm } from '../types/standards';
import { updateUser } from '../api/users';
import { TVendorForm } from '../types/vendors';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, UPDATE_PROFILE_SUCCESS } from '../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

type UserUpdateOptions = {
  id: string;
  data: TStandardForm | TVendorForm;
};
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  const { user, setUser } = useAuth();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({ id, data }: UserUpdateOptions) => {
      const token = await getAccessTokenSilently();
      return updateUser(id, data, token);
    },
    onSuccess: (userData) => {
      if (user?.sub === userData.sub) setUser(userData);
      if (userData.subscription === 'vendor') {
        queryClient.invalidateQueries({ queryKey: ['getVendorById', userData.sub] });
        queryClient.invalidateQueries({ queryKey: ['getVendors'] });
      }
      showToast(UPDATE_PROFILE_SUCCESS, 'success');
      navigate(
        userData.subscription === 'vendor'
          ? `/vendors/${userData.sub.replace('|', '%7C')}`
          : `/profile`,
      );
    },
  });
};
