import styled from 'styled-components';
import { Colors } from '../../theme/colors';
import { Link as LinkDOM } from 'react-router-dom';
import { PALETTE } from '../../theme/palette';
import { useAuth } from '../../auth/useAuth';
import { useDialog } from '../../context/DialogProvider';
import { FeedbackDialog } from '../dialogs/FeedbackDialog';

const FOOTER_SMALL = 600;

const Layout = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  padding: 2rem 3rem;
  background-color: ${Colors.primary};
  box-shadow: 0 0 5px 3px ${PALETTE.primary.main};

  @media (max-width: ${FOOTER_SMALL}px) {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
  }
`;
export const Link = styled(LinkDOM)`
  color: white;
  font-size: 24px;
  text-decoration: none;
`;

const Footer = () => {
  const { isBlocked, isVendorUser } = useAuth();
  const { setOpen } = useDialog();

  return (
    <Layout>
      {!isBlocked() && <Link to="about-us">ABOUT US</Link>}
      {!isBlocked() && <Link to="contact-us">CONTACT INFO</Link>}
      {!isBlocked() && isVendorUser() && (
        <Link to={'/advertise-agreement'}>ADVERTISE AGREEMENT</Link>
      )}
      {!isBlocked() && <Link to={'/terms-of-service'}>TERMS OF SERVICE</Link>}
      {!isBlocked() && <Link to={'/privacy-policy'}>PRIVACY POLICY</Link>}
      {!isBlocked() && (
        <Link
          to="#"
          onClick={(event) => {
            event.preventDefault();
            setOpen(true, <FeedbackDialog />);
          }}
        >
          PROVIDE FEEDBACK
        </Link>
      )}
    </Layout>
  );
};

export default Footer;
