import { Divider, Grid, Stack, Typography } from '@mui/material';
import DEFAULT_IMAGE from '../../../assets/Default_Image.png';
import { ensureHttps, formatPhoneNumber, formatWebsiteLink } from '../../../utils/formatting';
import { Text } from '../../../components/text';
import { Paper } from '../../../components/Paper';
import React from 'react';
import { useAuth } from '../../../auth/useAuth';
import { TVendor } from '../../../types/vendors';
import { ImageDisplay } from '../../../components/ImageDisplay';
import { ActionButtons } from '../../../components/ActionButtons';
import { useActionButtons } from '../../../hooks/useActionButtons';
import { isFollowingVendor } from '../../../utils/userUtils';
import { DAYS } from '../../../types/misc';
import { TIME } from '../../../formatting';
import { usePublicUser } from '../../../context/PublicUserProvider';

type InfoItemProps = {
  vendor: TVendor;
};
const GridInfoItem: React.FC<InfoItemProps> = ({ vendor }) => {
  return (
    <Grid component="section" container direction="column" item xs={12} sx={{ gap: '1.5rem' }}>
      {vendor.companyName && (
        <Text
          textAlign="center"
          fontSize="32px"
          fontWeight="bold"
          style={{ textDecoration: 'underline' }}
        >
          {vendor.companyName}
        </Text>
      )}
      {vendor.businessDescription && (
        <Text
          textAlign="center"
          fontSize="16px"
          fontWeight="bold"
          style={{ wordBreak: 'break-word' }}
        >
          {vendor.businessDescription}
        </Text>
      )}
      {vendor.missionStatement && (
        <Text textAlign="center" style={{ wordBreak: 'break-word' }}>
          {vendor.missionStatement}
        </Text>
      )}
      {vendor.address && (
        <Text component="address" textAlign="center">
          <Text component="span" fontWeight="bold" fontSize="24px">
            {vendor.address.street}
          </Text>
          <br />
          <Text component="span">
            {vendor.address.city}, {vendor.address.state} {vendor.address.zipcode}
          </Text>
        </Text>
      )}
      {vendor.phoneNumber && (
        <Text component="address" textAlign="center">
          Contact:{' '}
          <Text component="span" fontWeight="bold" fontSize="16px">
            {formatPhoneNumber(vendor.phoneNumber)}
          </Text>
        </Text>
      )}
      {vendor.websiteLink && (
        <Stack component="section" style={{ wordBreak: 'break-word' }}>
          <Text component="span" fontWeight="bold" fontSize="16px" textAlign="center">
            Website:
          </Text>
          <a
            style={{ margin: 0, wordWrap: 'break-word', textAlign: 'center' }}
            href={ensureHttps(vendor.websiteLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatWebsiteLink(vendor.websiteLink)}
          </a>
        </Stack>
      )}
      {vendor.daysOfOperation && (
        <Grid component="section" container direction="column" alignItems={'center'}>
          <Text textAlign="center" fontWeight="bold" component="p">
            Hours of Operation
          </Text>
          <br />
          <Stack
            component="ul"
            direction={'column'}
            spacing={'0.5rem'}
            width={{ xs: '100%', sm: '70%' }}
            sx={{ margin: 0, padding: 0 }}
          >
            {Object.entries(DAYS).map(([abr, dayDisplay]) => {
              const dayOperation = vendor.daysOfOperation.find(({ day }) => day === abr);

              return (
                <Stack
                  component="li"
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  gap={'1rem'}
                  key={`daysOfOperation-${dayDisplay}:`}
                >
                  <Typography textAlign="center">{`${dayDisplay}:`}</Typography>
                  <Typography textAlign="center">
                    {dayOperation
                      ? `${new Date(dayOperation.hours.open).toLocaleTimeString(undefined, TIME)} - ${new Date(dayOperation.hours.close).toLocaleTimeString(undefined, TIME)}`
                      : 'Closed'}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

type Props = {
  vendor: TVendor;
  onError?: (error: unknown) => void;
};
export const DisplayVendor = ({ vendor, onError }: Props) => {
  const { user, isPublicUser, isStandardUser, isVendorUser, isAdminUser } = useAuth();
  const { publicIsFollowingVendor } = usePublicUser();

  const {
    followVendorToggleMutation,
    blockUserToggleMutation,
    flagUserToggleMutation,
    toggleFollowVendorClick,
    toggleBlockUserClick,
    toggleFlagUserClick,
    onShareClick,
    onEditVendorClick,
    onVendorStatisticsClick,
    onNotificationsClick,
  } = useActionButtons();
  const { error: followVendorError, isLoading: isLoadingFollowVendor } = followVendorToggleMutation;
  const { error: blockUserError, isLoading: isLoadingBlockUser } = blockUserToggleMutation;
  const { error: flagUserError, isLoading: isLoadingFlagUser } = flagUserToggleMutation;

  React.useEffect(() => {
    if (followVendorError) onError && onError(followVendorError);
    if (blockUserError) onError && onError(blockUserError);
    if (flagUserError) onError && onError(flagUserError);
  }, [followVendorError, blockUserError, flagUserError]);

  const isFollowing = isPublicUser()
    ? publicIsFollowingVendor(vendor.sub)
    : isStandardUser()
      ? isFollowingVendor(vendor.sub, user)
      : false;
  const isOwner = user && user.sub === vendor.sub;

  const imageSource = vendor.photo || DEFAULT_IMAGE;

  // const onNotificationClick =
  //   isFollowing || isOwner || isAdminUser()
  //     ? () => onNotificationsClick(undefined, vendor.sub.replace('|', '%7C'))
  //     : undefined;
  const onFollowClick = () => toggleFollowVendorClick(isFollowing, vendor.sub);
  const onEdit = () => onEditVendorClick(vendor.sub);
  const onStatistics = () => onVendorStatisticsClick(vendor.sub);
  const onBlockClick = () => toggleBlockUserClick(vendor.blocked || false, vendor.sub);
  const onFlagClick =
    isAdminUser() || (vendor.sub.includes('auth0') && !isOwner && vendor.flagged === false)
      ? () => toggleFlagUserClick(!!vendor.flagged, vendor.sub)
      : undefined;

  return (
    <Paper component="article" elevation={2} style={{ padding: '1rem' }}>
      <Grid container justifyContent={'space-evenly'}>
        <ImageDisplay src={imageSource} alt={vendor.companyName} size={'100%'} />
        <GridInfoItem vendor={vendor} />
        <Grid item xs={12}>
          <br />
          <Divider />
          <br />
        </Grid>
        <Grid item xs={12}>
          <ActionButtons
            isLoadingBlock={isLoadingBlockUser}
            isBlocked={!!vendor.blocked}
            isFlagged={!!vendor.flagged}
            isFollowing={isFollowing}
            isLoadingFollowVendor={isLoadingFollowVendor}
            isLoadingFlag={isLoadingFlagUser}
            // onNotificationClick={onNotificationClick}
            onFollowClick={isPublicUser() || isStandardUser() ? onFollowClick : undefined}
            onEditClick={isOwner || isAdminUser() ? onEdit : undefined}
            onShareClick={isPublicUser() && isStandardUser() ? onShareClick : undefined}
            onStatisticsClick={isOwner || isAdminUser() ? onStatistics : undefined}
            onBlockClick={isAdminUser() && vendor.claimed ? onBlockClick : undefined}
            onFlagClick={onFlagClick}
          />
        </Grid>

        {vendor.blocked && (
          <Grid
            container
            item
            xs={12}
            justifyContent={'center'}
            padding={'1rem'}
            marginTop={'1rem'}
            style={{ background: '#0000001c' }}
          >
            <Text textAlign="center">Vendor is blocked</Text>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
