import { useNavigate } from 'react-router-dom';
import { Page } from '../../../components/layout/page';
import { ErrorText } from '../../../components/text/ErrorText';
import { useGetEventsQuery } from '../../../queries/useGetEventsQuery';
import { useGetNotificationsQuery } from '../../../queries/useGetNotificationsQuery';
import { useGetVendorsQuery } from '../../../queries/useGetVendorsQuery';
import { LoadingPage } from '../../LoadingPage';
import { TEvent } from '../../../types/events';
import { TVendor } from '../../../types/vendors';
import { TNotification } from '../../../types/notification';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { Paper } from '../../../components/Paper';
import { Text } from '../../../components/text';
import { ExpandMoreIcon } from '../../../components/icons';

type FlaggedItemParams = {
  item: TEvent | TVendor | TNotification;
  onClick: () => void;
};

export const FlaggedItem: React.FC<FlaggedItemParams> = ({ item, onClick }) => {
  function getItemLabel(item: TEvent | TVendor | TNotification): string {
    if ('name' in item) {
      return `${item.name} (Vendor: ${item.vendorName})`;
    } else if ('companyName' in item) {
      return item.companyName;
    } else if ('message' in item) {
      return `${item.message} (Vendor: ${item.vendorName})`;
    } else {
      // Handle the case where the item type doesn't have a suitable field
      console.warn('Unknown item type:', item);
      return 'Unknown Item'; // Or provide a default fallback label
    }
  }

  return (
    <Paper elevation={1} width={'100%'} onClick={() => onClick()}>
      <Stack padding={'8px'}>
        <Typography fontWeight={'bold'}>{getItemLabel(item)}</Typography>
      </Stack>
    </Paper>
  );
};

export const ReviewFlagsPage = () => {
  const navigate = useNavigate();
  const {
    isLoading: isLoadingEvents,
    data: events,
    error: eventsError,
  } = useGetEventsQuery({ blocked: false, flagged: true });
  const {
    isLoading: isLoadingVendors,
    data: vendors,
    error: vendorsError,
  } = useGetVendorsQuery({ blocked: false, claimed: true, flagged: true });
  const {
    isLoading: isLoadingNotifications,
    data: notifications,
    error: notificationsError,
  } = useGetNotificationsQuery({ flagged: true, blocked: false });
  if (isLoadingEvents || isLoadingVendors || isLoadingNotifications) return <LoadingPage />;
  if (!events || !vendors || !notifications) return <LoadingPage />;
  if (eventsError !== null || vendorsError !== null || notificationsError !== null)
    return <ErrorText>Something went wrong. Please try again.</ErrorText>;

  return (
    <Page header={'FLAGS'} maxWidth="450px">
      <Stack spacing={'2rem'}>
        <Accordion elevation={5} style={{ background: 'transparent' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Text fontSize="32px" style={{ paddingRight: '1rem' }}>
                EVENTS
              </Text>
              <Text
                style={{ paddingRight: '1rem' }}
              >{`Items: ${events.length.toString().toUpperCase()}`}</Text>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={'0.5rem'}>
              {events.map((event) => (
                <FlaggedItem
                  key={event._id}
                  item={event}
                  onClick={() => navigate(`/events/${event._id}`)}
                />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={5} style={{ background: 'transparent' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Text fontSize="32px" style={{ paddingRight: '1rem' }}>
                VENDORS
              </Text>
              <Text
                style={{ paddingRight: '1rem' }}
              >{`Items: ${vendors.length.toString().toUpperCase()}`}</Text>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={'0.5rem'}>
              {vendors.map((vendor) => (
                <FlaggedItem
                  key={vendor.sub}
                  item={vendor}
                  onClick={() => navigate(`/vendors/${vendor.sub}`)}
                />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={5} style={{ background: 'transparent' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Text fontSize="32px" style={{ paddingRight: '1rem' }}>
                NOTIFICATIONS
              </Text>
              <Text
                style={{ paddingRight: '1rem' }}
              >{`Items: ${notifications.length.toString().toUpperCase()}`}</Text>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={'0.5rem'}>
              {notifications.map((notification) => (
                <FlaggedItem
                  key={notification._id}
                  item={notification}
                  onClick={() => navigate(`/notifications?vendorId=${notification.vendorId}`)}
                />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      {/* {error && <ErrorText>{error}</ErrorText>} */}
    </Page>
  );
};
