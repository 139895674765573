import React from 'react';
import { TVendorForm } from '../../../types/vendors';
import { useToast } from '../../../hooks/useToast';
import { useCreateVendorMutation } from '../../../mutations/useCreateVendorMutation';

export const useCreateVendor = () => {
  const { showToast } = useToast();
  const { mutateAsync } = useCreateVendorMutation();
  const [error, setError] = React.useState<string>();

  const createVendor = async (
    data: TVendorForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      setError(undefined);
      await mutateAsync(data);
    } catch (err: any) {
      const message = err.message;
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return { createVendor, error };
};
