import { Typography } from '@mui/material';

export type TextProps = {
  color?: string;
  component?: React.ElementType<any>;
  textAlign?: 'left' | 'center' | 'right';
  fontWeight?: string;
  fontSize?: string;
  fancy?: boolean;
  disabled?: boolean;
  style?: any;
  onClick?: (e: any) => void;
};

export const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  color,
  component,
  textAlign,
  fontWeight,
  fontSize,
  fancy,
  disabled,
  style,
  children,
  onClick,
}) => {
  return component ? (
    <Typography
      style={{
        color: color || (disabled ? 'lightgrey' : 'black'),
        textAlign: textAlign || 'left',
        fontWeight: fontWeight || 'normal',
        fontSize: fontSize || '16px',
        fontStyle: fancy ? 'italic' : 'normal',
        padding: 0,
        margin: 0,
        cursor: onClick ? 'pointer' : 'default',
        wordWrap: 'break-word',
        fontFamily: 'Times',
        ...style,
      }}
      component={component || undefined}
      onClick={onClick}
    >
      {children}
    </Typography>
  ) : (
    <p
      style={{
        color: color || (disabled ? 'lightgrey' : 'black'),
        textAlign: textAlign || 'left',
        fontWeight: fontWeight || 'normal',
        fontSize: fontSize || '16px',
        fontStyle: fancy ? 'italic' : 'normal',
        padding: 0,
        margin: 0,
        cursor: onClick ? 'pointer' : 'default',
        wordWrap: 'break-word',
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </p>
  );
};
