import React from 'react';
import { Stack, Button, Divider, Grid } from '@mui/material';
import { Page } from '../components/layout/page';
import { Text } from '../components/text';
import { NotificationMap, TNotification } from '../types/notification';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import Container from '../components/Container';
import TextField from '../components/inputs/TextField';
import { useGetEventByIdQuery } from '../queries/useGetEventByIdQuery';
import { useCreateNotificationMutation } from '../mutations/useCreateNotificationMutation';
import { useGetNotificationsQuery } from '../queries/useGetNotificationsQuery';
import { LoadingPage } from './LoadingPage';
import { GetNotificationsParams } from '../api/notifications';
import { ActionButtons } from '../components/ActionButtons';
import { useActionButtons } from '../hooks/useActionButtons';
import { Item } from '../components/items/Item';
import { useCancelNotificationMutation } from '../mutations/useCancelNotificationMutation';
import { useUpdateNotificationMutation } from '../mutations/useUpdateNotificationMutation';
import { Cancel } from '../components/icons/Cancel';
import { CheckmarkCircle } from '../components/icons/CheckmarkCircle';
import { useToast } from '../hooks/useToast';

export const Notification: React.FC<TNotification> = (props) => {
  const { _id, type, eventName, timestamp, message, flagged, blocked, canceled, vendorId } = props;

  const { user, isStandardUser, isVendorUser, isAdminUser } = useAuth();
  const { showToast } = useToast();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [newMessage, setNewMessage] = React.useState<string>(message);

  const notificationInfo = NotificationMap[type];
  const { color, title } = notificationInfo;
  const date = new Date(timestamp);
  const time = date.toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const isOwner = user && user.sub === vendorId;
  const {
    flagNotificationToggleMutation,
    toggleFlagNotificationClick,
    blockNotificationToggleMutation,
    toggleBlockNotificationClick,
  } = useActionButtons();
  const { mutateAsync: cancelNotification, isLoading: isLoadingCancelNotification } =
    useCancelNotificationMutation();
  const { mutateAsync: updateNotification, isLoading: isLoadingUpdateNotification } =
    useUpdateNotificationMutation();

  const { isLoading: isLoadingFlagNotification } = flagNotificationToggleMutation;

  const { isLoading: isLoadingBlockNotification } = blockNotificationToggleMutation;

  const handleUpdateMessage = async () => {
    try {
      if (!newMessage || newMessage.trim().length <= 0 || !_id || message === newMessage) return;
      await updateNotification({
        id: _id,
        form: { ...props, message: newMessage, timestamp: Date.now() },
      });
      setIsEditing(false);
    } catch (error: any) {
      const message = error.message;
      message && showToast(message, 'error');
    }
  };
  const handleCancelUpdateMessage = () => {
    setIsEditing(false);
    setNewMessage(message);
  };
  const disableSubmitEdit =
    !newMessage ||
    newMessage.trim().length <= 0 ||
    isLoadingUpdateNotification ||
    message === newMessage;

  const onEditClick =
    type === 'message' && ((isVendorUser() && isOwner) || isAdminUser())
      ? () => setIsEditing(true)
      : undefined;
  const onCancelClick =
    type === 'message' && !canceled && ((isVendorUser() && isOwner) || isAdminUser())
      ? () => cancelNotification({ notificationId: _id })
      : undefined;
  const onFlagClick =
    type === 'message' &&
    ((!flagged && (isStandardUser() || (isVendorUser() && !isOwner))) || isAdminUser())
      ? () => toggleFlagNotificationClick(flagged || false, _id)
      : undefined;
  const onBlockClick =
    type === 'message' && isAdminUser()
      ? () => toggleBlockNotificationClick(blocked || false, _id)
      : undefined;

  return (
    <Stack component='li' spacing={'3px'}>
      <Text>{time}</Text>
      <Item color={color} component='section'>
        <Grid container rowSpacing={'1rem'}>
          <Grid item xs={12} padding={'0 1rem'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Text fontSize="18px" fontWeight={'bold'}>
                {title}
              </Text>
              <Text fontSize="18px">{eventName}</Text>
            </Stack>
          </Grid>
          {type === 'message' && (
            <Grid item xs={12} padding={'0 1rem'}>
              {isEditing ? (
                <>
                  <TextField
                    placeholder="Editing Message"
                    label="Edit Message"
                    value={newMessage}
                    multiline
                    required
                    onChange={setNewMessage}
                  />
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <CheckmarkCircle disabled={disableSubmitEdit} onClick={handleUpdateMessage} />
                    <Cancel onClick={handleCancelUpdateMessage} />
                  </Stack>
                </>
              ) : (
                <Text fontSize="18px" textAlign="center">
                  {message}
                </Text>
              )}
            </Grid>
          )}
          {!isEditing && (
            <Grid item xs={12}>
              <ActionButtons
                isFlagged={!!flagged}
                isLoadingFlag={isLoadingFlagNotification}
                onFlagClick={onFlagClick}
                isBlocked={!!blocked}
                isLoadingBlock={isLoadingBlockNotification}
                onBlockClick={onBlockClick}
                isCanceled={!!canceled}
                isLoadingCancel={isLoadingCancelNotification}
                onCancelClick={onCancelClick}
                onEditClick={onEditClick}
              />
            </Grid>
          )}
        </Grid>
      </Item>
    </Stack>
  );
};

export const NotificationsPage = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');
  const vendorId = searchParams.get('vendorId');
  const userId = searchParams.get('userId');
  const getNotificationParams: GetNotificationsParams = {};
  if (eventId) {
    getNotificationParams.eventId = eventId;
  }
  if (vendorId) {
    getNotificationParams.vendorId = vendorId;
  }
  if (userId) {
    getNotificationParams.userId = userId;
  }
  const {
    isLoading: isLoadingEvent,
    data: event,
    error: eventError,
  } = useGetEventByIdQuery(eventId || undefined);
  const {
    mutateAsync,
    isLoading: isLoadingCreateMessage,
    error: createError,
  } = useCreateNotificationMutation();
  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    error: notificationsError,
  } = useGetNotificationsQuery(getNotificationParams); // TODO - Make this better search
  const { user } = useAuth();
  const { showToast } = useToast();
  const [text, setText] = React.useState<string>();

  const handlePostMessage = async () => {
    if (!text || text.trim().length <= 0 || !event) return;
    try {
      await mutateAsync({
        type: 'message',
        message: text,
        eventId: event._id,
        eventName: event.name,
        vendorId: event.vendorId,
        vendorName: event.vendorName,
        link: '',
        timestamp: Date.now(),
      });
      setText('');
    } catch (error: any) {
      const message = error.message;
      message && showToast(message, 'error');
    } finally {
    }
  };

  const isOwnerOfEvent = () => {
    if (!event) return false;
    if (!user) return false;
    return event.vendorId === user.sub;
  };

  if (eventId && isLoadingEvent) return <LoadingPage />;
  if (!notifications || isLoadingNotifications) return <LoadingPage />;

  const showMessageField = isOwnerOfEvent();

  return (
    <Page header={'NOTIFICATIONS'} subheader={event?.name} maxWidth="600px">
      {showMessageField && (
        <Container maxWidth="300px">
          <Stack spacing={'1rem'} aria-label={'Post Message Form'}>
            <Divider flexItem />
            <TextField placeholder="Message" value={text} multiline onChange={setText} />
            <Button
              disabled={!text || text.trim().length <= 0 || isLoadingCreateMessage}
              variant="contained"
              onClick={handlePostMessage}
            >
              Post Message
            </Button>
            <Divider flexItem />
          </Stack>
        </Container>
      )}
      <br />
      <Stack component='ul' spacing={'2rem'} sx={{ listStyleType: 'none', paddingInline: '0', marginBlock: '0' }} aria-label={'Notifications List'}>
        {notifications
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((notification) => (
            <Notification {...notification} key={notification._id} />
          ))}
      </Stack>
    </Page>
  );
};
