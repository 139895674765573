import { Box } from '@mui/material';
import React from 'react';
import { DATE_SHORT, TIME } from '../formatting';
import { TEvent } from '../types/events';
import { Text } from './text';
import { Link } from 'react-router-dom';
import { Calendar } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import { useDialog } from '../context/DialogProvider';
import { CalendarEventsDialogContents } from './dialogs/CalendarEventsDialogContents';
import { getNextOcurrence } from '../utils/days';
import { DAYS_OF_WEEK } from '../types/misc';

export type EventDateMap = {
  [key: string]: TEvent[];
};
type EventsByMonth = {
  [month: string]: TEvent[];
};
type EventsByMonthByDate = {
  [month: string]: EventDateMap;
};

type CalendarViewProps = {
  events: TEvent[];
  view: string;
};

type CalendarItemProps = {
  event: TEvent;
};

const DAYS_TO_MILLISECONDS = 24 * 60 * 60 * 1000;
const MAX_FUTURE_DATE = Date.now() + (365 * DAYS_TO_MILLISECONDS);

export const CalendarView: React.FC<CalendarViewProps> = ({ events, view }) => {
  const [eventsFormatted, setEventsFormatted] = React.useState<EventsByMonthByDate>({});
  const { setOpen } = useDialog();

  React.useEffect(() => {
    const sortedEvents = sortEventsByMonth(events);
    const eventsByMonthAndDate = createMonthMap(sortedEvents);
    setEventsFormatted(eventsByMonthAndDate);
  }, [events]);

  const createMonthMap = (eventsByMonth: EventsByMonth): EventsByMonthByDate => {
    return Object.entries(eventsByMonth).reduce<EventsByMonthByDate>((acc, [month, events]) => {
      // Create a date map for each month separately
      const monthDatesMap = events.reduce<EventDateMap>((datesAcc, event) => {
        const date = new Date(event.dateTime.start);
        const formattedDate = date.toLocaleDateString(undefined, DATE_SHORT as any);
        if (!datesAcc[formattedDate]) datesAcc[formattedDate] = [];
        datesAcc[formattedDate].push(event);
        return datesAcc;
      }, {});

      acc[month] = monthDatesMap;
      return acc;
    }, {});
  };

  const sortEventsByMonth = (list: TEvent[]): EventsByMonth => {
    return list.reduce<EventsByMonth>((acc, event) => {
      switch (event.dateTime.type) {
        case 'Does not repeat': {
          const date = new Date(getNextOcurrence(new Date(), event.dateTime));
          const month = date.toLocaleString('default', { month: 'long' });
          const year = date.toLocaleString('default', { year: 'numeric' });
          const fullDate = `${month}, ${year}`;
          if (!acc[fullDate]) acc[fullDate] = [];
          acc[fullDate].push(event);
          return acc;
        }
        case 'Repeat Daily': {
          let timestamp = event.dateTime.start;
          const now = Date.now();
          const processedDates = new Set<string>();
          const finalDayEnd = new Date(event.dateTime.finalDay).setHours(23, 59, 59, 999);

          while (timestamp <= finalDayEnd && timestamp <= MAX_FUTURE_DATE) {
            const date = new Date(timestamp);
            if (timestamp < now && date.getMonth() !== new Date().getMonth()) {
              timestamp += DAYS_TO_MILLISECONDS;
              continue;
            }

            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            const fullDate = `${month}, ${year}`;
            const uniqueKey = `${event._id}-${timestamp}`;

            if (!processedDates.has(uniqueKey)) {
              if (!acc[fullDate]) acc[fullDate] = [];

              const eventInstance = {
                ...event,
                dateTime: {
                  ...event.dateTime,
                  start: timestamp,
                  end: timestamp + (event.dateTime.end - event.dateTime.start),
                },
                key: uniqueKey,
              };

              acc[fullDate].push(eventInstance);
              processedDates.add(uniqueKey);
            }

            timestamp += DAYS_TO_MILLISECONDS;
          }
          return acc;
        }
        default: {
          let timestamp = event.dateTime.start;
          const now = Date.now();
          const processedDates = new Set<string>();
          const finalDayEnd = new Date(event.dateTime.finalDay).setHours(23, 59, 59, 999);

          while (timestamp <= finalDayEnd && timestamp <= MAX_FUTURE_DATE) {
            const date = new Date(timestamp);
            if (timestamp < now && date.getMonth() !== new Date().getMonth()) {
              timestamp += DAYS_TO_MILLISECONDS;
              continue;
            }

            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            const fullDate = `${month}, ${year}`;
            const uniqueKey = `${event._id}-${timestamp}`;

            if (!processedDates.has(uniqueKey)) {
              const dayOfWeek = DAYS_OF_WEEK[date.getDay()];
              if (
                event.dateTime.type === 'Repeat Weekly' &&
                event.dateTime.days &&
                event.dateTime.days.includes(dayOfWeek)
              ) {
                if (!acc[fullDate]) acc[fullDate] = [];

                const eventInstance = {
                  ...event,
                  dateTime: {
                    ...event.dateTime,
                    start: timestamp,
                    end: timestamp + (event.dateTime.end - event.dateTime.start),
                  },
                  key: uniqueKey,
                };

                acc[fullDate].push(eventInstance);
                processedDates.add(uniqueKey);
              }
            }

            timestamp += DAYS_TO_MILLISECONDS;
          }

          return acc;
        }
      }
    }, {});
  };

  const eventItemStyles = (event: TEvent) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingX: '3px',
    paddingY: '1px',
    background: 'lightgrey',
    cursor: 'pointer',
    opacity: event.canceled ? 0.5 : 1,
    textDecoration: event.canceled ? 'line-through' : 'none',
  });

  const time = (eventTime: number) =>
    new Date(eventTime).toLocaleTimeString(undefined, TIME as any);

  const renderCell = (date: Date) => {
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    const fullDate = `${month}, ${year}`;
    const formattedDate = new Date(date).toLocaleDateString(undefined, DATE_SHORT as any);
    const CalendarItem: React.FC<CalendarItemProps> = ({ event }) => {
      return (
        <Link to={`/events/${event._id}`} style={{ color: 'black', textDecoration: 'none' }}>
          <Box sx={eventItemStyles(event)} title={`${time(event.dateTime.start)} - ${event.name}`}>
            <Text
              fontSize="12px"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              {event.name}
            </Text>
            <Text
              fontSize="10px"
              style={{ whiteSpace: 'nowrap', lineHeight: '10px', cursor: 'pointer' }}
            >
              {time(event.dateTime.start)}
            </Text>
          </Box>
        </Link>
      );
    };
    if (
      eventsFormatted[fullDate] !== undefined &&
      eventsFormatted[fullDate][formattedDate] !== undefined
    ) {
      if (eventsFormatted[fullDate][formattedDate].length > 2) {
        const filteredEvents = eventsFormatted[fullDate][formattedDate]
          .filter((item: TEvent, index: number) => index < 2)
          .map((event: TEvent) => <CalendarItem key={`calendarItem-${event._id}`} event={event} />);
        const extraEvents = eventsFormatted[fullDate][formattedDate].filter(
          (item: TEvent, index: number) => index >= 2,
        );
        const handleCalendarDialog = (e?: React.KeyboardEvent<HTMLDivElement>) => {
          if (e && e.key !== 'Enter') return;
          if (!e || (e && e.key === 'Enter')) {
            setOpen(
              true,
              <CalendarEventsDialogContents
                date={formattedDate}
                events={eventsFormatted[fullDate][formattedDate]}
              />,
            );
          }
        };

        filteredEvents.push(
          <Box
            key={`calendarCellExtra-${formattedDate}`}
            tabIndex={0}
            onKeyDown={(e) => handleCalendarDialog(e)}
            onClick={() => handleCalendarDialog()}
          >
            <Text textAlign={'center'} fontSize="12px" style={{ cursor: 'pointer' }}>
              {extraEvents.length} more
            </Text>
          </Box>,
        );
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px', marginTop: '4px' }}>
            {filteredEvents}
          </Box>
        );
      } else {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px', marginTop: '4px' }}>
            {eventsFormatted[fullDate][formattedDate].map((event: TEvent) => (
              <CalendarItem key={`calendarItem-${event._id}`} event={event} />
            ))}
          </Box>
        );
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: { xs: 'none', sm: view === 'calendar' ? 'block' : 'none' },
        background: 'white',
        borderRadius: '6px',
        marginTop: '8px',
      }}
    >
      <Calendar bordered renderCell={renderCell} />
    </Box>
  );
};
