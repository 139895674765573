import { Text } from '../text';

export const PrivacyLabel = () => {
  return (
    <Text textAlign="center">
      By subscribing to us, you agree to our <br />
      <a href={'/terms-of-service'}>Terms of Service</a>
      {', '}
      <a href={'/privacy-policy'}>Privacy Policy</a>
      {', '}
      and our <br />
      <a href={'/advertise-agreement'}>Advertise Agreement</a>
    </Text>
  );
};
