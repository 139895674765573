import { useQuery } from 'react-query';
import { getNotifications, GetNotificationsParams } from '../api/notifications';
import { useAuth0 } from '@auth0/auth0-react';

export const useGetNotificationsQuery = (options: GetNotificationsParams) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['getNotifications'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getNotifications(options, token);
    },
  });
};
