import { useNavigate } from 'react-router-dom';
import { Page } from '../../../components/layout/page';
import { ErrorText } from '../../../components/text/ErrorText';
import { useGetEventsQuery } from '../../../queries/useGetEventsQuery';
import { useGetNotificationsQuery } from '../../../queries/useGetNotificationsQuery';
import { useGetVendorsQuery } from '../../../queries/useGetVendorsQuery';
import { LoadingPage } from '../../LoadingPage';
import { TEvent } from '../../../types/events';
import { TVendor } from '../../../types/vendors';
import { TNotification } from '../../../types/notification';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Rating,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Paper } from '../../../components/Paper';
import { Text } from '../../../components/text';
import { ExpandMoreIcon } from '../../../components/icons';
import { TFeedback } from '../../../api/feedback';
import { useGetFeedbackQuery } from '../../../queries/useGetFeedbackQuery';

type FeedbackItemParams = {
  item: TFeedback;
};

export const FeedbackItem: React.FC<FeedbackItemParams> = ({ item }) => {
  const subscriptionLabel =
    !item.subscription || item.subscription === 'unsubscribed' ? 'public' : item.subscription;
  return (
    <Paper elevation={1} width={'100%'} padding={'1rem'}>
      <Stack spacing={'1rem'}>
        <Text fontSize={'24px'}>User: {subscriptionLabel}</Text>
        <Rating readOnly size="large" value={item.rating} />
        <Divider flexItem />
        <br />
        <Text fontSize={'24px'}>{item.comments}</Text>
      </Stack>
    </Paper>
  );
};

export const FeedbackPage = () => {
  const theme = useTheme();
  const { isLoading, data: feedbackList } = useGetFeedbackQuery();

  if (isLoading) return <LoadingPage />;
  if (!feedbackList) return <LoadingPage />;

  return (
    <Page header={'FEEDBACK'} maxWidth="450px">
      <Stack spacing={'2rem'}>
        {feedbackList.length > 0 ? (
          feedbackList
            .sort((a, b) => b.timestamp - a.timestamp)
            .map((feedback) => <FeedbackItem key={feedback._id} item={feedback} />)
        ) : (
          <Text textAlign="center" color={theme.palette.secondary.main}>
            No feedback yet.
          </Text>
        )}
      </Stack>
    </Page>
  );
};
