import { Text, TextProps } from '.';

export const ShortenedMultilineLabel: React.FC<React.PropsWithChildren<TextProps>> = (props) => {
  const styles = {
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    ...props.style
  };

  return (
    <Text {...props} style={styles}>
      {props.children}
    </Text>
  );
};
