import { Stack, useTheme } from '@mui/material';
import { Text } from '../components/text';
import { Page } from '../components/layout/page';

// TODO - Check in the header to ensure links are present if not blocked.
// TODO - Middleware to check if the user is not blocked when posting events.

// TODO - Follow this pattern for non-verified emails.

export const BlockedPage = () => {
  const theme = useTheme();
  return (
    <Page header="Your account is BLOCKED.">
      <Text textAlign="center" color={theme.palette.secondary.main}>
        If you think this is a mistake, please contact Paige at paige@sociallifeofmine.com
      </Text>
    </Page>
  );
};
