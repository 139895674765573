import { Divider, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Text } from '../text';
import { formatAddress, formatPhoneNumber, formatWebsiteLink } from '../../utils/formatting';
import React from 'react';
import { useAuth } from '../../auth/useAuth';
import { TVendor } from '../../types/vendors';
import { useActionButtons } from '../../hooks/useActionButtons';
import { ActionButtons } from '../ActionButtons';
import { isFollowingVendor } from '../../utils/userUtils';
import { Item } from './Item';
import { ShortenedMultilineLabel } from '../text/ShortenedMultilineLabel';
import { ImageDisplay } from '../ImageDisplay';
import { usePublicUser } from '../../context/PublicUserProvider';

type InfoItemProps = {
  vendor: TVendor;
};
const InfoItem: React.FC<InfoItemProps> = ({ vendor }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const textAlign = isSm ? 'center' : 'left';
  return (
    <Stack sx={{ width: '100%' }}>
      {vendor.companyName && (
        <Text textAlign={textAlign} fontSize="24px" fontWeight="bold">
          {vendor.companyName}
        </Text>
      )}
      <br />
      {vendor.businessDescription && (
        <ShortenedMultilineLabel textAlign={textAlign} fontWeight="bold">
          {vendor.businessDescription}
        </ShortenedMultilineLabel>
      )}
      <br />
      {vendor.address && (
        <Grid container justifyContent={textAlign}>
          <Text textAlign={textAlign} component="span" fontWeight="bold">
            Location: <Text component="span">{formatAddress(vendor.address)}</Text>
          </Text>
        </Grid>
      )}
      {vendor.phoneNumber && (
        <Grid container justifyContent={textAlign}>
          <Text textAlign={textAlign} component="span" fontWeight="bold">
            Contact: <Text component="span">{formatPhoneNumber(vendor.phoneNumber)}</Text>
          </Text>
        </Grid>
      )}
      {vendor.websiteLink && (
        <Grid container justifyContent={textAlign}>
          <Text textAlign={textAlign} component="span" fontWeight="bold">
            Website: <Text component="span">{formatWebsiteLink(vendor.websiteLink)}</Text>
          </Text>
        </Grid>
      )}
    </Stack>
  );
};

type Props = {
  vendor: TVendor;
  onClick: (error: TVendor) => void;
  onError: (error: unknown) => void;
};
export const VendorItem: React.FC<Props> = (props) => {
  const { vendor, onClick, onError } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const { user, isStandardUser, isPublicUser, isAdminUser } = useAuth();
  const { publicIsFollowingVendor } = usePublicUser();
  const { followVendorToggleMutation, toggleFollowVendorClick } = useActionButtons();
  const { error: followVendorError, isLoading: isLoadingFollowVendor } = followVendorToggleMutation;

  React.useEffect(() => {
    if (followVendorError) onError(followVendorError);
  }, [followVendorError]);

  const isFollowing = isPublicUser()
    ? publicIsFollowingVendor(vendor.sub)
    : isStandardUser()
      ? isFollowingVendor(vendor.sub, user)
      : false;
  const onFollowClick = () => toggleFollowVendorClick(isFollowing, vendor.sub);

  return (
    <Item
      ariaLabel={`${vendor.companyName} Vendor Card`}
      color={theme.palette.primary.main}
      disabled={vendor.blocked && !isAdminUser()}
      tabIndex={0}
      onClick={() => onClick(vendor)}
    >
      <Grid container item xs={12} width={'100%'} justifyContent={'space-evenly'}>
        <Grid item xs={12} sm={4} md={4} justifyContent={'center'} alignItems={'center'}>
          <ImageDisplay src={vendor.photo} alt={vendor.companyName} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={isStandardUser() || isPublicUser() ? 6 : 8}
          container
          justifyContent={'center'}
          alignItems={'center'}
          padding={'1rem'}
        >
          <InfoItem vendor={vendor} />
        </Grid>
        {(isStandardUser() || isPublicUser()) && (
          <>
            <Grid item xs={12} md={1}>
              <Divider orientation={isMd ? 'horizontal' : 'vertical'} />
            </Grid>
            <Grid container item xs={12} md={1} justifyContent={'center'} alignItems={'center'}>
              <ActionButtons
                isFollowing={isFollowing}
                isLoadingFollowVendor={isLoadingFollowVendor}
                onFollowClick={isStandardUser() || isPublicUser() ? onFollowClick : undefined}
              />
            </Grid>
          </>
        )}
      </Grid>
      {vendor.blocked && (
        <Grid
          container
          item
          xs={12}
          justifyContent={'center'}
          padding={'1rem'}
          marginTop={'1rem'}
          style={{ background: '#0000001c' }}
        >
          <Text textAlign="center">Vendor is blocked</Text>
        </Grid>
      )}
    </Item>
  );
};
