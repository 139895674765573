import React from 'react';
import { List, Stack, Typography } from '@mui/material';
import Container from '../components/Container';
import { CalendarListView } from '../components/CalendarListView';
import { usePublicUser } from '../context/PublicUserProvider';
import { VendorItem } from '../components/items/VendorItem';
import { useAuth } from '../auth/useAuth';
import { TVendor } from '../types/vendors';
import { TEvent } from '../types/events';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetEventsQuery } from '../queries/useGetEventsQuery';
import { useGetVendorsQuery } from '../queries/useGetVendorsQuery';
import { isEventOnCalendar, isFollowingVendor, isLikedEvent } from '../utils/userUtils';
import { Page } from '../components/layout/page';
import { SearchForm, TSearchForm } from '../components/forms/SearchForm';
import { GetEventsParams } from '../api/events';
import { Text } from '../components/text';
import { GetVendorsOptions } from '../api/vendors';

export const createEventsSearchParams = (form: TSearchForm) => {
  return Object.entries(form).reduce<URLSearchParams>((acc, [key, value]) => {
    if (value) acc.set(key, value.toString());
    return acc;
  }, new URLSearchParams());
};

export const createEventsSearchQuery = (
  params: URLSearchParams,
  blocked: boolean,
  canceled: boolean,
): GetEventsParams => {
  const textParam = params.get('text');
  const maxPriceParam = params.get('maxPrice');
  const startDateParam = params.get('startDate');
  const query: GetEventsParams = { blocked, canceled };
  if (textParam) query.text = textParam;
  if (maxPriceParam) query.maxPrice = Number(maxPriceParam);
  if (startDateParam) query.startDate = Number(startDateParam);
  return query;
};

export const createVendorsSearchQuery = (
  params: URLSearchParams,
  blocked: boolean,
): GetVendorsOptions => {
  const textParam = params.get('text');
  const query: GetVendorsOptions = { blocked };
  if (textParam) query.text = textParam;
  return query;
};

const TYPES_MAP = {
  Events: 'Events',
  Vendors: 'Vendors,',
};

const DISPLAY_TYPES = Object.keys(TYPES_MAP);

export const BrowsePage = () => {
  // TODO - Handle Canceled State for Vendor / Admin
  const navigate = useNavigate();
  const { publicIsLikedEvent, publicIsFollowingVendor } = usePublicUser();
  const { user, isPublicUser, isStandardUser, isVendorUser, isAdminUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredEvents, setFilteredEvents] = React.useState<TEvent[]>([]);
  const [filteredVendors, setFilteredVendors] = React.useState<TVendor[]>([]);

  const eventsQuery = createEventsSearchQuery(searchParams, isAdminUser(), isAdminUser());
  const { isLoading: isLoadingEvents, data: eventsResult } = useGetEventsQuery(eventsQuery);

  const vendorsQuery = createVendorsSearchQuery(searchParams, isAdminUser());
  const { isLoading: isLoadingVendors, data: vendorsResult } = useGetVendorsQuery(vendorsQuery);

  const type = searchParams.get('type') || DISPLAY_TYPES[0];
  const likedEventsOnly = searchParams.get('likedEventsOnly') === 'true' || false;
  const calendarEventsOnly = searchParams.get('calendarEventsOnly') === 'true' || false;
  const followedVendorsOnly = searchParams.get('followedVendorsOnly') === 'true' || false;
  const text = searchParams.get('text') || '';
  const startDate = Number(searchParams.get('startDate')) || undefined;
  const maxPrice = Number(searchParams.get('maxPrice'));

  React.useEffect(() => {
    if (type === 'Events') filterEvents(eventsResult || []);
  }, [eventsResult, likedEventsOnly, calendarEventsOnly, type]);

  React.useEffect(() => {
    if (type !== 'Vendors') return;
    filterVendors(vendorsResult || []);
  }, [vendorsResult, followedVendorsOnly, type]);

  const onSearchClick = (form: TSearchForm) => {
    const params = createEventsSearchParams(form);
    return setSearchParams(params);
  };

  const filterEvents = (es: TEvent[]) => {
    const filtered = es.filter((event) => {
      if (!likedEventsOnly && !calendarEventsOnly) return true;
      if (likedEventsOnly && isPublicUser() && publicIsLikedEvent(event._id)) return true;
      if (likedEventsOnly && isStandardUser() && isLikedEvent(event._id, user)) return true;
      if (calendarEventsOnly && isStandardUser() && isEventOnCalendar(event._id, user)) return true;
      return false;
    });
    setFilteredEvents(filtered);
  };

  const filterVendors = (vs: TVendor[]) => {
    const sorted = vs.sort((a, b) => {
      const lowerA = a.companyName.toLowerCase();
      const lowerB = b.companyName.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    });
    if (!followedVendorsOnly) return setFilteredVendors(sorted);
    if (isAdminUser()) return setFilteredVendors(sorted);
    if (isVendorUser()) return setFilteredVendors(sorted);
    if (isPublicUser()) return setFilteredVendors(sorted.filter((vendor) => publicIsFollowingVendor(vendor.sub)));
    setFilteredVendors(sorted.filter((vendor) => isFollowingVendor(vendor.sub, user)));
  };

  // TODO - Make sure this syncs?
  // React.useEffect(() => {
  //   setShowLikesOnly(false);
  //   setShowVendorsOnly(false);
  // }, [type]);

  const loadingText =
    isLoadingEvents || !eventsResult || isLoadingVendors || !vendorsResult
      ? 'Searching...'
      : undefined;

  return (
    <Page header="BROWSE" maxWidth="1200px">
      <Stack alignItems={'center'}>
        <Text textAlign="center">* All times are local to the event location</Text>
        <br />
        <SearchForm
          displayTypes={DISPLAY_TYPES}
          initialValues={{
            type,
            likedEventsOnly,
            calendarEventsOnly,
            followedVendorsOnly,
            text,
            startDate,
            maxPrice,
          }}
          onSubmit={async (form) => onSearchClick(form)}
        />
        <br />
        {type === 'Events' && (
          <Container maxWidth="800px" aria-label={'Search Events Results'}>
            <CalendarListView events={filteredEvents} view="list" />
          </Container>
        )}
        {type === 'Vendors' && (
          <Container maxWidth="800px" aria-label={'Search Vendors Results'}>
            <List>
              {filteredVendors.map((vendor) => (
                <VendorItem
                  key={vendor.sub}
                  vendor={vendor}
                  onClick={() => navigate(`/vendors/${vendor.sub}`)}
                  onError={console.log}
                />
              ))}
            </List>
          </Container>
        )}
        {loadingText && <Typography textAlign={'center'}>{loadingText}</Typography>}
      </Stack>
    </Page>
  );
};
