import { TStandard } from '../types/standards';
import { TUser } from '../auth/useAuth';
import { User } from '@auth0/auth0-react';
import { TUnsubscribed } from '../types/unsubscribed';
import { TAdmin } from '../types/admin';
import { TVendor } from '../types/vendors';

export const isLikedEvent = (eventId: string, user?: TUser): boolean => {
  if (user) {
    const standard = user as TStandard;
    if (!standard.likedEvents) return false;
    return standard.likedEvents.includes(eventId);
  }
  return false;
};

export const isEventOnCalendar = (eventId: string, user?: TUser): boolean => {
  if (user) {
    const standard = user as TStandard;
    if (!standard.calendarEvents) return false;
    return standard.calendarEvents.includes(eventId);
  }
  return false;
};

export const isFollowingVendor = (vendorId: string, user?: TUser): boolean => {
  if (user) {
    const standard = user as TStandard;
    if (!standard.followedVendors) return false;
    return standard.followedVendors.includes(vendorId);
  }
  return false;
};

const getStandardUser = (user: User): TStandard => {
  const { app_metadata, user_metadata } = user;
  const parsedAppMetadata = JSON.parse(app_metadata);
  const parsedUserMetadata = JSON.parse(user_metadata);

  const userId = user.sub || user.user_id;
  if (!userId) throw new Error('Unable to verify user id');
  if (!user.email) throw new Error('Unable to verify user email');
  if (!app_metadata) throw new Error('Unable to verify user data');
  if (!parsedAppMetadata.subscription) throw new Error('Unable to verify subscription');

  return {
    sub: userId,
    email: user.email,
    subscription: parsedAppMetadata.subscription,
    blocked: parsedAppMetadata.SLOM_isBlocked || false,
    pronouns: parsedUserMetadata.pronouns || 'He/Him',
    birthday: parsedUserMetadata.birthday || Date.now(),
    likedEvents: parsedUserMetadata.likedEvents || [],
    calendarEvents: parsedUserMetadata.calendarEvents || [],
    followedVendors: parsedUserMetadata.followedVendors || [],
    email_verified: user.email_verified,
  };
};

const getVendorUser = (user: User): TVendor => {
  const { app_metadata, user_metadata } = user;
  const parsedAppMetadata = JSON.parse(app_metadata);
  const parsedUserMetadata = JSON.parse(user_metadata);

  const userId = user.sub || user.user_id;
  if (!userId) throw new Error('Unable to verify user id');
  if (!user.email) throw new Error('Unable to verify user email');
  if (!app_metadata || !user_metadata) throw new Error('Unable to verify user data');
  if (!parsedAppMetadata.subscription) throw new Error('Unable to verify subscription');

  return {
    sub: userId,
    email: user.email,
    subscription: parsedAppMetadata.subscription,
    blocked: parsedAppMetadata.SLOM_isBlocked || false,
    flagged: parsedAppMetadata.SLOM_isFlagged || false,
    companyName: parsedUserMetadata.companyName,
    address: parsedUserMetadata.address,
    missionStatement: parsedUserMetadata.missionStatement,
    businessDescription: parsedUserMetadata.businessDescription,
    phoneNumber: parsedUserMetadata.phoneNumber,
    websiteLink: parsedUserMetadata.websiteLink,
    daysOfOperation: parsedUserMetadata.daysOfOperation,
    photo: parsedUserMetadata.photo,
    claimed: true,
    email_verified: user.email_verified,
  };
};

const getAdminUser = (user: User): TAdmin => {
  const { app_metadata } = user;
  const parsedAppMetadata = JSON.parse(app_metadata);

  const userId = user.sub || user.user_id;
  if (!userId) throw new Error('Unable to verify user id');
  if (!parsedAppMetadata.subscription) throw new Error('Unable to verify subscription');

  return {
    sub: userId,
    email_verified: true, // Hardcode for admins
    subscription: parsedAppMetadata.subscription,
  };
};

const getUnsubscribedUser = (user: User): TUnsubscribed => {
  const userId = user.sub || user.user_id;
  if (!userId) throw new Error('Unable to verify user id');
  if (!user.email) throw new Error('Unable to verify user email');

  return {
    sub: userId,
    email: user.email,
    subscription: 'unsubscribed',
    email_verified: user.email_verified,
  };
};

export const getUserFromAuth0User = (user: User) => {
  const { app_metadata } = user;
  const { subscription } = JSON.parse(app_metadata);
  switch (subscription) {
    case 'standard':
      return getStandardUser(user);
    case 'vendor':
      return getVendorUser(user);
    case 'admin':
      return getAdminUser(user);
    default:
      return getUnsubscribedUser(user);
  }
};
