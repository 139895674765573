import { useMutation, useQueryClient } from 'react-query';
import { TNotificationForm } from '../types/notification';
import { createNotification } from '../api/notifications';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, POST_MESSAGE_SUCCESS } from '../hooks/useToast';

export const useCreateNotificationMutation = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();

  return useMutation({
    mutationFn: async (form: TNotificationForm) => {
      const token = await getAccessTokenSilently();
      return createNotification(form, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getNotifications'] });
      showToast(POST_MESSAGE_SUCCESS, 'success');
    },
  });
};
