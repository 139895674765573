import { COMPANY_MISSION, COMPANY_MISSION_PARAGRAPHS } from '../../constants/mission-statement';
import { Text } from '../../components/text';
import { styled } from 'styled-components';
import { Grid, Stack } from '@mui/material';
import { Page } from '../../components/layout/page';

export const Profile = styled.img`
  width: 200px;
  height: 300px;
`;

export const AboutUsPage = () => {
  return (
    <Page header="OUR MISSION" maxWidth="900px">
      <Grid container alignItems={'center'}>
        <Grid item xs={12} alignItems={'center'}>
          <Stack spacing={'1rem'}>
            <Text textAlign="center" fontSize="32px" fancy>
              {COMPANY_MISSION}
            </Text>
            <br />
            {COMPANY_MISSION_PARAGRAPHS.map((paragraph) => (
              <div key={paragraph}>
                <Text textAlign="center" fontSize="18px">
                  {paragraph}
                </Text>
              </div>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
};
